import React, { Component } from "react";

export default class Validator extends Component {
  render() {
    const form = {
      tryCreateInput: {
        border: "2px solid #C6007E",
      },
      tryCreateText: {
        color: "#C6007E",
        fontFamily: "Ubuntu",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "20px",
      },
      input: {
        width: this.props.width ? this.props.width : "-webkit-fill-available",
        color: "#C5C5C5",
        border: "1px solid #c5c5c5",
        borderRadius: "5px",
        height: "30px",
        padding: "0 10px",
      },
    };

    return (
      <>
        {this.props.parameter ? (
          <input
            style={{ ...form.input }}
            placeholder={this.props.placeholder}
            value={this.props.parameter}
            name={this.props.name}
            onChange={this.props.handleChange}
            className={this.props.className}
            type={this.props.type}
          ></input>
        ) : this.props.tryCreate ? (
          <>
            <input
              style={{
                ...form.input,
                ...form.tryCreateInput,
              }}
              placeholder={this.props.placeholder}
              value={this.props.parameter}
              name={this.props.name}
              onChange={this.props.handleChange}
              className={this.props.className}
              type={this.props.type}
            ></input>
            <div style={{ ...form.tryCreateText }}>{this.props.error}</div>
          </>
        ) : (
          <input
            style={{ ...form.input }}
            placeholder={this.props.placeholder}
            value={this.props.parameter}
            name={this.props.name}
            onChange={this.props.handleChange}
            className={this.props.className}
            type={this.props.type}
          ></input>
        )}
      </>
    );
  }
}
