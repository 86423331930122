import React from "react";
import { authRoles } from "../../auth/authRoles";
const dashboardRoutes = [
  {
    path: "/",
    component: React.lazy(() => import("./Analytics")),
    auth: authRoles.admin,
  },
  {
    path: "/session/login",
    component: React.lazy(() => import("../sessions/SignIn/SignIn")),
    auth: authRoles.admin,
  },
];

export default dashboardRoutes;
