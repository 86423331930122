import React from "react";
import PropTypes from "prop-types";

import MarkerStyled from "./styles/MarkerStyled";
import MarkerInGroupStyled from "./styles/MarkerInGroupStyled";
import CardInfoUnit from "./components/CardInfoUnit";

import { ReactComponent as SignalCircle } from "../../../../../../assets/svg/marker/signal-circle.svg";

import "./Marker.scss";

class Marker extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      orientation: 0,
      cardOptions: null,
      showMarker: true,
      hovered: false,
      markerState: "Normal",
      status: "",
      animationStatus: false,
    };
    this.animateMarker = this.animateMarker.bind(this);
    this.handleMarkerHoverIn = this.handleMarkerHoverIn.bind(this);
    this.handleMarkerHoverOut = this.handleMarkerHoverOut.bind(this);
    this.handleClic = this.handleClic.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.imageForType = this.imageForType.bind(this);
    this.animationStatus = this.animationStatus.bind(this);
  }
  // eslint-disable-line react/prefer-stateless-function
  static defaultProps = {
    inGroup: false,
  };

  componentDidMount() {
    if (this.props.orientation === 360) {
      this.setState({ orientation: 0 });
    } else {
      this.setState({ orientation: this.props.orientation });
    }
    if (this.props.signal > 0) {
      this.setState({
        markerState: "Normal",
      });
    } else {
      this.setState({
        markerState: "Error",
      });
    }
    if (this.props.speed > 0) {
      this.setState({
        animationStatus: false,
      });
    } else {
      this.setState({
        animationStatus: true,
      });
      this.animationStatus();
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.orientation) {
      if (this.props.showWidget) {
        if (this.props.idSelect === this.props.id) {
          this.props.setCenterMap([this.props.lat, this.props.lng]);
        }
      }
      if (prevProps.orientation !== this.props.orientation) {
        if (prevProps.orientation === 360) {
          this.setState({ orientation: 0 });
        } else {
          this.setState({ orientation: prevProps.orientation });
        }
        this.changeOrientation();
      }
    } else {
      if (this.props.orientation === 360) {
        this.setState({ orientation: 0 });
      } else {
        this.setState({ orientation: this.props.orientation });
      }
    }
    if (prevProps.signal !== this.props.signal) {
      if (this.props.signal > 0) {
        this.setState({
          markerState: "Normal",
          animationStatus: false,
        });
      } else {
        this.setState({
          markerState: "Error",
        });
      }
    }
    if (prevProps.speed !== this.props.speed) {
      if (!this.state.animationStatus && this.props.speed === 0) {
        this.animationStatus();
        this.setState({
          animationStatus: true,
        });
      } else {
        this.setState({
          animationStatus: false,
        });
      }
    }
  }

  animationStatus() {
    this.timeStatus = setTimeout(() => {
      if (this.state.status === "") {
        this.setState({
          status: "-stop",
        });
      } else {
        this.setState({
          status: "",
        });
      }
      if (this.state.animationStatus) {
        this.animationStatus();
      }
    }, 700);
  }

  changeOrientation() {
    var speed = 5;
    if (this.state.orientation) {
      if (this.state.orientation < this.props.orientation) {
        if (this.props.orientation - this.state.orientation <= 180) {
          this.time = setTimeout(() => {
            this.setState({
              orientation: this.state.orientation + 1,
            });

            if (this.state.orientation < this.props.orientation) {
              this.changeOrientation();
            }
            if (this.state.orientation === 359) {
              this.setState({
                orientation: 0,
              });
            }
          }, speed);
        } else {
          this.time = setTimeout(() => {
            if (this.state.orientation === 360) {
              this.setState({
                orientation: 360 - 1,
              });
            } else {
              this.setState({
                orientation: this.state.orientation - 1,
              });
            }
            if (this.props.orientation > this.state.orientation) {
              this.changeOrientation();
            }
            if (this.state.orientation === 359) {
              this.setState({
                orientation: 0,
              });
            }
          }, speed);
        }
      } else {
        if (this.state.orientation - this.props.orientation >= 180) {
          this.time = setTimeout(() => {
            if (this.state.orientation === 360) {
              this.setState({
                orientation: 0 + 1,
              });
            } else {
              this.setState({
                orientation: this.state.orientation + 1,
              });
            }
            if (this.state.orientation > this.props.orientation) {
              this.changeOrientation();
            }
            if (this.state.orientation === 359) {
              this.setState({
                orientation: 0,
              });
            }
          }, speed);
        } else {
          this.time = setTimeout(() => {
            this.setState({
              orientation: this.state.orientation - 1,
            });

            if (this.state.orientation > this.props.orientation) {
              this.changeOrientation();
            }
            if (this.state.orientation === 359) {
              this.setState({
                orientation: 0,
              });
            }
          }, speed);
        }
      }
    }
  }

  handleToggle() {
    this.setState({
      showMarker: !this.state.showMarker,
    });
  }

  handleMarkerHoverIn() {
    //entro al marcador
    this.setState({ hovered: true });
  }
  handleMarkerHoverOut() {
    /*entro al marcador*/
    this.setState({ hovered: false });
  }

  handleClic() {
    this.props.setCenterMap([this.props.lat, this.props.lng]);
    this.props.setZoomMap(17);
  }

  animateMarker() {
    let changeImg;

    if (this.state.orientation === 360) {
      changeImg = 0;
    } else {
      changeImg =
        this.state.orientation -
        Math.round((this.state.orientation / 7.5) * 6.5);
    }

    if (changeImg < 10) {
      let animationIndex = "00" + changeImg;

      this.setState({
        imgSrc: `/assets/images/animation/${this.props.type.value}/${
          this.props.type.value
        }${
          this.state.animationStatus ? this.state.status : ""
        }${animationIndex}.png`,
        imgBack: `/assets/images/animation/${this.props.type.value}/${this.props.type.value}-color${animationIndex}.png`,
      });
    } else {
      let animationIndex = "0" + (changeImg > 47 ? "00" : changeImg);
      this.setState({
        imgSrc: `/assets/images/animation/${this.props.type.value}/${
          this.props.type.value
        }${
          this.state.animationStatus ? this.state.status : ""
        }${animationIndex}.png`,
        imgBack: `/assets/images/animation/${this.props.type.value}/${this.props.type.value}-color${animationIndex}.png`,
      });
    }
  }

  imageForType() {
    this.animateMarker();
    let defaultWidth = this.props.type.width
      ? parseInt(this.props.type.width)
      : 30;
    let zoom = (this.props.zoom * 100) / 17;
    let x = zoom / 100;
    let widht = defaultWidth * x;
    let minWidth =
      widht > (defaultWidth * 25) / 100 ? widht : (defaultWidth * 25) / 100;
    const styles = {
      width: `${
        this.props.focusMarker === this.props.id ? defaultWidth * 1.2 : minWidth
      }px`,
    };
    return (
      <>
        <img
          src={this.state.imgBack}
          style={{
            ...styles,
            position: "absolute",
            filter:
              this.props.color.filterCss + "drop-shadow(0px 0px 5px #8c8c8c)",
            zIndex: this.props.focusMarker === this.props.id ? 2 : null,
          }}
          alt=""
          className={`${
            this.props.focusMarker === this.props.id
              ? `markerFocus `
              : `marker `
          }
            `}
        />
        <img
          src={this.state.imgSrc}
          alt=""
          style={{
            ...styles,
            position: "absolute",
            zIndex: this.props.focusMarker === this.props.id ? 2 : null,
          }}
          className={`${
            this.props.focusMarker === this.props.id ? `markerFocus` : `marker`
          } img-front`}
        />
      </>
    );
  }

  handleZoom(z) {
    this.props.setZoomMap(z);
  }

  render() {
    let showname = this.props.devices ? (
      <span>{this.props.name}</span>
    ) : (
      <span></span>
    );
    let showdriver = this.props.drivers ? (
      <span>@DriverName</span>
    ) : (
      <span></span>
    );
    return (
      <div>
        {this.props.inGroup ? (
          <MarkerInGroupStyled
            className="imgMarker"
            onMouseLeave={this.handleMarkerHoverOut}
            onMouseEnter={this.handleMarkerHoverIn}
            onClick={this.handleClic}
          ></MarkerInGroupStyled>
        ) : (
          <div
            onMouseLeave={this.handleMarkerHoverOut}
            onMouseEnter={this.handleMarkerHoverIn}
          >
            {this.state.hovered ? (
              <CardInfoUnit
                className="cardMarker"
                lat={this.props.lat}
                lng={this.props.lng}
                orientation={this.props.orientation}
                marca="@Brand"
                modelo={this.props.modelo}
                name={this.props.name}
                city={this.props.city}
                speed={this.props.speed}
                lastUpdate={this.props.lastUpdate}
                signal={this.props.signal}
                timeMoving={this.props.id}
                timeStoped="00:02:21"
                altitud={this.props.altitud}
                voltage={this.props.voltage}
                hovered={this.state.hovered}
                mouseIn={this.handleMarkerHoverIn}
                mouseOut={this.handleMarkerHoverOut}
                map={this.props.map}
                maps={this.props.maps}
              />
            ) : null}
            <MarkerStyled className="imgMarker" onClick={this.handleClic}>
              {this.imageForType()}
              <SignalCircle
                className={`outline_marker outline_marker${this.state.markerState}`}
                style={{
                  width: `${
                    this.props.$geoService.transform_._zoom / 0.8 + 30
                  }px`,
                  position: "absolute",
                  zIndex: -1,
                }}
              ></SignalCircle>
            </MarkerStyled>
          </div>
        )}
        <div className="tagMarker column">
          <h2>{showname}</h2>
          <h3>{showdriver}</h3>
        </div>
      </div>
    );
  }
}

Marker.propTypes = {
  inGroup: PropTypes.bool,
};

export default Marker;
