import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import RenderMiniMaps from "./RenderMiniMaps";
import Widget from "./MAP/Widget/Widget";
import update from "react-addons-update";
import {
  updateListUnitsBuffer,
  listUnitsBuffer,
} from "../../../../redux/actions/BufferAction";
import { getCoolorsMotion } from "app/redux/actions/CoolorsAction";

import MapGoogle from "./MAP/MapGoogle";
import MiniMapsPane from "./MiniMaps/MiniMapsPane";
import LayerControl from "./RightNav/components/LayerControl/LayerControl";
import ListOfDevices from "./ListOfDevices/ListOfDevices";
import MeasureTool from "measuretool-googlemaps-v3";
import ModalUnit from "./Modals/components/ModalUnit/ModalUnit";
import ModalGroups from "./Modals/components/ModalGroups/ModalGroups";
import ModalUpdateUnit from "./Modals/components/ModalUpdateUnit/ModalUpdateUnit";
import { getImagesMotion } from "app/redux/actions/ImagesActions";
import FilterSvg from "./FilterSvg/FilterSvg";

const mapStateToProps = (state) => {
  return {
    data: state.data,
    value: state.value,
    buffer: state.BufferReducer.buffer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  listUnitsBuffer: () => {
    dispatch(listUnitsBuffer());
  },
  updateListUnitsBuffer: () => {
    dispatch(updateListUnitsBuffer());
  },
  getCoolorsMotion: () => {
    dispatch(getCoolorsMotion());
  },
  getImagesMotion: () => {
    dispatch(getImagesMotion());
  },
});

class Main extends Component {
  constructor() {
    super();
    this.state = {
      auth: JSON.parse(localStorage.getItem("auth_user")),
      traffic: false,
      trafficLayer: null,
      drivers: false,
      devices: false,
      selectedMarker: null,
      hoverMarker: null,
      visibleVehicles: [],
      prevVisibleVehicles: [],
      clickedMarker: null,
      clusterVisible: true,
      measure: false,
      measureObj: null,
      mapStyle: "silverMode",
      userLocation: false,
      displayCardMarker: null,
      //MiniMaps
      showMiniMaps: true,
      miniMaps: [],
      miniMapIdSelect: [],

      positionLeft: 270,
      showList: false,
      center: { lat: 4.653891, lng: -74.098075 },
      zoom: 9,
      map: null,
      maps: null,
      showWidget: false,
      idSelectedMarkers: [],
      showAllVisible: false,
      focusMarker: null,
      //Show modals
      showModalUnitsGroups: false,
      showModalUpdateUnit: false,
    };
    this.handleToggleList = this.handleToggleList.bind(this);
    this.onDriversClick = this.onDriversClick.bind(this);
    this.onDevicesClick = this.onDevicesClick.bind(this);
    this.measureClick = this.measureClick.bind(this);
    this.handleHoverMarker = this.handleHoverMarker.bind(this);
    this.handleUnHoverMarker = this.handleUnHoverMarker.bind(this);
    this.handleClickedMarker = this.handleClickedMarker.bind(this);
    this.handleUnClickedMarker = this.handleUnClickedMarker.bind(this);
    this.handleDbClickedMarker = this.handleDbClickedMarker.bind(this);
    this.handleUnDbClickedMarker = this.handleUnDbClickedMarker.bind(this);
    this.userLocationClick = this.userLocationClick.bind(this);
    this.handleDisplayCard = this.handleDisplayCard.bind(this);
    this.unDisplayCard = this.unDisplayCard.bind(this);

    //Function of minimaps
    this.appendToMiniMaps = this.appendToMiniMaps.bind(this);
    this.deleteFromMiniMaps = this.deleteFromMiniMaps.bind(this);
    this.toggleMiniMaps = this.toggleMiniMaps.bind(this);
    this.hideMiniMaps = this.hideMiniMaps.bind(this);
    this.showAllMiniMaps = this.showAllMiniMaps.bind(this);
    this.updateMiniMaps = this.updateMiniMaps.bind(this);
    this.closeMiniMapErr = this.closeMiniMapErr.bind(this);

    //Map
    this.setCenterMap = this.setCenterMap.bind(this);
    this.setZoomMap = this.setZoomMap.bind(this);
    this.changeMap = this.changeMap.bind(this);
    this.changeMapStyle = this.changeMapStyle.bind(this);
    this.catchMapsApi = this.catchMapsApi.bind(this);
    this.onClusterClick = this.onClusterClick.bind(this);
    this.toggleTrafficLayer = this.toggleTrafficLayer.bind(this);

    //Widget
    this.selectWidget = this.selectWidget.bind(this);
    this.updateSelectWidget = this.updateSelectWidget.bind(this);
    this.closeWidget = this.closeWidget.bind(this);

    //Focus marker
    this.toggleFocusMarker = this.toggleFocusMarker.bind(this);
    this.toggleFocusMarkerNull = this.toggleFocusMarkerNull.bind(this);

    //Markers
    this.updateVisibleMarker = this.updateVisibleMarker.bind(this);
    this.updateVisibleAllVehicles = this.updateVisibleAllVehicles.bind(this);
    this.updatePrevVisibleMarker = this.updatePrevVisibleMarker.bind(this);
    this.appendToVisibleVehicles = this.appendToVisibleVehicles.bind(this);
    this.appendToVisibleAllVehicles =
      this.appendToVisibleAllVehicles.bind(this);
    this.changeValue = this.changeValue.bind(this);

    //Components
    this.changePositionLeft = this.changePositionLeft.bind(this);
    this.listOfDevices = this.listOfDevices.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleModalGroupUnits = this.toggleModalGroupUnits.bind(this);
    this.toggleModalUpdateUnit = this.toggleModalUpdateUnit.bind(this);
    this.ModalUpdateUnit = this.ModalUpdateUnit.bind(this);
    this.justAppendToVisibleVehicles =
      this.justAppendToVisibleVehicles.bind(this);
  }

  componentDidMount() {
    this.props.listUnitsBuffer();
    this.props.updateListUnitsBuffer();
    this.props.getCoolorsMotion();
    this.props.getImagesMotion();
    this.timer = setInterval(() => {
      this.props.updateListUnitsBuffer();
      if (this.state.miniMaps.length > 0) {
        this.updateMiniMaps();
      }
    }, 5000);
  }

  componentDidUpdate(prevProps, prevState) {
    let auth = JSON.parse(localStorage.getItem("auth_user"));
    if (auth === null) {
      window.location.replace("/session/login");
    }
    if (this.props.buffer[1]) {
      this.updateVisibleMarker();
    }
    if (this.props.buffer[0]) {
      this.updatePrevVisibleMarker();
    }
    if (this.state.visibleVehicles.length > 0) {
      if (
        this.state.visibleVehicles.length === this.props.buffer[1].length &&
        this.state.showAllVisible === false
      ) {
        this.setState({
          showAllVisible: true,
        });
      } else if (
        this.state.visibleVehicles.length !== this.props.buffer[1].length &&
        this.state.showAllVisible === true
      ) {
        this.setState({
          showAllVisible: false,
        });
      }
    }
  }

  setCenterMap(c) {
    this.setState({
      center: c,
    });
  }

  setZoomMap(z) {
    this.setState({
      zoom: z,
    });
  }

  changePositionLeft(t) {
    this.setState({ positionLeft: t });
  }

  handleToggleList() {
    let dropdownlist = document.getElementsByClassName("dropDownList")[0];
    let groupbaricon = dropdownlist.getElementsByClassName("groupBarIcon")[0];
    groupbaricon.classList.toggle("iconUntransformed");
    this.setState({ showList: !this.state.showList });
  }

  //MiniMaps
  appendToMiniMaps(idOfMarker) {
    //console.warn("se activo funcion apendTominimaps");
    //console.warn(idOfMarker);
    let minimaps = this.state.miniMaps;

    let row = Math.floor(
      (document.getElementById("containerMapGoogle").clientWidth -
        (16 + 270 + 16 + 40)) /
        230
    );

    let max = row * 2;

    const isAlredyInMiniMaps = minimaps.filter(
      (minimap) => minimap.id === parseInt(idOfMarker)
    );
    if (isAlredyInMiniMaps[0]) {
      //console.warn("se encontro " + isAlredyInMiniMaps);
      return;
    } else {
      if (this.state.miniMaps.length < max) {
        let locations = this.props.buffer[0];
        let value = locations.filter(
          (device) => device.id === parseInt(idOfMarker)
        );
        //console.warn("se encontruk " + value[0].name);
        minimaps.push(value[0]);
        this.setState({
          miniMaps: minimaps,
          miniMapIdSelect: [...this.state.miniMapIdSelect, idOfMarker],
        });
      } else {
        this.setState({
          miniMapErr: true,
        });
      }
    }
  }

  closeMiniMapErr() {
    this.setState({
      miniMapErr: false,
    });
  }

  updateMiniMaps() {
    if (this.state.miniMaps.length > 0) {
      for (var i = 0; i < this.state.miniMapIdSelect.length; i++) {
        let id_select = parseInt(this.state.miniMapIdSelect[i]);
        let unitdata = this.props.buffer[1].filter((unit) => {
          return unit.id === id_select;
        });
        const index = this.state.miniMaps.findIndex((x) => x.id === id_select);
        if (unitdata[0]) {
          this.setState((state) => {
            return {
              miniMaps: update(state.miniMaps, {
                [index]: { $set: unitdata[0] },
              }),
            };
          });
        }
      }
    }
  }

  deleteFromMiniMaps(idOfMarker) {
    const isAlredyInMiniMaps = this.state.miniMaps.filter(
      (minimap) => minimap.id === idOfMarker
    );
    if (isAlredyInMiniMaps[0]) {
      this.setState({
        miniMaps: this.state.miniMaps.filter(
          (minimap) => minimap.id !== idOfMarker
        ),
      });
    }
  }

  toggleMiniMaps(event) {
    this.setState({ showMiniMaps: !this.state.showMiniMaps });
    if (this.state.showMiniMaps) {
      event.target.setAttribute("class", "layerIcon");
    } else event.target.setAttribute("class", "layerIconS");
  }

  hideMiniMaps() {
    if (this.state.showMiniMaps !== false) {
      this.setState({ showMiniMaps: false });
    }
  }

  userLocationClick() {
    this.setState({ userLocation: !this.state.userLocation });
  }

  changeValue(id, value) {
    const selected = document.getElementById(id);

    selected.setAttribute("value", value);
    this.setState({ mapStyle: selected });
  }

  changeMap(value) {
    this.setState({ mapType: value });
  }

  //Measure
  measureClick() {
    this.setState({ measure: !this.state.measure });
  }

  //CLusters
  onClusterClick(event) {
    this.setState({ clusterVisible: !this.state.clusterVisible });

    setTimeout(
      () => this.setState({ clusterVisible: this.state.clusterVisible }),
      10
    );

    if (this.state.clusterVisible) {
      event.target.setAttribute("class", "layerIcon");
    } else event.target.setAttribute("class", "layerIconS");
  }

  //Markers
  appendToVisibleVehicles(id) {
    let array = this.state.visibleVehicles;
    let arrayPrev = this.state.prevVisibleVehicles;
    //obtenemos unidad data de vehiclelocations
    let id_select = parseInt(id);
    // this.setCenterMap([unitdata[0].latitud, unitdata[0].longitud]);
    //preguntamos si ya existe
    let existUnit = this.state.visibleVehicles.filter(
      (unit) => unit.id === id_select
    );
    //si ya existe sacar
    if (existUnit.length === 1) {
      //eliminar del arreglo
      let newArray = array.filter((unit) => unit.id !== id_select);
      let newArrayPrev = arrayPrev.filter((unit) => unit.id !== id_select);
      if (this.state.idSelectedMarkers.includes(id_select)) {
        var idToDelete = this.state.idSelectedMarkers.filter((id) => {
          return id !== id_select;
        });
        this.setState({
          idSelectedMarkers: idToDelete,
        });
      }
      this.setState({
        visibleVehicles: newArray,
        prevVisibleVehicles: newArrayPrev,
      });
    } else {
      let unitdata = this.props.buffer[1].filter(
        (unit) => unit.id === id_select
      );
      let prevUnitdata = this.props.buffer[0].filter(
        (unit) => unit.id === id_select
      );

      this.setState({
        visibleVehicles: [...this.state.visibleVehicles, ...unitdata],
        prevVisibleVehicles: [
          ...this.state.prevVisibleVehicles,
          ...prevUnitdata,
        ],
      });
      if (!this.state.idSelectedMarkers.includes(id_select)) {
        this.setState({
          idSelectedMarkers: [...this.state.idSelectedMarkers, id_select],
        });
      }
    }
  }

  justAppendToVisibleVehicles(id) {
    //obtenemos unidad data de vehiclelocations
    let id_select = parseInt(id);
    // this.setCenterMap([unitdata[0].latitud, unitdata[0].longitud]);
    //preguntamos si ya existe
    let existUnit = this.state.visibleVehicles.filter(
      (unit) => unit.id === id_select
    );
    //si ya existe sacar
    if (existUnit.length === 1) {
    } else {
      let unitdata = this.props.buffer[1].filter(
        (unit) => unit.id === id_select
      );
      let prevUnitdata = this.props.buffer[0].filter(
        (unit) => unit.id === id_select
      );

      this.setState({
        visibleVehicles: [...this.state.visibleVehicles, ...unitdata],
        prevVisibleVehicles: [
          ...this.state.prevVisibleVehicles,
          ...prevUnitdata,
        ],
      });
      if (!this.state.idSelectedMarkers.includes(id_select)) {
        this.setState({
          idSelectedMarkers: [...this.state.idSelectedMarkers, id_select],
        });
      }
    }
  }

  updateVisibleMarker() {
    if (this.state.visibleVehicles.length > 0) {
      for (var i = 0; i < this.state.idSelectedMarkers.length; i++) {
        let id_select = parseInt(this.state.idSelectedMarkers[i]);

        let unitdata = this.props.buffer[1].filter((unit) => {
          return unit.id === id_select;
        });
        const index = this.state.visibleVehicles.findIndex(
          (x) => x.id === id_select
        );
        if (unitdata[0]) {
          this.state.visibleVehicles[index] = unitdata[0];
        }
      }
    }
  }

  updatePrevVisibleMarker() {
    if (
      this.state.prevVisibleVehicles &&
      this.state.prevVisibleVehicles.length > 0
    ) {
      for (var i = 0; i < this.state.idSelectedMarkers.length; i++) {
        let id_select = parseInt(this.state.idSelectedMarkers[i]);

        const index = this.state.visibleVehicles.findIndex(
          (x) => x.id === id_select
        );
        let prevUnitdata = this.props.buffer[0].filter((unit) => {
          return unit.id === id_select;
        });
        if (prevUnitdata[0]) {
          this.state.prevVisibleVehicles[index] = prevUnitdata[0];
        }
      }
    }
  }

  appendToVisibleAllVehicles() {
    if (this.state.showAllVisible) {
      this.setState({
        visibleVehicles: [],
        prevVisibleVehicles: [],
        idSelectedMarkers: [],
        showAllVisible: !this.state.showAllVisible,
      });
    } else {
      let i;
      let idsSelected = [];
      for (i = 0; i < this.props.buffer[1].length; i++) {
        let id_selected = this.props.buffer[1][i].id;
        if (!this.state.idSelectedMarkers.includes(id_selected)) {
          idsSelected.push(id_selected);
        }
      }
      this.setState({
        visibleVehicles: this.props.buffer[1],
        prevVisibleVehicles: this.props.buffer[0],
        idSelectedMarkers: idsSelected,
        showAllVisible: !this.state.showAllVisible,
      });
    }
  }

  updateVisibleAllVehicles() {
    // this.setState({
    //   visibleVehicles: this.props.data.vehicleLocations,
    // });
  }

  handleClickedMarker(id) {
    this.setState({ clickedMarker: id, hoverMarker: null });
  }

  handleUnClickedMarker() {
    this.setState({ clickedMarker: null });
  }

  handleDbClickedMarker(id) {
    this.setState({
      selectedMarker: id,
      clickedMarker: null,
      hoverMarker: null,
    });
  }

  handleUnDbClickedMarker() {
    this.setState({ selectedMarker: null });
  }

  handleHoverMarker(id) {
    this.setState({ hoverMarker: id });
  }

  handleUnHoverMarker() {
    this.setState({ hoverMarker: null, clickedMarker: null });
  }

  //Traffic
  toggleTrafficLayer(event) {
    // the event context comes from the Child
    if (!this.state.traffic) {
      this.state.trafficLayer.setMap(this.state.map);
    } else {
      this.state.trafficLayer.setMap(null);
    }
    this.setState({ traffic: !this.state.traffic });
    if (this.state.traffic) {
      event.target.setAttribute("class", "layerIcon");
    } else event.target.setAttribute("class", "layerIconS");
  }

  onDriversClick(event) {
    this.setState({ drivers: !this.state.drivers });
    if (this.state.drivers) {
      event.target.setAttribute("class", "layerIcon");
    } else event.target.setAttribute("class", "layerIconS");
  }

  onDevicesClick(event) {
    this.setState({ devices: !this.state.devices });
    if (this.state.devices) {
      event.target.setAttribute("class", "layerIcon");
    } else event.target.setAttribute("class", "layerIconS");
  }

  handleDisplayCard(id) {
    if (this.state.displayCardMarker !== id) {
      this.setState({ displayCardMarker: id });
    }
  }

  unDisplayCard() {
    this.setState({ displayCardMarker: null });
  }

  showAllMiniMaps() {
    this.setState({ showMiniMaps: true });
  }

  catchMapsApi(map, maps) {
    this.setState({
      map: map,
      Maps: maps,
      trafficLayer: new maps.TrafficLayer(),
      measureObj: new MeasureTool(map),
    });
  }

  //Widget
  selectWidget(id) {
    //obtenemos unidad data de vehiclelocations
    let id_select = parseInt(id);

    // this.setCenterMap([unitdata[0].latitud, unitdata[0].longitud]);
    //preguntamos si ya existe
    let existUnit = this.state.visibleVehicles.filter(
      (unit) => unit.id === id_select
    );
    //si ya existe sacar
    if ((existUnit.length === 1) === false) {
      let unitdata = this.props.buffer[1].filter(
        (unit) => unit.id === id_select
      );
      let prevUnitdata = this.props.buffer[0].filter(
        (unit) => unit.id === id_select
      );

      this.setState({
        visibleVehicles: [...this.state.visibleVehicles, ...unitdata],
        prevVisibleVehicles: [
          ...this.state.prevVisibleVehicles,
          ...prevUnitdata,
        ],
      });
      if (!this.state.idSelectedMarkers.includes(id_select)) {
        this.setState({
          idSelectedMarkers: [...this.state.idSelectedMarkers, id_select],
        });
      }
    }

    let marker_select = this.props.buffer[1];
    let result = marker_select.filter((marker) => marker.id === id_select);
    this.setZoomMap(17);
    this.setState({
      marker: result,
      showWidget: true,
      prevShowMiniMaps: this.state.showMiniMaps,
      showMiniMaps: false,
    });
    this.timeWidget = setInterval(() => {
      this.updateSelectWidget();
    }, 1000);
  }

  updateSelectWidget() {
    const id_select = parseInt(this.state.selectedMarker);
    if (this.props.buffer[0].length > 0) {
      let result = this.props.buffer[0].filter(
        (marker) => marker.id === id_select
      );
      if (result[0] && result[0].position) {
        const LatLng = [
          result[0].position.latitud,
          result[0].position.longitud,
        ];
        this.setZoomMap(17);
        this.setCenterMap(LatLng);
        this.setState({
          marker: result,
        });
      }
    }
  }

  closeWidget() {
    this.setZoomMap(5);
    this.setState({
      marker: null,
      selectedMarker: null,
      showWidget: false,
      showMiniMaps: this.state.prevShowMiniMaps,
    });
    clearInterval(this.timeWidget);
  }

  //FocusMarker
  toggleFocusMarker(id) {
    this.setState({
      focusMarker: id,
    });
  }

  toggleFocusMarkerNull() {
    this.setState({
      focusMarker: null,
    });
  }

  //ListOfDevice
  listOfDevices() {
    if (
      this.props.buffer[0] &&
      this.props.buffer[1] &&
      this.props.viewListOfDevice &&
      !this.state.showWidget
    ) {
      return (
        <ListOfDevices
          data={this.props.data}
          appendToVisibleVehicles={this.appendToVisibleVehicles}
          appendToVisibleAllVehicles={this.appendToVisibleAllVehicles}
          visibleVehicles={this.state.visibleVehicles}
          vehicleLocations={this.props.buffer[0]}
          onMarkerClick={this.handleClickedMarker}
          unMarkerClick={this.handleUnClickedMarker}
          onMarkerDbClick={this.handleDbClickedMarker}
          appendToMiniMaps={this.appendToMiniMaps}
          zoom={this.state.zoom}
          setCenterMap={this.setCenterMap}
          setZoomMap={this.setZoomMap}
          selectedMarker={this.state.selectedMarker}
          showList={this.state.showList}
          handleToggleList={this.handleToggleList}
          unSelect={this.handleUnDbClickedMarker}
          selectWidget={this.selectWidget}
          toggleModal={this.props.toggleModal}
          toggleModalGroupUnits={this.toggleModalGroupUnits}
          showAllVisible={this.state.showAllVisible}
          toggleFocusMarker={this.toggleFocusMarker}
          toggleFocusMarkerNull={this.toggleFocusMarkerNull}
          miniMaps={this.state.miniMaps}
          toggleModalUpdateUnit={this.toggleModalUpdateUnit}
          justAppendToVisibleVehicles={this.justAppendToVisibleVehicles}
        />
      );
    } else {
      return null;
    }
  }

  //Modal
  toggleModal() {
    this.props.toggleModal();
  }

  modalUnit() {
    if (this.props.showModal) {
      return (
        <ModalUnit
          showModal={this.props.showModal}
          toggleModal={this.toggleModal}
        />
      );
    } else {
      return <div />;
    }
  }

  toggleModalGroupUnits() {
    this.setState({
      showModalUnitsGroups: !this.state.showModalUnitsGroups,
    });
  }

  ModalUnitsGroups() {
    if (this.state.showModalUnitsGroups) {
      return (
        <ModalGroups
          toggleModalGroupUnits={this.toggleModalGroupUnits}
          vehicleLocations={this.props.data.vehicleLocations}
        />
      );
    } else {
      return <div />;
    }
  }

  toggleModalUpdateUnit(id) {
    if (id) {
      const id_select = parseInt(id);
      const updateUnitModal = this.props.buffer[0].filter((unit) => {
        return unit.id === id_select;
      });
      this.setState({
        updateUnitModal: updateUnitModal,
      });
    }
    this.setState({
      showModalUpdateUnit: !this.state.showModalUpdateUnit,
    });
  }

  ModalUpdateUnit() {
    if (this.state.showModalUpdateUnit) {
      return (
        <ModalUpdateUnit
          toggleModalUpdateUnit={this.toggleModalUpdateUnit}
          updateUnitModal={this.state.updateUnitModal[0]}
        />
      );
    }
  }

  changeMapStyle(mapStyle) {
    this.setState({
      mapStyle: mapStyle,
    });
  }

  render() {
    if (this.props.buffer) {
      return (
        <React.Fragment>
          {this.listOfDevices(this.state.visibleVehicles)}
          <MapGoogle
            data={this.props.data}
            traffic={this.state.traffic}
            devices={this.state.devices}
            drivers={this.state.drivers}
            marker={this.state.marker}
            hoverMarker={this.state.hoverMarker}
            clickedMarker={this.state.clickedMarker}
            selectedMarker={this.state.selectedMarker}
            visibleVehicles={this.state.visibleVehicles}
            unMarkerDbClick={this.handleUnDbClickedMarker}
            unMarkerClick={this.handleUnClickedMarker}
            onMarkerDbClick={this.handleDbClickedMarker}
            clusterVisible={this.state.clusterVisible}
            mapStyle={this.state.mapStyle}
            userLocation={this.state.userLocation}
            appendToMiniMaps={this.appendToMiniMaps}
            deleteFromMiniMaps={this.deleteFromMiniMaps}
            miniMaps={this.state.miniMaps}
            toggleMiniMaps={this.toggleMiniMaps}
            showMiniMaps={this.state.showMiniMaps}
            hideMiniMaps={this.hideMiniMaps}
            showAllMiniMaps={this.showAllMiniMaps}
            positionLeft={this.state.positionLeft}
            changePositionLeft={this.changePositionLeft}
            showList={this.state.showList}
            center={this.state.center}
            zoom={this.state.zoom}
            setCenterMap={this.setCenterMap}
            setZoomMap={this.setZoomMap}
            handleHoverMarker={this.handleHoverMarker}
            handleUnHoverMarker={this.handleUnHoverMarker}
            catchMapsApi={this.catchMapsApi}
            measure={this.state.measure}
            map={this.state.map}
            maps={this.state.Maps}
            showWidget={this.state.showWidget}
            idSelect={this.state.idSelect}
            prevVisibleVehicles={this.state.prevVisibleVehicles}
            idSelectedMarkers={this.state.idSelectedMarkers}
            focusMarker={this.state.focusMarker}
            markersToRenderMiniMap={this.state.miniMaps}
            closeFunctionMiniMap={this.deleteFromMiniMaps}
            miniMapErr={this.state.miniMapErr}
            closeMiniMapErr={this.closeMiniMapErr}
          />
          <LayerControl
            devices={this.state.devices}
            drivers={this.state.drivers}
            height={this.state.height}
            displayList={this.state.displayList}
            showList={this.state.showList}
            mapStyle={this.state.mapStyle}
            changeMapStyle={this.changeMapStyle}
            changeMapType={this.props.changeMapType}
            onTrafficClick={this.toggleTrafficLayer}
            onDriversClick={this.onDriversClick}
            onDevicesClick={this.onDevicesClick}
            onClusterClick={this.onClusterClick}
            measureClick={this.measureClick}
            userLocationClick={this.props.userLocationClick}
            showMiniMaps={this.props.showMiniMaps}
            toggleMiniMaps={this.toggleMiniMaps}
          />
          {/*<RenderMiniMaps
            markersToRenderMiniMap={this.state.miniMaps}
            closeFunction={this.deleteFromMiniMaps}
            markers={this.props.data.vehicleLocations}
            onMarkerClick={this.handleClickedMarker}
          />*/}
          <MiniMapsPane
            markersToRenderMiniMap={this.state.miniMaps}
            closeFunction={this.deleteFromMiniMaps}
            showMiniMaps={this.state.showMiniMaps}
            markers={this.props.data.vehicleLocations}
            showWidget={this.state.showWidget}
            marker={this.state.marker}
            maps={this.state.Maps}
            map={this.state.map}
          />
          {this.modalUnit()}
          {this.ModalUnitsGroups()}
          {this.ModalUpdateUnit()}
          {this.state.selectedMarker ? (
            <Widget
              showAllMiniMaps={this.showAllMiniMaps}
              selectedMarker={this.state.selectedMarker}
              closeWidget={this.closeWidget}
              unSelectMarker={this.handleUnDbClickedMarker}
              marker={this.state.marker}
            />
          ) : null}
          <FilterSvg />
        </React.Fragment>
      );
    } else {
      return <div>Loading...</div>;
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
