import {
  //Buffer
  SAVE_UNIT_LIST_BUFFER,
  SAVE_UNIT_LIST_BUFFER_FAILED,
} from "../types/UnitsTypes";

const initialState = {
  isLoading: true,
  isHistoryLoading: false,
  errMess: null,
  buffer: [],
};

const BufferReducer = function (state = initialState, action) {
  switch (action.type) {
    case SAVE_UNIT_LIST_BUFFER:
      if (state.buffer.length < 1) {
        return {
          isLoading: false,
          buffer: [action.payload, action.payload],
        };
      } else if (state.buffer.length < 2) {
        return {
          isLoading: false,
          buffer: [...state.buffer, action.payload],
        };
      } else {
        state.buffer.shift();
        return {
          isLoading: false,
          buffer: [...state.buffer, action.payload],
        };
      }
    case SAVE_UNIT_LIST_BUFFER_FAILED:
      return { isLoading: false, errMess: action.payload };

    default:
      return state;
  }
};

export default BufferReducer;
