import React, { Component } from "react";
import Draggable from "react-draggable";
import GoogleMapReact from "google-map-react";

import mapStyle from "../../../MAP/mapStyles/mapStyle";
import "./CardMiniMap.scss";
import Marker from "./components/Marker/Marker";
import { ReactComponent as IcoUser } from "../../assets/motion_icon-user.svg";

function countdown(s) {
  var h = Math.floor(s / 3600);
  if (h < 10) {
    h = "0" + h;
  }

  s -= h * 3600;

  var m = Math.floor(s / 60);
  if (m < 10) {
    m = "0" + m;
  }

  s -= m * 60;

  const tmp = [];

  tmp.push(h + ":");

  tmp.push(m + ":");
  if (s < 10) {
    s = "0" + s;
  }

  tmp.push(s);

  return tmp.join("");
}

export default class CardMiniMap extends Component {
  constructor() {
    super();
    this.state = {
      urlStreetView: "",
      expanded: true,
      iframe: null,
      x: 1100,
      y: 0,
      zoom: 9,
      mapOptions2: {
        disableDefaultUI: true,
        disableDoubleClickZoom: true,
        zoom: 17,
        options: {
          gestureHandling: "greedy",
        },
        // styles: this.props.mapStyle,
      },
      center: {},
      i: 0,
      delay: 10,
      numDeltas: 60,
      showStreetView: false,
    };
    this.setExpanded = this.setExpanded.bind(this);
    this.openExpanded = this.openExpanded.bind(this);
    this.closeExpanded = this.closeExpanded.bind(this);
    //transition
    this.transition = this.transition.bind(this);
    this.moveMarker = this.moveMarker.bind(this);
    this.handleStreetViewPanorama = this.handleStreetViewPanorama.bind(this);
  }
  componentDidMount() {
    this.setState({
      lat: this.props.lat,
      lng: this.props.lng,
      orientacion: this.props.orientacion,
      center: { lat: this.props.lat, lng: this.props.lng },
      mapOptions2: {
        disableDefaultUI: true,
        disableDoubleClickZoom: true,
        zoom: 17,
        options: {
          gestureHandling: "greedy",
        },
        styles:
          this.props.mapStyle === "darkMode"
            ? mapStyle.darkMode
            : this.props.mapStyle === "silverMode"
            ? mapStyle.silverMode
            : "default",
      },
    });
    this.updatePosition = setInterval(() => {}, 1000);
    let value = this.props.index.findIndex((device) => {
      return device.id === this.props.id;
    });
    this.setState({
      value: value + 1,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.mapStyle !== this.props.mapStyle) {
      this.setState({
        mapOptions2: {
          ...this.state.mapOptions2,
          styles:
            this.props.mapStyle === "darkMode"
              ? mapStyle.darkMode
              : this.props.mapStyle === "silverMode"
              ? mapStyle.silverMode
              : "default",
        },
      });
    }
    if (this.state.showStreetView) {
      const newPosition = new this.state.maps.LatLng({
        lat: this.props.lat,
        lng: this.props.lng,
        heading: this.props.orientacion,
      });
      if (
        prevProps.lat !== this.props.lat ||
        prevProps.lng !== this.props.lng
      ) {
        this.state.panorama.setPosition(newPosition);
        this.state.panorama.setPov({
          heading: this.props.orientacion,
          pitch: 0,
        });
      }
    }
    if (prevProps.lat !== this.props.lat || prevProps.lng !== this.props.lng) {
      this.transition();
    }
    if (this.props.value !== prevProps.value) {
      let value = this.props.index.findIndex((device) => {
        return device.id === this.props.id;
      });
      this.setState({
        value: value + 1,
      });
    }
  }

  handleStreetViewPanorama() {
    this.setState({
      showStreetView: !this.state.showStreetView,
    });
  }

  createMapOptions = () => {
    return {
      disableDefaultUI: true,
      disableDoubleClickZoom: true,
      options: {
        gestureHandling: "greedy",
      },

      minZoom: 5,
      maxZoom: 17,
      mapTypeControl: false,
    };
  };
  setExpanded() {
    this.setState({
      expanded: !this.state.expanded,
    });
  }
  closeExpanded() {
    this.setState({
      expanded: false,
    });
  }
  openExpanded() {
    this.setState({
      expanded: true,
    });
  }
  onMapLoaded(map, maps) {
    this.setState({
      mapsLoaded: true,
      map: map,
      maps: maps,
    });
    const fenway = {
      lat: this.props.lat,
      lng: this.props.lng,
    };
    const panorama = new maps.StreetViewPanorama(
      document.getElementById(`MiniMapPanorama${this.props.id}`),
      {
        position: fenway,
        pov: {
          heading: this.props.orientacion,
          pitch: 10,
        },
        addressControl: false,
        linksControl: false,
        panControl: false,
        enableCloseButton: false,
      }
    );
    map.setStreetView(panorama);
    this.setState({
      panorama: panorama,
    });
  }
  transition() {
    let deltaLat;
    let deltaLng;

    deltaLat = (this.props.lat - this.state.lat) / this.state.numDeltas;
    deltaLng = (this.props.lng - this.state.lng) / this.state.numDeltas;

    if (
      this.props.lat !== this.state.lat &&
      this.props.lng !== this.state.lng &&
      deltaLat !== 0 &&
      deltaLng !== 0
    ) {
      this.moveMarker({
        deltaLat: deltaLat,
        deltaLng: deltaLng,
      });
    }
  }

  moveMarker(data) {
    this.time = setTimeout(() => {
      this.setState({
        i: this.state.i + 1,
        lat: this.state.lat + data.deltaLat,
        lng: this.state.lng + data.deltaLng,
        center: [this.props.lat, this.props.lng],
      });
      if (this.state.i <= this.state.numDeltas) {
        //  if the counter < 10, call the loop function
        this.moveMarker(data); //  ..  again which will trigger another
      } else {
        this.setState({
          i: 0,
        });
        clearInterval(this.time);
        this.time = null;
      }
    }, this.state.delay);
  }
  render() {
    let marker = this.props.mdata;
    let options = !this.state.expanded ? (
      <div className="mmOptions">
        <span>@Aqui las opciones y el action</span>
      </div>
    ) : (
      <div />
    );
    /*******       ************* */
    if (marker) {
      return (
        <>
          {this.state.value ? (
            this.state.value <= this.props.row ? (
              <Draggable
                defaultPosition={{
                  x: this.props.width - 235 * this.state.value,
                  y: this.props.height - 210,
                }}
                handle=".handle"
                bounds={"parent"}
              >
                <div
                  className="cardMiniMap"
                  key={"minimap_" + this.props.id}
                  style={{ width: 220, height: 200 }}
                >
                  <div className="miniMapHeaderContainer handle">
                    <div className="miniMapHeaderInfoContainer">
                      <div className="miniMapHeaderFont">{this.props.name}</div>
                      <div className="miniMapSubFont">Subtitle</div>
                    </div>
                    <div className="miniMapLastUpdate">
                      {"(" + countdown(7200) + ")"}
                    </div>
                    <img
                      className="miniMapCloseIcon"
                      src="/assets/images/svg/zoomIn.svg"
                      onClick={() => {
                        this.props.closeFunction(this.props.id);
                      }}
                      alt=""
                    />
                  </div>
                  <div
                    className="miniMapView"
                    style={{
                      height: "calc(100% - 48px - 20px)",
                      position: "relative",
                      width: "100%",
                    }}
                  >
                    <div style={{ width: "100%", height: "100%" }}>
                      <GoogleMapReact
                        defaultZoom={17}
                        center={this.state.center}
                        bootstrapURLKeys={{
                          key: "AIzaSyCWfddIiuoWK-1AwcNN13nNkO-C2L1to0g",
                          libraries: [
                            "visualization",
                            "directions",
                            "places",
                            "drawing",
                          ],
                        }}
                        options={this.state.mapOptions2}
                        yesIWantToUseGoogleMapApiInternals={true}
                        onGoogleApiLoaded={({ map, maps }) =>
                          this.onMapLoaded(map, maps)
                        }
                      >
                        <Marker
                          key={this.props.id}
                          id={this.props.id}
                          lat={this.state.lat}
                          lng={this.state.lng}
                          type={this.props.type}
                          orientation={this.props.orientacion}
                          devices={this.props.devices}
                          drivers={this.props.drivers}
                          speed={this.props.speed}
                          signal={this.props.signal}
                          color={this.props.color}
                        />
                      </GoogleMapReact>
                      <IcoUser
                        className={
                          !this.state.showStreetView
                            ? "icoUser-street"
                            : "icoUser-street active"
                        }
                        onClick={this.handleStreetViewPanorama}
                        style={{
                          zIndex: "2",
                        }}
                      />
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "absolute",
                          top: "0px",
                        }}
                      ></div>
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "absolute",
                          top: "0px",
                          zIndex: this.state.showStreetView ? "1" : "-1",
                        }}
                        id={`MiniMapPanorama${this.props.id}`}
                      ></div>
                    </div>
                    {/* <div
                    style={{ width: "100%", height: "100%" }}
                    id={`MiniMapPanorama${this.props.id}`}
                  ></div> */}
                  </div>
                  <div className="miniMapOptions">
                    <img
                      className="miniMapOptionsIcon"
                      src="/assets/images/svg/motion_icon-28.svg"
                      onClick={this.setExpanded}
                      alt=""
                    />
                  </div>
                  {options}
                </div>
              </Draggable>
            ) : (
              <Draggable
                defaultPosition={{
                  x:
                    this.props.width -
                    235 * (this.state.value - this.props.row),
                  y: 16,
                }}
                handle=".handle"
                bounds={"parent"}
              >
                <div
                  className="cardMiniMap"
                  key={"minimap_" + this.props.id}
                  style={{ width: 220, height: 200 }}
                >
                  <div className="miniMapHeaderContainer handle">
                    <div className="miniMapHeaderInfoContainer">
                      <div className="miniMapHeaderFont">{this.props.name}</div>
                      <div className="miniMapSubFont">Subtitle</div>
                    </div>
                    <div className="miniMapLastUpdate">
                      {"(" + countdown(7200) + ")"}
                    </div>
                    <img
                      className="miniMapCloseIcon"
                      src="/assets/images/svg/zoomIn.svg"
                      onClick={() => {
                        this.props.closeFunction(this.props.id);
                      }}
                      alt=""
                    />
                  </div>
                  <div
                    className="miniMapView"
                    style={{
                      height: "calc(100% - 48px - 20px)",
                      position: "relative",
                      width: "100%",
                    }}
                  >
                    <div style={{ width: "100%", height: "100%" }}>
                      <GoogleMapReact
                        defaultZoom={17}
                        center={this.state.center}
                        bootstrapURLKeys={{
                          key: "AIzaSyCWfddIiuoWK-1AwcNN13nNkO-C2L1to0g",
                          libraries: [
                            "visualization",
                            "directions",
                            "places",
                            "drawing",
                          ],
                        }}
                        options={this.state.mapOptions2}
                        yesIWantToUseGoogleMapApiInternals={true}
                        onGoogleApiLoaded={({ map, maps }) =>
                          this.onMapLoaded(map, maps)
                        }
                      >
                        <Marker
                          key={this.props.id}
                          id={this.props.id}
                          lat={this.state.lat}
                          lng={this.state.lng}
                          type={this.props.type}
                          orientation={this.props.orientacion}
                          devices={this.props.devices}
                          drivers={this.props.drivers}
                          speed={this.props.speed}
                          signal={this.props.signal}
                          color={this.props.color}
                        />
                      </GoogleMapReact>
                      <IcoUser
                        className={
                          !this.state.showStreetView
                            ? "icoUser-street"
                            : "icoUser-street active"
                        }
                        onClick={this.handleStreetViewPanorama}
                        style={{
                          zIndex: "2",
                        }}
                      />
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "absolute",
                          top: "0px",
                        }}
                      ></div>
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "absolute",
                          top: "0px",
                          zIndex: this.state.showStreetView ? "1" : "-1",
                        }}
                        id={`MiniMapPanorama${this.props.id}`}
                      ></div>
                    </div>
                    {/* <div
                    style={{ width: "100%", height: "100%" }}
                    id={`MiniMapPanorama${this.props.id}`}
                  ></div> */}
                  </div>
                  <div className="miniMapOptions">
                    <img
                      className="miniMapOptionsIcon"
                      src="/assets/images/svg/motion_icon-28.svg"
                      onClick={this.setExpanded}
                      alt=""
                    />
                  </div>
                  {options}
                </div>
              </Draggable>
            )
          ) : null}
        </>
      );
    } else {
      return <div></div>;
    }
  }
}
