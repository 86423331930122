import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { createUser } from "../../../../../../redux/actions/UserActions";

const mapStateToProps = (state) => ({
  createAccount: PropTypes.func.isRequired,
  usuarios: state.usuarios,
});

class Users extends Component {
  constructor(props) {
    super();
    this.state = {
      usuarios: {
        nombre: "",
        email: "",
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.peticion = this.peticion.bind(this);
  }
  handleChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState({
      usuarios: {
        ...this.state.usuarios,
        [name]: value,
      },
    });
  }
  peticion(event) {
    event.preventDefault();
    let name = this.state.usuarios.nombre;
    let email = this.state.usuarios.email;

    this.props.createUser({
      name,
      email,
    });
  }
  render() {
    console.log(this.state.usuarios)
    return (
      <div className="form-hiddenav">
        <h1>Nueva Usuario</h1>
        <form>
          <div>
            <h2>Nombre</h2>
            <input
              type="text"
              placeholder="nombre"
              name="nombre"
              onChange={this.handleChange}
              value={this.state.usuarios.nombre}
            />
          </div>
          <div>
            <h2>Email</h2>
            <input
              type="text"
              placeholder="email"
              name="email"
              onChange={this.handleChange}
              value={this.state.usuarios.email}
            />
          </div>
          <button onClick={this.peticion}>Crear Usuario</button>
        </form>
      </div>
    );
  }
}

export default connect(mapStateToProps, { createUser })(Users);
