import {
  GET_DEVICES,
  GET_DEVICES_FAILED,
  GET_DEVICES_SUCCESS,
} from "../types/DevicesTypes";

const initialState = {
  isLoading: true,
  isHistoryLoading: false,
  errMess: null,
  devices: [],
};

const DevicesReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_DEVICES:
      return {
        isLoading: true,
        devices: [...state.devices],
      };
    case GET_DEVICES_SUCCESS:
      return {
        isLoading: false,
        devices: action.payload,
      };
    case GET_DEVICES_FAILED:
      return {
        isLoading: false,
        devices: [...state.devices],
        errMess: action.payload,
      };
    default:
      return state;
  }
};

export default DevicesReducer;
