import history from "history.js";
import localStorageService from "../../services/localStorageService";
import axios from "axios";
import qs from "qs";


export const SET_USER_DATA = "USER_SET_DATA";
export const REMOVE_USER_DATA = "USER_REMOVE_DATA";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";


export const ADD_USER_TO_MOTION = "ADD_USER_TO_MOTION";
export const ADD_USER_TO_MOTION_SUCCESS = "ADD_USER_TO_MOTION_SUCCESS";
export const DATA_FAILED = "DATA_FAILED";

/* Aqui probare la accion de crear nueva unidad*/
export function createUser({ name, email }) {
  return (dispatch) => {
    let data = qs.stringify({
      name: name,
      email: email,
    });
    console.log(data);
    var config = {
      method: "POST",
      url: "/api/tracking/users",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios(config)
      .then(
        (response) => {
          if (response.status === 200) {
            console.log(response);
            return response;
          } else {
            var error = new Error("oops, something went wrong");
            console.log(error);
            error.response = response;
            throw error;
          }
        },
        (error) => {
          console.log(error);
          var errmess = new Error(error);
          throw errmess;
        }
      )
      .then((response) => dispatch(createUserSuccess(response.result)))
      .catch((error) => dispatch(dataFailed(error.message)));
  };
}

//Create unit

export function createUserPost(payload) {
  return { type: ADD_USER_TO_MOTION, payload };
}

export function createUserSuccess(payload) {
  const byId = { [payload.result]: payload };
  return { type: ADD_USER_TO_MOTION_SUCCESS, payload: { byId } };
}

export const dataFailed = (errmess) => ({
  type: DATA_FAILED,
  payload: errmess,
});


export function setUserData(user) {
  localStorageService.setItem("auth_user", user);
  return (dispatch) => {
    dispatch({
      type: SET_USER_DATA,
      data: user,
    });
  };
}

export function logoutUser() {
  axios
    .get("/web/session/logout")
    .then(function (response) {
      // handle success
      console.log(response);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    });
  return (dispatch) => {
    //motionAuthService.logout(); el axios anterior reemplazaria este llamado 
    localStorage.removeItem("auth_user");
    history.push({
      pathname: "/session/login",
    });
    dispatch({
      type: USER_LOGGED_OUT,
    });
  };
}
