import React, { Component } from "react";
import { connect } from "react-redux";
import { ReactComponent as MoreAppsIcon } from "../../assets/motion_icon-22.svg";
import { ReactComponent as MapIcon } from "../../assets/mapIcon.svg";
import { ReactComponent as LocationIcon } from "../../assets/userlocation.svg";

import "./LayerControl.scss";

import { UncontrolledTooltip } from "reactstrap";
import MoreApps from "../MoreApps/MoreApps";
import {
  getCurrentPosition,
  deleteCurrentPosition,
} from "app/redux/actions/PositionAction";

const mapStateToProps = (state) => ({
  position: state.PositionReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getCurrentPosition: ({ lat, lng }) => {
    dispatch(
      getCurrentPosition({
        lat,
        lng,
      })
    );
  },
  deleteCurrentPosition: () => {
    dispatch(deleteCurrentPosition());
  },
});

class LayerControl extends Component {
  constructor() {
    super();
    this.state = {
      showMoreApps: false,
      showCurrentLocation: false,
    };
    this.toggleMoreApps = this.toggleMoreApps.bind(this);
    this.getCurrrentLocation = this.getCurrrentLocation.bind(this);
  }

  componentDidUpdate() {
    if (this.props.showMiniMaps) {
      let minimaps = document.getElementsByClassName("miniMapsContainer");
      let minimaps2 = document.getElementsByClassName("miniMapsContainer2");

      if (minimaps) {
        for (let i = 0; i < minimaps.length; i++) {
          minimaps[i].style.display = "inline-block";
        }
      }

      if (minimaps2) {
        for (let i = 0; i < minimaps2.length; i++) {
          minimaps2[i].style.display = "inline-block";
        }
      }
    }
    if (!this.props.showMiniMaps) {
      let minimaps = document.getElementsByClassName("miniMapsContainer");
      if (minimaps) {
        for (let i = 0; i < minimaps.length; i++) {
          minimaps[i].style.display = "none";
        }
      }
      let minimaps2 = document.getElementsByClassName("miniMapsContainer2");
      if (minimaps2) {
        for (let i = 0; i < minimaps2.length; i++) {
          minimaps2[i].style.display = "none";
        }
      }
    }
  }

  toggleMoreApps() {
    this.setState({
      showMoreApps: !this.state.showMoreApps,
    });
  }

  getCurrrentLocation() {
    if (!this.state.showCurrentLocation) {
      this.setState({
        showCurrentLocation: !this.state.showCurrentLocation,
      });
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          this.props.getCurrentPosition({ lat: pos.lat, lng: pos.lng });
        });
      } else {
        // Browser doesn't support Geolocation
        alert("not support geolocation");
      }
    } else {
      this.setState({
        showCurrentLocation: !this.state.showCurrentLocation,
      });
      this.props.deleteCurrentPosition();
    }
  }

  render() {
    return (
      <div className="LayerControl column">
        <div className="LayerControl-top column">
          <div className="moreAppsIcon-content">
            <MoreAppsIcon
              className="moreAppsIcon"
              id="MoreApps"
              onClick={this.toggleMoreApps}
            />
            {this.state.showMoreApps ? <MoreApps /> : null}
          </div>
          <UncontrolledTooltip
            placement="left"
            target={"MoreApps"}
            className="moreApps"
            innerClassName="tooltip-moreapps"
            autohide={false}
          >
            <div className="moreAppsTooltip">
              <p>Más Apps</p>
            </div>
          </UncontrolledTooltip>
        </div>

        <div className="LayerControl-body column">
          <img
            onClick={this.props.onTrafficClick}
            className="layerIcon"
            alt="layer control icon"
            src="/assets/images/svg/motion_icon-44.svg"
            id="traffic"
          />
          <UncontrolledTooltip
            placement="left"
            target={"traffic"}
            className="layers"
            innerClassName="tooltip-layer"
            autohide={false}
          >
            <div className="layerTooltip">
              <p>Tráfico</p>
            </div>
          </UncontrolledTooltip>
          <img
            className="layerIcon"
            onClick={this.props.onDevicesClick}
            alt="layer control icon"
            src="/assets/images/svg/motion_icon-46.svg"
            id="deviceLayer"
          />
          <UncontrolledTooltip
            placement="left"
            target={"deviceLayer"}
            className="layers"
            innerClassName="tooltip-layer"
            autohide={false}
          >
            <div className="layerTooltip">
              <p>Unidades</p>
            </div>
          </UncontrolledTooltip>

          <img
            className="layerIcon"
            onClick={this.props.onDriversClick}
            alt="layer control icon"
            src="/assets/images/svg/motion_icon-04.svg"
            id="driversLayer"
          />
          <UncontrolledTooltip
            placement="left"
            target={"driversLayer"}
            className="layers"
            innerClassName="tooltip-layer"
            autohide={false}
          >
            <div className="layerTooltip">
              <p>Conductores</p>
            </div>
          </UncontrolledTooltip>
          <img
            className="layerIconS"
            onClick={this.props.onClusterClick}
            src="/assets/images/svg/motion_icon-45.svg"
            id="clusterLayer"
            alt=""
          />
          <UncontrolledTooltip
            placement="left"
            target={"clusterLayer"}
            className="layers"
            innerClassName="tooltip-layer"
            autohide={false}
          >
            <div className="layerTooltip">
              <p>Clusters</p>
            </div>
          </UncontrolledTooltip>
          <img
            className="layerIconS"
            alt="layer control icon"
            src="/assets/images/svg/minimapas.svg"
            id="minimapas"
            onClick={this.props.toggleMiniMaps}
          />
          <UncontrolledTooltip
            placement="left"
            target={"minimapas"}
            className="layers"
            innerClassName="tooltip-layer"
            autohide={false}
          >
            <div className="layerTooltip">
              <p>Minimapas</p>
            </div>
          </UncontrolledTooltip>

          <img
            alt="layer control icon"
            className="layerIcon"
            src="/assets/images/svg/motion_icon-48.svg"
          />
          <img
            className="layerIcon"
            alt="layer control icon"
            src="/assets/images/svg/motion_icon-43.svg"
          />
          <img
            className="layerIcon"
            alt="layer control icon"
            src="/assets/images/svg/motion_icon-47.svg"
          />
          <img
            className="layerIcon"
            alt="layer control icon"
            src="/assets/images/svg/motion_icon-03.svg"
          />
          <img
            className="layerIcon"
            alt="layer control icon"
            src="/assets/images/svg/motion_icon-01.svg"
          />
          <LocationIcon
            className={
              this.state.showCurrentLocation ? "layerIconS" : "layerIcon"
            }
            alt="layer control icon"
            onClick={this.getCurrrentLocation}
          />
        </div>
        <div className="LayerControl-bottom column  ">
          <div className="mapIcon-content" id="style-selector">
            <MapIcon className="mapIcon" fill="#8b8c8c" value="silver" />
          </div>
          <UncontrolledTooltip
            placement="left"
            target={"style-selector"}
            className="mapTooltip"
            innerClassName="tooltip-inner1"
            value="silver"
            autohide={false}
          >
            <ul className="mapOptions">
              <li
                className={`optionsItem ${
                  this.props.mapStyle === "default" ? "selectedMap" : ""
                }`}
                onClick={() => this.props.changeMapStyle("default")}
              >
                Google Maps
              </li>
              <li
                className={`optionsItem ${
                  this.props.mapStyle === "silverMode" ? "selectedMap" : ""
                }`}
                onClick={() => this.props.changeMapStyle("silverMode")}
              >
                Motion Map
              </li>
              <li
                className={`optionsItem ${
                  this.props.mapStyle === "darkMode" ? "selectedMap" : ""
                }`}
                onClick={() => this.props.changeMapStyle("darkMode")}
              >
                Night Mode
              </li>
            </ul>
          </UncontrolledTooltip>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LayerControl);
