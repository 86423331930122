import React, { Component } from "react";
import {
  selectRutaMarker,
  hidenRutaModal,
} from "../../../../../../redux/actions/RutaAction";
import Draggable from "react-draggable";
import { ReactComponent as IconExit } from "./assets/motion_icon-salir-cerrar.svg";
import "./Route.scss";
import Select from "react-select";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    buffer: state.BufferReducer.buffer,
    RutaReducer: state.RutaReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  selectRutaMarker: (id) => {
    dispatch(selectRutaMarker(id));
  },
  hidenRutaModal: () => {
    dispatch(hidenRutaModal());
  },
});

class Route extends Component {
  constructor() {
    super();
    this.state = {
      address: "",
    };
    this.formatTime = this.formatTime.bind(this);
  }

  componentDidMount() {
    this.formatTime();
    let index;
    if (this.props.RutaReducer.RutaMarker) {
      index = this.props.buffer[0].findIndex((item) => {
        return item.id === this.props.RutaReducer.RutaMarker[0].id;
      });
      console.log(index);
    } else {
      index = null;
    }
    const options = this.props.buffer[0].map((item) => ({
      value: item.id,
      label: item.name,
    }));
    this.setState({
      index: index,
      options: options,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.duration !== this.props.duration) {
      this.formatTime();
    }
    if (
      prevProps.address !== this.props.address &&
      this.props.address !== undefined
    ) {
      this.setState({
        address: this.props.address,
      });
    }
  }

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.props.geocodeAddress(e.target.value);
    }
  };

  handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState({
      [name]: value,
    });
  };

  onDataChange = (value, action) => {
    this.props.selectRutaMarker(value.value);
  };

  formatTime() {
    if (this.props.duration) {
      const time = this.props.duration;
      var horas = Math.floor(time);
      var minutos = Math.floor((time - Math.floor(time)) * 60);
      this.setState({
        horas: horas,
        minutos: minutos,
      });
    }
  }

  render() {
    return (
      <Draggable
        defaultPosition={{
          x: 300,
          y: 16,
        }}
        handle=".handle"
        bounds={"parent"}
      >
        <div
          className="column card-ruta"
          style={{ height: this.props.duration ? "240px" : "210px" }}
        >
          <div className="ruta-header handle row">
            <div>Calcular ruta</div>
            <IconExit
              className="ruta-exit"
              onClick={this.props.hidenRutaModal}
            />
          </div>
          <div className="ruta-body column">
            <div>
              <div className="ruta-text">Seleccione la unidad</div>
              {this.state.options ? (
                <Select
                  options={this.state.options}
                  name="UnitsIds"
                  onChange={this.onDataChange}
                  defaultValue={
                    this.state.index || this.state.index === 0
                      ? this.state.options[this.state.index]
                      : null
                  }
                />
              ) : null}
            </div>
            <div>
              <div className="ruta-text">Destino</div>
              <input
                className="ruta-input"
                onKeyDown={this.handleKeyDown}
                onChange={this.handleChange}
                placeholder="Direccion"
                value={this.state.address}
                name="address"
              ></input>
            </div>
            <div className="row ruta-container-button">
              <button
                className="btn2"
                onClick={() => this.props.geocodeAddress(this.state.address)}
              >
                Calcular
              </button>
            </div>
            {this.props.duration ? (
              <div className="row ruta-information">
                <div className="ruta-km">{`${this.props.kilometros} km`}</div>
                <div className="ruta-hours">{`${this.state.horas} h ${this.state.minutos} min`}</div>
              </div>
            ) : null}
          </div>
        </div>
      </Draggable>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Route);
