import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { createAccount } from "../../../../../../redux/actions/AccountAction";

const mapStateToProps = (state) => ({
  createAccount: PropTypes.func.isRequired,
  cuentas: state.cuentas,
});

class Accounts extends Component {
  constructor(props) {
    super();
    this.state = {
      cuentas: {
        nombre: "",
        comentario: "",
      },
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState({
      cuentas: {
        ...this.state.cuentas,
        [name]: value,
      },
    });
  }
  peticion(event) {
    event.preventDefault();
    let name = this.state.cuentas.nombre;
    let comentario = this.state.cuentas.comentario;

    this.props.createAccount({
      name,
      comentario,
    });
  }
  render() {
    return (
      <div className="form-hiddenav">
        <h1>Nueva Cuenta</h1>
        <form>
          <div>
            <h2>Nombre</h2>
            <input
              type="text"
              placeholder="nombre"
              value={this.state.cuentas.nombre}
              name="nombre"
              onChange={this.handleChange}
            />
          </div>
          <div>
            <h2>Comentario</h2>
            <input
              type="text"
              placeholder="comentario"
              value={this.state.cuentas.comentario}
              name="comentario"
              onChange={this.handleChange}
            />
          </div>
          <button onClick={(event) => this.peticion(event)}>
            Crear Cuenta
          </button>
        </form>
      </div>
    );
  }
}

export default connect(mapStateToProps, { createAccount })(Accounts);
