import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Switch, Icon, MenuItem, Tooltip, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { connect } from "react-redux";

import { logoutUser } from "app/redux/actions/UserActions";
import { Link, withRouter } from "react-router-dom";
import NavMenu from "./NavMenu/NavMenu";
import Sidenav from "./SideNav/SideNav";
import SidenavTheme from "./SideNav/SidenavTheme/SidenavTheme";

import { isMdScreen } from "utils";

import "./LeftNav.scss";

const IconButtonWhite = withStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    padding: "5px",
  },
}))(IconButton);

const IconSmall = withStyles(() => ({
  root: {
    fontSize: "1rem",
  },
}))(Icon);

class LeftNav extends Component {
  constructor() {
    super();
    this.state = {
      hidden: true,
      showSideNav: false,
    };
    this.toggleLeftNav = this.toggleLeftNav.bind(this);
  }
  componentDidMount() {
    // CLOSE SIDENAV ON ROUTE CHANGE ON MOBILE
    this.unlistenRouteChange = this.props.history.listen((location, action) => {
      if (isMdScreen()) {
      }
    });

    setTimeout(() => {
      this.setState({ hidden: false });
    }, 400);
  }

  componentWillUnmount() {
    this.unlistenRouteChange();
  }

  handleSidenavToggle = () => {
    let {
      settings: {
        layout1Settings: {
          leftSidebar: { mode },
        },
      },
    } = this.props;

    console.log(mode);
  };

  handleSignOut = () => {
    this.props.logoutUser();
  };

  renderLogoSwitch = () => (
    // Open Brand component file to replace logo and text
    <Switch
      className="sidenav__toggle show-on-pc"
      onChange={this.handleSidenavToggle}
      checked={
        !(this.props.settings.layout1Settings.leftSidebar.mode === "full")
      }
      color="secondary"
    />
  );

  renderUser = () => {
    let auth = this.props.auth;
    return (
      <div className="sidenav__user">
        <div className="username-photo">
          <img src={auth.photoURL} alt="user" />
        </div>
        <div className="ml-4">
          <span className="username">{auth.displayName}</span>
          <span className="username">{auth.email}</span>
          <div className="user__menu">
            <NavMenu
              menuButton={
                <Tooltip title="Settings">
                  <IconButtonWhite
                    aria-label="Delete"
                    className="icon-user"
                    size="small"
                  >
                    <IconSmall> settings </IconSmall>
                  </IconButtonWhite>
                </Tooltip>
              }
            >
              <MenuItem className="flex items-center">
                <Icon> home </Icon>
                <span className="pl-4"> Home </span>
              </MenuItem>
              <MenuItem className="flex items-center">
                <Icon> settings </Icon>
                <span className="pl-4"> Account Setting </span>
              </MenuItem>
            </NavMenu>

            <Tooltip title="Profile">
              <IconButtonWhite
                aria-label="Delete"
                className="icon-user"
                size="small"
              >
                <IconSmall>person</IconSmall>
              </IconButtonWhite>
            </Tooltip>
            <Link to="/web/session/logout">
              <Tooltip title="Sign out">
                <IconButtonWhite
                  aria-label="Delete"
                  className="icon-user"
                  size="small"
                  onClick={this.handleSignOut}
                >
                  <IconSmall>exit_to_app</IconSmall>
                </IconButtonWhite>
              </Tooltip>
            </Link>
          </div>
        </div>
      </div>
    );
  };

  toggleLeftNav() {
    this.setState({
      showSideNav: !this.state.showSideNav,
    });
  }

  render() {
    return (
      <SidenavTheme>
        {!this.state.hidden && (
          <div
            className={`${
              this.state.showSideNav ? "sidenav expand" : "sidenav"
            }`}
            onMouseEnter={() => {
              this.setState({
                showSideNav: true,
              });
            }}
            onMouseLeave={() => {
              this.setState({
                showSideNav: false,
              });
            }}
            onClick={this.toggleLeftNav}
          >
            <div className="sidenav__hold">
              <Fragment>
                {/*this.renderLogoSwitch()*/}
                <Sidenav
                  toggleModal={this.props.toggleModal}
                  toggleHiddeNav={this.props.toggleHiddeNav}
                  viewListOfDevice={this.props.viewListOfDevice}
                >
                  {this.renderUser()}
                </Sidenav>
              </Fragment>
            </div>
          </div>
        )}
      </SidenavTheme>
    );
  }
}

LeftNav.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  logoutUser: PropTypes.func.isRequired,
  user: state.user,
});

export default withRouter(
  connect(mapStateToProps, {
    logoutUser,
  })(LeftNav)
);
