import {
  ADD_DRIVER_TO_MOTION,
  ADD_DRIVER_TO_MOTION_SUCCESS,
  DATA_FAILED,
} from "../actions/driverActions";

const initialState = {
  isLoading: true,
  errMess: null,
  params: {},
};

const DriverReducer = function (state = initialState, action) {
  switch (action.type) {
    case ADD_DRIVER_TO_MOTION:
      return {
        ...state,
        isLoading: true,
        errMess: null,
      };
    case ADD_DRIVER_TO_MOTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errMess: null,
      };
    case DATA_FAILED:
      return { ...state, isLoading: false, errMess: action.payload };
    default:
      return state;
  }
};

export default DriverReducer;
