import {
  SHOW_RUTA_MODAL,
  HIDEN_RUTA_MODAL,
  SELECTED_RUTA,
} from "../types/RutaTypes";

export function showRutaModal(id) {
  let id_select = parseInt(id);
  return (dispatch, getState) => {
    const { BufferReducer } = getState();
    let RutaMarker = BufferReducer.buffer[0].filter(
      (unit) => unit.id === id_select
    );
    dispatch({ type: SHOW_RUTA_MODAL, payload: RutaMarker });
  };
}

export function hidenRutaModal(id) {
  let id_select = parseInt(id);
  return (dispatch, getState) => {
    const { BufferReducer } = getState();
    let RutaMarker = BufferReducer.buffer[0].filter(
      (unit) => unit.id === id_select
    );
    dispatch({ type: HIDEN_RUTA_MODAL, payload: RutaMarker });
  };
}

export function selectRutaMarker(id) {
  let id_select = parseInt(id);
  return (dispatch, getState) => {
    const { BufferReducer } = getState();
    let RutaMarker = BufferReducer.buffer[0].filter(
      (unit) => unit.id === id_select
    );
    dispatch({ type: SELECTED_RUTA, payload: RutaMarker });
  };
}
