import React, { Fragment, Component } from "react";
import Scrollbar from "react-perfect-scrollbar";

import VerticalNav from "./VerticalNav/VerticalNav";

class SideNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: null,
    };
  }

  render() {
    return (
      <Fragment>
        <Scrollbar
          options={{ suppressScrollX: true }}
          className="scrollable position-relative"
        >
          {this.props.children}
          <VerticalNav
            toggleModal={this.props.toggleModal}
            toggleHiddeNav={this.props.toggleHiddeNav}
            viewListOfDevice={this.props.viewListOfDevice}
          />
        </Scrollbar>
        <div className="sidenav__overlay" />
      </Fragment>
    );
  }
}
export default SideNav;
