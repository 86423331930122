import {
  GET_POSITION_SUCCESS,
  DELETE_POSITION_SUCCESS,
} from "../types/PositionTypes";

export function getCurrentPosition({ lat, lng }) {
  return (dispatch) => {
    const LatLng = { lat, lng };
    dispatch({ type: GET_POSITION_SUCCESS, payload: LatLng });
  };
}

export function deleteCurrentPosition() {
  return (dispatch) => {
    dispatch({ type: DELETE_POSITION_SUCCESS, payload: "Delete" });
  };
}
