import { UncontrolledTooltip } from "reactstrap";
import { ReactComponent as ShowIcon } from "../../assets/motion_icon-27.svg";
import { ReactComponent as OptionsIcon } from "../../assets/motion_icon-28.svg";

import { ReactComponent as SignalIcon } from "../../assets/signal/motion_icon-09.svg";
import { ReactComponent as SignalIconLow } from "../../assets/signal/motion_icon-nivel-senal-baja.svg";
import { ReactComponent as SignalIconAvarage } from "../../assets/signal/motion_icon-nivel-senal-media.svg";

import { ReactComponent as HideIcon } from "../../assets/motion_icon-51.svg";
import React, { Component } from "react";
import DeviceOptions from "../extraTest/DeviceOptions";

import "./DeviceCard.scss";

class DeviceCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showVisible: true,
      onOff: true,
      imgSecondary: true,
    };
    this.handleSetCenter = this.handleSetCenter.bind(this);
    this.toggleVisible = this.toggleVisible.bind(this);
    this.levelOfSignal = this.levelOfSignal.bind(this);
    this.iconVisible = this.iconVisible.bind(this);
    this.clickMarker = this.clickMarker.bind(this);
  }

  handleSetCenter() {
    this.props.setCenter([this.props.lat, this.props.lng]);
    //console.warn(this.props.lat);
  }
  toggleVisible() {
    this.setState({
      showVisible: !this.state.showVisible,
    });
  }
  handleVisible(id) {
    this.props.makeVehicleVisible(id);
    this.toggleVisible();
  }

  imageStatus = () => {
    return (
      <React.Fragment>
        <UncontrolledTooltip
          style={{ cursor: "pointer" }}
          placement="right"
          target={"direc" + this.props.id.toString()}
          className="tooltipImageStop"
        >
          <div className="deviceOff">
            <div className="deviceStopStatus">Encendido</div>
            <div className="deviceTimeStopStatus">Detenido (00:11:22)</div>
          </div>
        </UncontrolledTooltip>

        <div style={{ position: "relative" }} className="deviceImgPaused">
          <img
            style={{
              position: "absolute",
              width: "30px",
              filter:
                this.props.color.filterCss + "drop-shadow(0px 0px 4px #a8a8a8)",
            }}
            src={`/assets/images/animation/${this.props.type.value}/${this.props.type.value}-lista-color.png`}
            alt=""
          />
          <img
            style={{
              position: "absolute",
              width: "30px",
            }}
            className="deviceImgPaused"
            src={`/assets/images/animation/${this.props.type.value}/${this.props.type.value}-lista-stop.gif`}
            alt="device img"
            id={"direc" + this.props.id.toString()}
          />
        </div>
      </React.Fragment>
    );
  };

  countdown(s) {
    var h = Math.floor(s / 3600);
    if (h < 10) {
      h = "0" + h;
    }

    s -= h * 3600;

    var m = Math.floor(s / 60);
    if (m < 10) {
      m = "0" + m;
    }

    s -= m * 60;

    const tmp = [];

    tmp.push(h + ":");

    tmp.push(m + ":");
    if (s < 10) {
      s = "0" + s;
    }

    tmp.push(s);

    return tmp.join("");
  }
  // funcion que retorna el estado del dispositivo.
  stateOfDevice = () => {
    //contador abajo ...
    var date = Date.now();
    var lastReport = this.props.vehicle.last_date_positionn;
    // si el motor esta apagado ...
    if (this.props.engine === false) {
      // si el ultimo reporte es menor a 300?(unknown unit measure) ...
      if (lastReport < date) {
        return (
          <React.Fragment>
            <UncontrolledTooltip
              style={{ cursor: "pointer" }}
              placement="right"
              target={"stateOff" + this.props.id.toString()}
              className="tooltipImageStop"
            >
              <div className="deviceOff">
                <div className="deviceOffStatus">Apagado</div>
                <div className="deviceTimeOffStatus">Detenido (00:11:22)</div>
              </div>
            </UncontrolledTooltip>
            <img
              className="deviceIcons"
              src="/assets/images/svg/off.svg"
              id={"stateOff" + this.props.id.toString()}
              alt="deviceIcons"
            />
          </React.Fragment>
        );
      }
      // si el ultimo reporte es mayor a 300?(unknown unit measure) ...
      if (lastReport > date) {
        return (
          <React.Fragment>
            <UncontrolledTooltip
              style={{ cursor: "pointer" }}
              placement="right"
              target={"stateOff" + this.props.id.toString()}
              className="tooltipImageStop"
            >
              <div className="deviceOff">
                <div className="deviceOffStatus">Apagado</div>
                <div className="deviceTimeOffStatus">Parqueado (00:11:22)</div>
              </div>
            </UncontrolledTooltip>
            <img
              className="deviceIcons"
              src="/assets/images/svg/parking.svg"
              id={"stateOff" + this.props.id.toString()}
              alt="deviceIcons"
            />
          </React.Fragment>
        );
      }
    }
    // si el motor esta encendido y la velocidad es mayor a 0 (en movimiento)
    if (this.props.engine && this.props.speed > 0) {
      // velocidad menor a 20
      if (this.props.speed < 20)
        return (
          <React.Fragment>
            <UncontrolledTooltip
              style={{ cursor: "pointer" }}
              placement="right"
              target={"stateSlow" + this.props.id.toString()}
              className="speedTooltip"
            >
              <div className="deviceOn">
                <div className="deviceOnStatus">Encendido</div>
                <div className="deviceSpeedStatus">
                  {this.props.speed + " km/h"}
                </div>
              </div>
            </UncontrolledTooltip>
            <img
              className="deviceIcons"
              src="/assets/images/svg/moving-slow.svg"
              id={"stateSlow" + this.props.id.toString()}
              alt="deviceIcons"
            />
          </React.Fragment>
        );
      // velocidad entre 20km y 40km
      if (this.props.speed >= 20 && this.props.speed <= 40)
        return (
          <React.Fragment>
            <img
              className="deviceIcons"
              src="/assets/images/svg/moving.svg"
              id={"stateMedium" + this.props.id.toString()}
              alt="deviceIcons"
            />
            <UncontrolledTooltip
              style={{ cursor: "pointer" }}
              placement="right"
              target={"stateMedium" + this.props.id.toString()}
              className="speedTooltip"
            >
              <div className="deviceOn">
                <div className="deviceOnStatus">Encendido</div>
                <div className="deviceSpeedStatus">
                  {this.props.speed + " km/h"}
                </div>
              </div>
            </UncontrolledTooltip>
          </React.Fragment>
        );
      // velocidad entre 40km y 80km
      if (this.props.speed > 40 && this.props.speed < 80)
        return (
          <React.Fragment>
            <UncontrolledTooltip
              style={{ cursor: "pointer" }}
              placement="right"
              target={"stateFast" + this.props.id.toString()}
              className="speedTooltip"
            >
              <div className="deviceOn">
                <div className="deviceOnStatus">Encendido</div>
                <div className="deviceSpeedStatus">
                  {this.props.speed + " km/h"}
                </div>
              </div>
            </UncontrolledTooltip>
            <img
              className="deviceIcons"
              src="/assets/images/svg/moving-fast.svg"
              id={"stateFast" + this.props.id.toString()}
              alt="deviceIcons"
            />
          </React.Fragment>
        );
      // velocidad encima de 80km/h
      if (this.props.speed >= 80)
        return (
          <React.Fragment>
            <UncontrolledTooltip
              style={{ cursor: "pointer" }}
              placement="right"
              target={"stateFast" + this.props.id.toString()}
              className="speedTooltip"
            >
              <div className="deviceOn">
                <div className="deviceOnStatus">Encendido</div>
                <div className="deviceOverSpeedStatus">
                  {this.props.speed + " km/h"}
                </div>
              </div>
            </UncontrolledTooltip>
            <img
              className="deviceIcons"
              src="/assets/images/svg/SpeedLimit.svg"
              id={"stateFast" + this.props.id.toString()}
              alt="deviceIcons"
            />
          </React.Fragment>
        );
    }

    return (
      <React.Fragment>
        <UncontrolledTooltip
          style={{ cursor: "pointer" }}
          placement="right"
          target={"stateStop" + this.props.id.toString()}
          className="tooltipImageStop"
        >
          <div className="deviceOff">
            <div className="deviceStopStatus">Encendido</div>
            <div className="deviceTimeStopStatus">Detenido (00:11:22)</div>
          </div>
        </UncontrolledTooltip>

        <img
          className="deviceIcons"
          src="/assets/images/svg/stoped.svg"
          id={"stateStop" + this.props.id.toString()}
          alt="deviceIcons"
        />
      </React.Fragment>
    );
  };

  // Funcion de imagen dependiendo de la nivel de señal //Pendiente
  levelOfSignal = () => {
    if (this.props.cnkb <= 0) {
      return (
        <>
          <SignalIcon
            fill="#E2E2E2"
            className="deviceIcons"
            alt="icon img"
            id={"signal" + this.props.id.toString()}
          />
          <UncontrolledTooltip
            style={{ cursor: "pointer" }}
            placement="right"
            target={"signal" + this.props.id.toString()}
            className="signal"
          >
            <div className="deviceSignal">
              {/*Falta Nivel de señas por estado cambiando imagen*/}
              <div className="deviceSignalHead">Nivel de señal</div>
              <div className="deviceSignalStatus">{"Sin Señal"}</div>
            </div>
          </UncontrolledTooltip>
        </>
      );
    } else if (this.props.cnkb < 50000) {
      return (
        <>
          <SignalIconLow
            className="deviceIcons"
            alt="icon img"
            id={"signal" + this.props.id.toString()}
          />
          <UncontrolledTooltip
            style={{ cursor: "pointer" }}
            placement="right"
            target={"signal" + this.props.id.toString()}
            className="signal"
          >
            <div className="deviceSignal">
              {/*Falta Nivel de señas por estado cambiando imagen*/}
              <div className="deviceSignalHead">Nivel de señal</div>
              <div className="deviceSignalStatus">{"Baja"}</div>
            </div>
          </UncontrolledTooltip>
        </>
      );
    } else if (this.props.cnkb <= 100000) {
      return (
        <>
          <SignalIconAvarage
            className="deviceIcons"
            alt="icon img"
            id={"signal" + this.props.id.toString()}
          />
          <UncontrolledTooltip
            style={{ cursor: "pointer" }}
            placement="right"
            target={"signal" + this.props.id.toString()}
            className="signal"
          >
            <div className="deviceSignal">
              {/*Falta Nivel de señas por estado cambiando imagen*/}
              <div className="deviceSignalHead">Nivel de señal</div>
              <div className="deviceSignalStatus">{"Media"}</div>
            </div>
          </UncontrolledTooltip>
        </>
      );
    } else if (this.props.cnkb > 100000) {
      return (
        <>
          <SignalIcon
            fill="#01BEDB"
            className="deviceIcons"
            alt="icon img"
            id={"signal" + this.props.id.toString()}
          />
          <UncontrolledTooltip
            style={{ cursor: "pointer" }}
            placement="right"
            target={"signal" + this.props.id.toString()}
            className="signal"
          >
            <div className="deviceSignal">
              {/*Falta Nivel de señas por estado cambiando imagen*/}
              <div className="deviceSignalHead">Nivel de señal</div>
              <div className="deviceSignalStatus">{"Alta"}</div>
            </div>
          </UncontrolledTooltip>
        </>
      );
    }
  };

  iconVisible() {
    const UnitView = this.props.visibleVehicles.filter((item) => {
      return item.id === this.props.id;
    });
    if (UnitView.length === 1) {
      return (
        <>
          <ShowIcon
            fill="#01bedb"
            alt="icon img"
            id={"deviceIconVisible" + this.props.id.toString()}
            onClick={() => this.handleVisible(this.props.id)}
            className="deviceIcons"
          />
          <UncontrolledTooltip
            style={{ cursor: "pointer" }}
            placement="right"
            className="visibleContainer"
            target={"deviceIconVisible" + this.props.id.toString()}
          >
            <div className="Show">
              <span>Visible</span>
            </div>
          </UncontrolledTooltip>
        </>
      );
    } else {
      return (
        <>
          <HideIcon
            fill="#E2E2E2"
            alt="icon img"
            id={"deviceIconVisible" + this.props.id.toString()}
            className="deviceIcons"
          />
          <UncontrolledTooltip
            style={{ cursor: "pointer" }}
            placement="right"
            className="visibleContainer"
            target={"deviceIconVisible" + this.props.id.toString()}
          >
            <div className="Hide">
              <span>Oculto</span>
            </div>
          </UncontrolledTooltip>
        </>
      );
    }
  }

  clickMarker() {
    this.props.setCenter([this.props.lat, this.props.lng]);
    this.props.setZoomMap(17);
    this.props.justAppendToVisibleVehicles(this.props.id);
  }

  render() {
    //Funcion que retorna la imagen de acuerdo al estado //Hecho
    return (
      <div
        className="deviceCard"
        onMouseEnter={() => this.props.toggleFocusMarker(this.props.id)}
        onMouseLeave={this.props.toggleFocusMarkerNull}
      >
        {this.imageStatus()}
        <div className="deviceInfo" onClick={this.clickMarker}>
          <div className="deviceHeader">{this.props.name}</div>
          <div className="deviceSubtitle">Ciudad </div>
        </div>
        {this.stateOfDevice()}

        {this.levelOfSignal()}
        {this.iconVisible()}
        <OptionsIcon
          fill="#8B8c8c"
          className="optionsIcon"
          alt="icon img"
          id={"options" + this.props.id.toString()}
        />
        <UncontrolledTooltip
          autohide={false}
          placement="right"
          target={"options" + this.props.id.toString()}
          className="options"
        >
          <DeviceOptions
            id={this.props.id.toString()}
            onMarkerDbClick={this.props.onMarkerDbClick}
            appendToMiniMaps={this.props.appendToMiniMaps}
            selectWidget={this.props.selectWidget}
            toggleVisible={this.toggleVisible}
            handleSetCenter={this.handleSetCenter}
            toggleFocusMarkerNull={this.props.toggleFocusMarkerNull}
            makeVehicleVisible={this.props.makeVehicleVisible}
            miniMaps={this.props.miniMaps}
            toggleModalUpdateUnit={this.props.toggleModalUpdateUnit}
          />
        </UncontrolledTooltip>
      </div>
    );
  }
}
export default DeviceCard;
