import React, { Component } from "react";
import { createUnitGroup } from "../../../../../../../redux/actions/UnitsActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Select from "react-select";

import { SketchPicker } from "react-color";

import "./ModalGroups.scss";

const mapStateToProps = (state) => ({
  createUnitGroup: PropTypes.func.isRequired,
  UnitsGroup: state.UnitsGroup,
});

class GroupUnits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UnitsGroup: {
        name: "",
        UnitsIds: null,
        color: "#C5C5C",
      },
      units: [],
      background: "#C5C5C5",
      labelBackground: "Color",
      showPicker: false,
    };
    this.showPicker = this.showPicker.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.peticion = this.peticion.bind(this);
    this.onDataChange = this.onDataChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      units: this.props.vehicleLocations,
    });
  }

  handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState({
      UnitsGroup: {
        ...this.state.UnitsGroup,
        [name]: value,
      },
      background: value,
    });
  };

  handleChangeComplete = (color) => {
    this.setState({
      UnitsGroup: { ...this.state.UnitsGroup, color: color.hex },
      background: color.hex,
      labelBackground: color.hex,
    });
  };

  onDataChange = (value, action) => {
    var values = value != null ? value.map((item) => item.value) : null;
    this.setState({
      UnitsGroup: {
        ...this.state.UnitsGroup,
        [action.name]: values,
      },
    });
  };

  showPicker() {
    this.setState({
      showPicker: !this.state.showPicker,
    });
  }

  peticion(event) {
    event.preventDefault();
    let nameGroup = this.state.UnitsGroup.name;
    let UnitsIds = this.state.UnitsGroup.UnitsIds;
    let color = this.state.UnitsGroup.color;

    this.props.createUnitGroup({
      nameGroup,
      UnitsIds,
      color,
    });
  }
  render() {
    const options = this.state.units.map((item) => ({
      value: item.id,
      label: item.name,
    }));
    return (
      <div className="modal-groups ModalGroups">
        <div className="modal-container">
          <div className="modal-header row">
            <img
              className="miniMapCloseIcon"
              src="/assets/images/svg/zoomIn.svg"
              onClick={this.props.toggleModalGroupUnits}
              alt=""
            />
          </div>
          <form className="modal-form-groups">
            <h2 className="blue2">Nuevo grupo</h2>
            <h3 className="gray1">Seleccione las unidades</h3>
            <div className="row modal-groups-content">
              <div className="column modal-groups-column1">
                <Select
                  options={options}
                  name="UnitsIds"
                  isMulti
                  onChange={this.onDataChange}
                />
              </div>
              <div className="column modal-groups-column2">
                <input
                  className="input-modal"
                  type="text"
                  placeholder="Nombre del grupo"
                  value={this.state.UnitsGroup.name}
                  onChange={this.handleChange}
                  name="name"
                  id="name"
                />
                <div className="row modal-groups-row-color">
                  <select
                    name="color"
                    className="modal-form-units-select1"
                    value={this.state.UnitsGroup.color}
                    onChange={this.handleChange}
                    placeholder="Tipo de vehiculo"
                    style={{ background: `${this.state.background}` }}
                  >
                    <option selected>{this.state.labelBackground}</option>
                    <option value="red">Rojo</option>
                    <option value="yellow">Amarillo</option>
                    <option value="green">Verde</option>
                    <option value="blue">Azul</option>
                    <option value="white">Blanco</option>
                    <option value="black">Negro</option>
                  </select>
                  <div>
                    <div
                      onClick={this.showPicker}
                      className="modal-groups-button-colorpicker"
                      style={{ background: `${this.state.background}` }}
                    ></div>
                    {this.state.showPicker ? (
                      <SketchPicker
                        className="modal-groups-colorpicker"
                        color={this.state.UnitsGroup.color}
                        onChangeComplete={this.handleChangeComplete}
                        name="color"
                        value={this.state.UnitsGroup.color}
                      ></SketchPicker>
                    ) : null}
                  </div>
                </div>
                <button
                  className="button-form"
                  onClick={(event) => this.peticion(event)}
                >
                  Crear grupo
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, { createUnitGroup })(GroupUnits);
