import React, { Component } from "react";
import "./FilterSvg.scss";

export default class FilterSvg extends Component {
  render() {
    return (
      <svg className="defs-only">
        <filter
          id="chromakey"
          colorInterpolationFilters="sRGB"
          x="0"
          y="0"
          height="100%"
          width="100%"
        >
          <feColorMatrix
            type="matrix"
            values="1   0   0   0   0 
                    0   1   0   0   0  
                    0   0   1   0   0 
                    2   -2  2   1   0"
          />
        </filter>
      </svg>
    );
  }
}
