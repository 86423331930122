/*
 * GoogleMap hover example
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import shouldPureComponentUpdate from "react-pure-render/function";
import update from "react-addons-update";

import Map from "google-map-react";
import Polyline from "./Polyline/Polyline";
import Marker from "./Marker/Marker";
import ClusterMarker from "./ClusterMarker";
import mapStyle from "./mapStyles/mapStyle";
import supercluster from "points-cluster";
import CardMiniMap from "../MiniMaps/components/CardMiniMap/CardMiniMap";
import Route from "./Route/Route";
import AlertModal from "../AlertModal/AlertModal";
import { ReactComponent as MiniMapIco } from "../MiniMaps/assets/motion_icon-mini-mapa.svg";

const mapStateToProps = (state) => {
  return {
    data: state.data,
    value: state.value,
    buffer: state.BufferReducer.buffer,
    position: state.PositionReducer,
    RutaReducer: state.RutaReducer,
  };
};

class MapGoogle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapStyle: "silverMode",
      mapsLoaded: false,
      map: null,
      maps: null,
      trafficLayer: null,
      vw: document.documentElement.clientWidth,
      zoom: 9,
      hoverKey: null,
      traffic: false,
      visibleVehicles: [],
      prevVisibleVehicles: [],
      markers: [],
      clusters: [],
      polyline: {},
      delay: 10,
      numDeltas: 80,
      mapOptions: {
        disableDefaultUI: true,
        disableDoubleClickZoom: true,
        zoom: this.props.zoom,
        options: {
          gestureHandling: "greedy",
        },
        minZoom: 5,
        maxZoom: 17,
        center: { lat: 4.658883676657488, lng: -74.06437579086325 },
        mapTypeControl: false,
        styles:
          this.props.mapStyle === "darkMode"
            ? mapStyle.darkMode
            : this.props.mapStyle === "silverMode"
            ? mapStyle.silverMode
            : "default",
      },
      mapOptions2: {
        disableDefaultUI: true,
        disableDoubleClickZoom: true,
        zoom: this.props.zoom,
        options: {
          gestureHandling: "greedy",
        },
        minZoom: 5,
        maxZoom: 17,
        center: { lat: 4.658883676657488, lng: -74.06437579086325 },
        mapTypeControl: false,
        styles:
          this.props.mapStyle === "darkMode"
            ? mapStyle.darkMode
            : this.props.mapStyle === "silverMode"
            ? mapStyle.silverMode
            : "default",
      },
      path: [
        { lat: 1.7752365701570292, lng: -77.19195532071318 },
        { lat: 6.142703721981792, lng: -70.89139152720601 },
      ],
      points: [
        this.props.visibleVehicles.latitud,
        this.props.visibleVehicles.longitud,
        this.props.visibleVehicles.img_type,
      ],
      newMarker: {},
      x: 900,
      y: 500,
    };
    this.clusters = [];
    this.Vehicles = [];
    this.markers = [];
    this.mapDiv = React.createRef();
    this.cluster = [];
    this.Maps = null;
    this.interval = [];
    this.measure = null;
    this.measureObj = null;
    this.userLocateMessage = null;
    this.markersToShowMiniMap = [];
    this.minimapsRendered = [];
    this.handleMapApi = this.handleMapApi.bind(this);
    this.handleZoom = this.handleZoom.bind(this);
    this.assignToTransition = this.assignToTransition.bind(this);
    this.transition = this.transition.bind(this);
    this.moveMarker = this.moveMarker.bind(this);
    this.updateVisibleMarker = this.updateVisibleMarker.bind(this);
    this.updatePrevVisibleMarker = this.updatePrevVisibleMarker.bind(this);
    this.appendToVisibleVehiclesMaps =
      this.appendToVisibleVehiclesMaps.bind(this);

    this.centerCurrentPosition = this.centerCurrentPosition.bind(this);

    //Route
    this.geocodeAddress = this.geocodeAddress.bind(this);
    this.clickAddress = this.clickAddress.bind(this);
    this.calculateAndDisplayRoute = this.calculateAndDisplayRoute.bind(this);
    this.removeRutaMarker = this.removeRutaMarker.bind(this);
  }
  static propTypes = {
    center: PropTypes.array, // @controllable
    zoom: PropTypes.number, // @controllable
    hoverKey: PropTypes.string, // @controllable
    clickKey: PropTypes.string, // @controllable
    onCenterChange: PropTypes.func, // @controllable generated fn
    onZoomChange: PropTypes.func, // @controllable generated fn
    onHoverKeyChange: PropTypes.func, // @controllable generated fn
  };

  shouldComponentUpdate = shouldPureComponentUpdate;

  // aqui las funciones del contructor

  // fin funciones anterior mapa
  createMapOptions = () => {
    return {
      disableDefaultUI: true,
      disableDoubleClickZoom: true,
      zoom: this.props.zoom,
      options: {
        gestureHandling: "greedy",
      },
      minZoom: 5,
      maxZoom: 17,
      center: this.state.center,
      mapTypeControl: false,
      styles:
        this.props.mapStyle === "darkMode"
          ? mapStyle.darkMode
          : this.props.mapStyle === "silverMode"
          ? mapStyle.silverMode
          : "default",
    };
  };

  onMapLoaded(map, maps) {
    const directionsRenderer = new maps.DirectionsRenderer();
    const directionsService = new maps.DirectionsService();
    const geocoder = new maps.Geocoder();
    this.setState({
      ...this.state,
      mapsLoaded: true,
      map: map,
      maps: maps,
      directionsRenderer: directionsRenderer,
      directionsService: directionsService,
      geocoder: geocoder,
    });
    this.props.catchMapsApi(map, maps);
  }

  _onBoundsChange = (center, zoom /* , bounds, marginBounds */) => {
    this.props.setCenterMap(center);
    this.props.setZoomMap(zoom);
  };

  _onChildClick = (key, childProps) => {
    console.warn("Se hizo click en id :");
    //console.warn(key);
    this.props.onCenterChange([childProps.lat, childProps.lng]);
    this.props.setCenterMap([childProps.lat, childProps.lng]);
  };
  // hover
  _onChildMouseEnter = (key /*, childProps */) => {
    this.props.handleHoverMarker(key);
    //console.warn(key);
  };
  // unhover
  _onChildMouseLeave = (/* key, childProps */) => {
    this.props.handleUnHoverMarker();
  };
  /*
  return of render places copy
  
  
  */
  handleMapApi(map, maps) {
    this.props.catchMapsApi(map, maps);
  }

  handleZoom(z) {
    this.props.setZoomMap(z);
  }

  measureTool() {
    // measuring tool
    if (this.props.measure) {
      this.measureObj.start();
    } else {
      this.measureObj.end();
    }
  }

  componentDidMount() {
    this.setState({
      prevVisibleVehicles: this.props.prevVisibleVehicles,
      visibleVehicles: this.props.visibleVehicles,
      idSelectedMarkers: this.props.idSelectedMarkers,
    });
    this.assignToTransition();
    this.time2 = setInterval(() => {
      this.assignToTransition();
      this.appendToVisibleVehiclesMaps();
      this.createClusters(this.props);
      this.updateVisibleMarker();
      this.updatePrevVisibleMarker();
    }, 5000);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.mapStyle !== this.props.mapStyle) {
      this.setState({
        mapOptions2: {
          ...this.state.mapOptions2,
          styles:
            this.props.mapStyle === "darkMode"
              ? mapStyle.darkMode
              : this.props.mapStyle === "silverMode"
              ? mapStyle.silverMode
              : "default",
        },
      });
    }
    if (
      !this.props.RutaReducer.ShowRutaModal &&
      prevProps.RutaReducer.ShowRutaModal !==
        this.props.RutaReducer.ShowRutaModal
    ) {
      this.state.directionsRenderer.setMap(null);
    }
    if (
      this.props.position &&
      this.props.position.CurrentPosition &&
      prevProps.position.CurrentPosition !== this.props.position.CurrentPosition
    ) {
      this.centerCurrentPosition();
    }
    if (
      this.state.visibleVehicles &&
      this.props.visibleVehicles &&
      this.state.visibleVehicles.length !== this.props.visibleVehicles.length
    ) {
      this.setState({
        prevVisibleVehicles: this.props.prevVisibleVehicles,
        visibleVehicles: this.props.visibleVehicles,
        idSelectedMarkers: this.props.idSelectedMarkers,
      });
    }
  }

  assignToTransition() {
    let i;
    for (i = 0; i < this.props.prevVisibleVehicles.length; i++) {
      if (i === 0) {
      }
      if (this.props.visibleVehicles[i] && this.state.prevVisibleVehicles[i]) {
        if (
          this.state.prevVisibleVehicles[i].position.latitud !==
            this.props.visibleVehicles[i].position.latitud &&
          this.state.prevVisibleVehicles[i].position.longitud !==
            this.props.visibleVehicles[i].position.longitud
        ) {
          this.updateVisibleMarker();
          this.updatePrevVisibleMarker();
          if (this.state.map.zoom > 13) {
            var bounds = this.state.map.getBounds();
            var marker = this.props.prevVisibleVehicles[i];
            var currentLocation = new this.props.maps.LatLng(
              marker.position.latitud,
              marker.position.longitud
            );
            if (bounds.contains(currentLocation) === true) {
              this.transition({
                prevPosition: this.props.prevVisibleVehicles[i].position,
                currentPosition: this.props.visibleVehicles[i].position,
                id: this.props.visibleVehicles[i].id,
              });
            }
          }
        }
      }
    }
  }

  appendToVisibleVehiclesMaps() {
    this.setState({
      prevVisibleVehicles: this.props.prevVisibleVehicles,
      visibleVehicles: this.props.visibleVehicles,
      idSelectedMarkers: this.props.idSelectedMarkers,
    });
  }

  updateVisibleMarker() {
    if (
      this.props.visibleVehicles &&
      this.props.buffer[1] &&
      this.props.visibleVehicles.length > 0 &&
      this.props.buffer[1].length > 0
    ) {
      for (var i = 0; i < this.props.idSelectedMarkers.length; i++) {
        let id_select = parseInt(this.props.idSelectedMarkers[i]);
        let unitdata = this.props.buffer[1].filter((unit) => {
          return unit.id === id_select;
        });
        const index = this.state.visibleVehicles.findIndex(
          (x) => x.id === id_select
        );
        if (unitdata[0]) {
          this.setState((state) => {
            return {
              visibleVehicles: update(state.visibleVehicles, {
                [index]: { $set: unitdata[0] },
              }),
            };
          });
        }
      }
    }
  }

  updatePrevVisibleMarker() {
    if (
      this.state.prevVisibleVehicles &&
      this.state.prevVisibleVehicles.length > 0
    ) {
      for (var i = 0; i < this.props.idSelectedMarkers.length; i++) {
        let id_select = parseInt(this.props.idSelectedMarkers[i]);

        const index = this.state.visibleVehicles.findIndex(
          (x) => x.id === id_select
        );
        let prevUnitdata = this.props.buffer[0].filter((unit) => {
          return unit.id === id_select;
        });
        if (prevUnitdata[0]) {
          this.setState((state) => {
            return {
              prevVisibleVehicles: update(state.prevVisibleVehicles, {
                [index]: { $set: prevUnitdata[0] },
              }),
            };
          });
        }
      }
    }
  }

  transition(data) {
    let deltaLat;
    let deltaLng;
    var nameDeltaLat = `deltaLat${data.id}`;
    var nameDeltaLng = `deltaLng${data.id}`;

    deltaLat =
      (data.currentPosition.latitud - data.prevPosition.latitud) /
      this.state.numDeltas;
    deltaLng =
      (data.currentPosition.longitud - data.prevPosition.longitud) /
      this.state.numDeltas;

    this.setState({
      [nameDeltaLat]: deltaLat,
      [nameDeltaLng]: deltaLng,
    });

    var vehicle = this.state.prevVisibleVehicles.filter((item) => {
      return item.id === data.id;
    });

    var newVehicle = this.state.visibleVehicles.filter((item) => {
      return item.id === data.id;
    });

    var vehicles = this.state.prevVisibleVehicles.filter((item) => {
      return item.id !== data.id;
    });
    if (
      data.prevPosition.latitud !== data.currentPosition.latitud &&
      data.prevPosition.longitud !== data.currentPosition.longitud &&
      deltaLat !== 0 &&
      deltaLng !== 0
    ) {
      this.moveMarker({
        vehicle: vehicle,
        newVehicle: newVehicle,
        vehicles: vehicles,
        id: data.id,
      });
    }
  }

  moveMarker(data) {
    var idName = `i${data.id}`;
    var nameDeltaLat = `deltaLat${data.id}`;
    var nameDeltaLng = `deltaLng${data.id}`;
    if (!this.state[idName]) {
      this.setState({
        [idName]: 0,
      });
    }
    this.createClusters(this.props);
    this.updateMoveMarker = setTimeout(async () => {
      const index = this.state.prevVisibleVehicles.findIndex(
        (x) => x.id === data.id
      );
      if (index !== -1) {
        this.setState((state) => {
          return {
            prevVisibleVehicles: update(state.prevVisibleVehicles, {
              [index]: {
                position: {
                  latitud: {
                    $set:
                      state.prevVisibleVehicles[index].position.latitud +
                      this.state[nameDeltaLat],
                  },
                  longitud: {
                    $set:
                      state.prevVisibleVehicles[index].position.longitud +
                      this.state[nameDeltaLng],
                  },
                },
              },
            }),
          };
        });
      }
      this.setState({
        [idName]: this.state[idName] + 1,
      });
      if (this.state[idName] <= this.state.numDeltas) {
        //  if the counter < 10, call the loop function
        await this.moveMarker(data);
      } else {
        this.setState({
          [idName]: 0,
          [nameDeltaLng]: 0,
          [nameDeltaLat]: 0,
        });
        this.updateMoveMarker = null;
      }
    }, this.state.delay);
  }

  getClusters = () => {
    const markersData = this.state.prevVisibleVehicles // fill(0) for loose mode
      .map((vehicle) => ({
        id: vehicle.id,
        lat: vehicle.position.latitud,
        lng: vehicle.position.longitud,
        type: vehicle.img_type,
        orientacion: vehicle.position.orientacion,
        name: vehicle.name,
        velocidad: vehicle.position.velocidad,
        last_date_position: vehicle.last_date_position,
        cnkb: vehicle.position.cnkb,
        color: vehicle.params.color,
      }));

    const clusters = supercluster(markersData, {
      minZoom: 0,
      maxZoom: 16,
      radius: 60,
    });

    return clusters(this.state.mapOptions);
  };

  createClusters = (props) => {
    this.setState({
      clusters: this.state.mapOptions.bounds
        ? this.getClusters(props).map(({ wx, wy, numPoints, points, id }) => ({
            lat: wy,
            lng: wx,
            numPoints,
            id: points[0].id,
            points,
            idVehicle: id,
            emai: points[0].id,
          }))
        : [],
    });
  };

  handleMapChange = ({ center, zoom, bounds }) => {
    this.setState(
      {
        mapOptions: {
          center,
          zoom,
          bounds,
        },
      },
      () => {
        this.createClusters(this.props);
        this.props.setZoomMap(zoom);
        this.props.setCenterMap(center);
      }
    );
    // this.props.zoom = zoom;
  };

  geocodeAddress(address) {
    this.state.geocoder.geocode({ address: address }, (results, status) => {
      if (status === "OK") {
        const origin = {
          lat: this.props.RutaReducer.RutaMarker[0].position.latitud,
          lng: this.props.RutaReducer.RutaMarker[0].position.longitud,
        };
        let destination = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        };
        this.calculateAndDisplayRoute(origin, destination);
      } else {
        console.log("peticion invalida");
      }
    });
  }

  clickAddress({ lat, lng }) {
    if (this.props.RutaReducer.ShowRutaModal) {
      console.log(lat, lng);
      const origin = {
        lat: this.props.RutaReducer.RutaMarker[0].position.latitud,
        lng: this.props.RutaReducer.RutaMarker[0].position.longitud,
      };
      const destination = { lat: lat, lng: lng };
      this.calculateAndDisplayRoute(origin, destination);
    }
  }

  calculateAndDisplayRoute(origin, destination) {
    this.state.directionsRenderer.setMap(null);
    this.state.directionsRenderer.setMap(this.props.map);
    this.state.directionsService.route(
      {
        origin: origin,
        destination: destination,
        // Note that Javascript allows us to access the constant
        // using square brackets and a string value as its
        // "property."
        travelMode: this.props.maps.TravelMode.DRIVING,
        drivingOptions: {
          departureTime: new Date(Date.now()), // for the time N milliseconds from now.
          trafficModel: "optimistic",
        },
      },
      (response, status) => {
        if (status === "OK") {
          this.state.directionsRenderer.setDirections(response);
          console.log(response);
          // Get first route duration
          var route = response.routes[0];
          var duration = 0;
          var kilometros = 0;
          var address = "";

          route.legs.forEach(function (leg) {
            // The leg duration in seconds.
            duration += leg.duration.value;
            kilometros += leg.distance.value;
            address = leg.end_address;
          });

          this.setState({
            duration: (duration + duration * 0.15) / 60 / 60,
            kilometros: kilometros / 1000,
            address: address,
          });
        } else {
          console.log(response.request);
          console.log(status);
        }
      }
    );
  }

  removeRutaMarker() {
    this.state.directionsRenderer.setMap(null);
    this.props.removeRutaMarker();
  }

  renderPolylines() {
    return (
      <Polyline
        map={this.state.map}
        maps={this.state.maps}
        markers={this.state.path}
        position={this.state.polyline}
        marker={this.props.marker}
      />
    );
  }

  centerCurrentPosition() {
    this.props.setCenterMap([
      this.props.position.CurrentPosition.lat,
      this.props.position.CurrentPosition.lng,
    ]);
    this.handleZoom(17);
  }

  render() {
    return (
      <div
        key="google_map"
        id="containerMapGoogle"
        style={{ height: "100vh", width: "100%" }}
      >
        {this.props.showMiniMaps
          ? this.props.markersToRenderMiniMap.length > 0
            ? this.props.markersToRenderMiniMap.map((device) => {
                return (
                  <CardMiniMap
                    x={this.state.x}
                    y={this.state.y}
                    mapStyle={this.props.mapStyle}
                    width={
                      document.getElementById("containerMapGoogle")
                        .clientWidth -
                      (40 + 16)
                    }
                    height={
                      document.getElementById("containerMapGoogle")
                        .clientHeight - 16
                    }
                    max={Math.floor(
                      ((document.getElementById("containerMapGoogle")
                        .clientWidth -
                        (16 + 270 + 16 + 40)) /
                        230) *
                        2
                    )}
                    row={Math.floor(
                      (document.getElementById("containerMapGoogle")
                        .clientWidth -
                        (16 + 270 + 16 + 40)) /
                        230
                    )}
                    value={this.props.markersToRenderMiniMap.length}
                    index={this.props.markersToRenderMiniMap}
                    key={"minimap_" + device.id}
                    id={device.id}
                    lat={device.position.latitud}
                    lng={device.position.longitud}
                    type={device.img_type}
                    orientacion={device.position.orientacion}
                    marca={device.params.brand}
                    modelo={device.params.model}
                    name={device.name}
                    city={device.position.city}
                    speed={device.position.velocidad}
                    lastUpdate={device.position.last_date_position}
                    signal={device.position.cnkb}
                    color={device.params.color}
                    timeMoving="00:45:23"
                    timeStoped="00:02:21"
                    altitud={device.position.altitud}
                    voltage={device.position.battery_lvl}
                    hovered={false}
                    mdata={device}
                    closeFunction={this.props.closeFunctionMiniMap}
                    map={this.props.map}
                    maps={this.props.maps}
                  />
                );
              })
            : null
          : null}
        {this.props.miniMapErr ? (
          <AlertModal
            position={
              document.getElementById("containerMapGoogle")
                ? document.getElementById("containerMapGoogle").clientWidth / 2
                : "50%"
            }
            closeMiniMapErr={this.props.closeMiniMapErr}
            ico={<MiniMapIco className="AlertModal-ico" />}
            message={"No es posible abrir más minimapas"}
          />
        ) : null}
        {this.props.showRutaModal ? (
          <Route
            RutaMarker={this.props.RutaMarker}
            geocodeAddress={this.geocodeAddress}
            map={this.props.map}
            maps={this.props.maps}
            duration={this.state.duration}
            kilometros={this.state.kilometros}
            address={this.state.address}
            removeRutaMarker={this.removeRutaMarker}
          />
        ) : this.props.RutaReducer.ShowRutaModal ? (
          <Route
            RutaMarker={null}
            geocodeAddress={this.geocodeAddress}
            map={this.props.map}
            maps={this.props.maps}
            duration={this.state.duration}
            kilometros={this.state.kilometros}
            address={this.state.address}
            removeRutaMarker={this.removeRutaMarker}
          />
        ) : null}
        <Map
          key="google_mapComponent"
          bootstrapURLKeys={{
            key: "AIzaSyCWfddIiuoWK-1AwcNN13nNkO-C2L1to0g",
            libraries: ["visualization"],
          }}
          center={this.props.center}
          zoom={this.props.zoom}
          onClick={this.clickAddress}
          onChildMouseEnter={this._onChildMouseEnter}
          onChildMouseLeave={this._onChildMouseLeave}
          options={this.state.mapOptions2}
          onChange={this.handleMapChange}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map, maps }) => this.onMapLoaded(map, maps)}
        >
          {this.props.position.CurrentPosition ? (
            <div
              lat={this.props.position.CurrentPosition.lat}
              lng={this.props.position.CurrentPosition.lng}
            >
              Estoy aqui
            </div>
          ) : null}
          {/* {this.props.marker ? this.renderPolylines() : null} */}
          {this.props.clusterVisible
            ? this.state.clusters.map((item) => {
                if (item.numPoints === 1) {
                  const prevOrientation = this.props.buffer[0].filter(
                    (unit) => {
                      return unit.id === item.emai;
                    }
                  );
                  const lastUnits = this.props.buffer[1].filter((unit) => {
                    return unit.id === item.id;
                  });
                  return (
                    <Marker
                      key={item.id}
                      id={item.id}
                      lat={item.points[0].lat}
                      lng={item.points[0].lng}
                      type={lastUnits[0].img_type}
                      color={lastUnits[0].params.color}
                      orientation={
                        prevOrientation[0]
                          ? prevOrientation[0].position.orientacion
                          : item.points[0].orientacion
                      }
                      devices={this.props.devices}
                      drivers={this.props.drivers}
                      brand={item.points[0].brand}
                      modelo="@model"
                      name={item.points[0].name}
                      city="@City"
                      speed={item.points[0].velocidad}
                      lastUpdate={item.points[0].last_date_position}
                      signal={item.points[0].cnkb}
                      //visibleVehicles={this.state.visibleVehicles}
                      timeMoving="@timemoving"
                      timeStoped="@timestoped"
                      altitud={item.points[0].altitud}
                      voltage={item.points[0].battery_lvl}
                      setCenterMap={this.props.setCenterMap}
                      setZoomMap={this.handleZoom}
                      //type={this.state.visibleVehicles[item.id_vehicle - 1].img_type}
                      // use your hover state (from store, react-controllables etc...)
                      hover={this.props.hoverKey === item.id}
                      showWidget={this.props.showWidget}
                      idSelect={this.props.idSelect}
                      focusMarker={this.props.focusMarker}
                      map={this.props.map}
                      maps={this.props.maps}
                      zoom={this.props.zoom}
                    />
                  );
                } else {
                  return (
                    <ClusterMarker
                      key={item.id}
                      lat={item.lat}
                      lng={item.lng}
                      points={item.points}
                      setCenterMap={this.props.setCenterMap}
                      setZoomMap={this.handleZoom}
                    />
                  );
                }
              })
            : this.state.prevVisibleVehicles.map((item) => {
                const prevOrientation = this.props.buffer[0].filter((unit) => {
                  return unit.id === item.id;
                });
                const lastUnits = this.props.buffer[1].filter((unit) => {
                  return unit.id === item.id;
                });
                return (
                  <Marker
                    key={item.id}
                    id={item.id}
                    lat={item.position.latitud}
                    lng={item.position.longitud}
                    type={lastUnits[0].img_type}
                    color={lastUnits[0].params.color}
                    orientation={prevOrientation[0].position.orientacion}
                    devices={this.props.devices}
                    drivers={this.props.drivers}
                    brand={item.params.brand}
                    modelo="@model"
                    name={item.name}
                    city="@City"
                    speed={item.position.velocidad}
                    lastUpdate={item.last_date_position}
                    signal={item.position.cnkb}
                    //visibleVehicles={this.state.visibleVehicles}
                    timeMoving="@timemoving"
                    timeStoped="@timestoped"
                    altitud={item.position.altitud}
                    voltage={item.position.battery_lvl}
                    setCenterMap={this.props.setCenterMap}
                    //type={this.state.visibleVehicles[item.id_vehicle - 1].img_type}
                    // use your hover state (from store, react-controllables etc...)
                    hover={this.props.hoverKey === item.id}
                    setZoomMap={this.handleZoom}
                    showWidget={this.props.showWidget}
                    idSelect={this.props.idSelect}
                    focusMarker={this.props.focusMarker}
                    map={this.props.map}
                    maps={this.props.maps}
                    zoom={this.props.zoom}
                  />
                );
              })}
        </Map>
      </div>
    );
  }
}
export default withRouter(connect(mapStateToProps)(MapGoogle));
