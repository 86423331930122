import {
  //UPDATE
  UPDATE_UNIT,
  UPDATE_UNIT_FAILED,
  UPDATE_UNIT_SUCCESS,
  UPDATE_UNIT_CLOSE,
  //CREATE
  ADD_UNIT_TO_MOTION,
  ADD_UNIT_TO_MOTION_CLOSE,
  ADD_UNIT_TO_MOTION_FAILED,
  ADD_UNIT_TO_MOTION_SUCCESS,
  //New param Units
  GET_UNIT_LIST,
  GET_UNIT_LIST_SUCCESS,
  GET_UNIT_LIST_FAILED,
  GET_UNIT_LIST_UPDATE,
} from "../types/UnitsTypes";

const initialState = {
  isLoading: true,
  isHistoryLoading: false,
  errMess: null,
  vehicleLocations: [],

  isLoadingUpdate: false,
  errMessUpdate: null,
  updateSuccess: false,
  updateUnit: [],
};

const UnitsReducer = function (state = initialState, action) {
  switch (action.type) {
    //Create unit
    case ADD_UNIT_TO_MOTION:
      return { isLoadingCreate: true };
    case ADD_UNIT_TO_MOTION_SUCCESS:
      return {
        isLoadingCreate: false,
        errMessCreate: false,
        createSuccess: true,
      };
    case ADD_UNIT_TO_MOTION_FAILED:
      return {
        isLoadingCreate: false,
        errMessCreate: true,
      };
    case ADD_UNIT_TO_MOTION_CLOSE:
      return {
        isLoadingCreate: false,
        errMessCreate: false,
        createSuccess: false,
      };

    //Get unitList
    case GET_UNIT_LIST:
      return { isLoading: true };
    case GET_UNIT_LIST_SUCCESS:
      return {
        isLoading: false,
        vehicleLocations: action.payload,
      };
    case GET_UNIT_LIST_FAILED:
      return { isLoading: false, errMess: action.payload };
    case GET_UNIT_LIST_UPDATE:
      return {
        isLoading: false,
        vehicleLocations: state.vehicleLocations,
      };

    //Update unit
    case UPDATE_UNIT:
      return { isLoadingUpdate: true };
    case UPDATE_UNIT_SUCCESS:
      return {
        isLoadingUpdate: false,
        errMessUpdate: false,
        updateSuccess: true,
        updateUnit: action.payload,
      };
    case UPDATE_UNIT_CLOSE:
      return {
        isLoadingUpdate: false,
        errMessUpdate: false,
        updateSuccess: false,
      };
    case UPDATE_UNIT_FAILED:
      return {
        isLoadingUpdate: false,
        errMessUpdate: true,
      };
    default:
      return state;
  }
};

export default UnitsReducer;
