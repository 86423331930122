import React, { Component, useState } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as AlertIco } from "./assets/motion_icon-alerta.svg";
import { ReactComponent as CheckIco } from "./assets/motion_icon-checked.svg";

const colores = {
  borde: "#0075FF",
  error: "#c6007e",
  exito: "#01bebd",
};

const LabelError = styled.label`
  font-size: 10px;
  margin-bottom: 0;
  color: #8a8a8a;
  position: absolute;
  bottom: 100%;
  left: 100%;
  height: fit-content;
  width: fit-content;
  background-color: #ffffff;
  border-radius: 5px 5px 5px 0px;
  box-shadow: 0px 0px 10px #8a8a8a;
  padding: 5px;
  z-index: 1;
`;

const InputContent = styled.div`
  position: relative;
  ${(props) =>
    css`
      width: ${props.width};
    `}
`;

const InputGroup = styled.div`
  width: -webkit-fill-available;
  border: 1px solid #c5c5c5;
  border-radius: 7px;
  color: $gray2;
  font-weight: 300;
  font-size: 16px;
  padding: 7px 3px 7px 1rem;
  display: grid;
  grid-template-columns: calc(100% - 15px) 15px;
  align-items: center;
  ${(props) =>
    props.valido &&
    css`
      border: 1px solid ${colores.exito};
    `}
  ${(props) =>
    props.valido === false &&
    css`
      border: 1px solid ${colores.error};
    `}
`;

const ContentIco = styled.div`
  width: 14px;
  height: 14px;
  cursor: pointer;
  position: relative;
`;

const InputStyle = styled.input`
  width: -webkit-fill-available;
  border: none;
  color: $gray2;
  font-weight: 300;
  font-size: 16px;
  background: transparent;
`;

export default function InputMotion({
  state,
  changeField,
  placeholder,
  name,
  err,
  expression,
  validFunction,
  width,
  disabled = false,
}) {
  const [showAlert, setShowAlert] = useState(false);

  const onChange = (e) => {
    if (!disabled) {
      changeField({ ...state, field: e.target.value, ...e });
    }
  };

  const validation = (e) => {
    if (expression) {
      if (expression.test(state.field)) {
        changeField({ ...state, valid: true, ...e });
      } else {
        changeField({ ...state, valid: false, ...e });
      }
    }

    if (validFunction) {
      validFunction();
    }
  };

  return (
    <InputContent width={width}>
      <InputGroup valido={state.valid}>
        <InputStyle
          type="text"
          placeholder={placeholder}
          value={state.field ? state.field : ""}
          onChange={onChange}
          name={name}
          onKeyUp={validation}
          onBlur={validation}
        ></InputStyle>
        {state.valid ? (
          <ContentIco valido={state.valid}>
            <CheckIco fill={colores.exito} />
          </ContentIco>
        ) : state.valid === false ? (
          <ContentIco valido={state.valid}>
            <AlertIco
              fill={colores.error}
              onMouseEnter={() => setShowAlert(true)}
              onMouseLeave={() => setShowAlert(false)}
            />
            {showAlert ? (
              <LabelError
                style={{ cursor: "pointer" }}
                className="speedTooltip"
                valido={showAlert}
              >
                {err}
              </LabelError>
            ) : null}
          </ContentIco>
        ) : null}
      </InputGroup>
    </InputContent>
  );
}
