import "../styles/_app.scss";
import "./App.css";
import "./webfont/style.css";

import React from "react";
import { Provider } from "react-redux";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import AppContext from "./appContext";
import history from "history.js";
import routes from "./RootRoutes";
import { Store } from "./redux/Store";

import Dashboard1 from "./views/dashboard/Analytics";
import SignIn from "./views/sessions/SignIn/SignIn";

function App() {
  return (
    <AppContext.Provider value={{ routes }}>
      <Provider store={Store}>
        <Router history={history}>
          <Switch>
            <Route path="/session/login">
              <SignIn />
            </Route>
            <Route path="/">
              <Dashboard1 />
            </Route>
            <Redirect from="*" to="/session/login" />
          </Switch>
        </Router>
      </Provider>
    </AppContext.Provider>
  );
}

export default App;
