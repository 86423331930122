import React from "react";
import SidenavThemeStyles from "./SidenavThemeStyles";

const SidenavTheme = ({ theme, settings, children }) => {
  return (
    <SidenavThemeStyles theme={theme} settings={settings}>
      {children}
    </SidenavThemeStyles>
  );
};

export default SidenavTheme;
