import { combineReducers } from "redux";
import LoginReducer from "./AuthReducer";
import UserReducer from "./UserReducer";
import UnitReducer from "./UnitsReducers";
import DriverReducer from "./driverReducer";
import AccountReducer from "./AccountReducer";
import BufferReducer from "./BufferReducer";
import CoolorsReducer from "./CoolorsReducer";
import ImagesReducer from "./ImagesReducer";
import PositionReducer from "./PositionReducer";
import RutaReducer from "./RutaReducer";
import DevicesReducer from "./DevicesReducer";

const RootReducer = combineReducers({
  login: LoginReducer,
  user: UserReducer,
  data: UnitReducer,
  driverData: DriverReducer,
  accountData: AccountReducer,
  BufferReducer: BufferReducer,
  CoolorsReducer: CoolorsReducer,
  ImagesReducer: ImagesReducer,
  PositionReducer: PositionReducer,
  RutaReducer: RutaReducer,
  DevicesReducer: DevicesReducer,
});

export default RootReducer;
