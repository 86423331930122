import React, { Component } from "react";
import Draggable from "react-draggable";
import "./MinimapWidget.scss";

import { ReactComponent as SignalIcon } from "../../../ListOfDevices/assets/signal/motion_icon-09.svg";
import { ReactComponent as SignalIconLow } from "../../../ListOfDevices/assets/signal/motion_icon-nivel-senal-baja.svg";
import { ReactComponent as SignalIconAvarage } from "../../../ListOfDevices/assets/signal/motion_icon-nivel-senal-media.svg";

function countdown(s) {
  var h = Math.floor(s / 3600);
  if (h < 10) {
    h = "0" + h;
  }

  s -= h * 3600;

  var m = Math.floor(s / 60);
  if (m < 10) {
    m = "0" + m;
  }

  s -= m * 60;

  const tmp = [];

  tmp.push(h + ":");

  tmp.push(m + ":");
  if (s < 10) {
    s = "0" + s;
  }

  tmp.push(s);

  return tmp.join("");
}

export default class MinimapWidget extends Component {
  constructor() {
    super();
    this.state = {
      urlStreetView: "",
      expanded: true,
      iframe: null,
      x: 1100,
      y: 0,
      center: [4.658883676657488, -74.06437579086325],
      zoom: 9,
    };
    this.setExpanded = this.setExpanded.bind(this);
    this.openExpanded = this.openExpanded.bind(this);
    this.closeExpanded = this.closeExpanded.bind(this);
  }
  componentDidMount() {
    const fenway = {
      lat: this.props.lat,
      lng: this.props.lng,
    };
    const panorama = new this.props.maps.StreetViewPanorama(
      document.getElementById("pano"),
      {
        position: fenway,
        pov: {
          heading: this.props.orientacion,
          pitch: 10,
        },
        addressControl: false,
        linksControl: false,
        panControl: false,
        enableCloseButton: false,
      }
    );
    this.props.map.setStreetView(panorama);
    this.setState({
      panorama: panorama,
    });
  }

  componentDidUpdate(prevProps) {
    const newPosition = new this.props.maps.LatLng({
      lat: this.props.lat,
      lng: this.props.lng,
      heading: this.props.orientacion,
    });
    if (prevProps.lat !== this.props.lat) {
      this.state.panorama.setPosition(newPosition);
      this.state.panorama.setPov({ heading: this.props.orientacion, pitch: 0 });
    }
  }
  //setter expanded
  setExpanded() {
    this.setState({
      expanded: !this.state.expanded,
    });
  }
  closeExpanded() {
    this.setState({
      expanded: false,
    });
  }
  openExpanded() {
    this.setState({
      expanded: true,
    });
  }

  levelOfSignal = () => {
    if (this.props.signal <= 0) {
      return (
        <>
          <SignalIcon
            fill="#E2E2E2"
            className="deviceIcons"
            alt="icon img"
            id={"signal" + this.props.id.toString()}
          />
        </>
      );
    } else if (this.props.signal < 50000) {
      return (
        <>
          <SignalIconLow
            className="deviceIcons"
            alt="icon img"
            id={"signal" + this.props.id.toString()}
          />
        </>
      );
    } else if (this.props.signal <= 100000) {
      return (
        <>
          <SignalIconAvarage
            className="deviceIcons"
            alt="icon img"
            id={"signal" + this.props.id.toString()}
          />
        </>
      );
    } else if (this.props.signal > 100000) {
      return (
        <>
          <SignalIcon
            fill="#01BEDB"
            className="deviceIcons"
            alt="icon img"
            id={"signal" + this.props.id.toString()}
          />
        </>
      );
    }
  };

  render() {
    let marker = this.props.mdata;
    let options = !this.state.expanded ? (
      <div className="mmOptions">
        <span>@Aqui las opciones y el action</span>
      </div>
    ) : (
      <div />
    );
    return (
      <>
        {marker ? (
          <Draggable
            defaultPosition={{
              x: document.getElementById("MiniMapsPanel").clientWidth / 2 - 150,
              y: 16,
            }}
            handle=".handle"
          >
            <div
              className="miniMapContainer"
              style={{ width: 300, height: 220 }}
              key={"minimap_" + this.props.id}
            >
              <div className="miniMapHeaderContainer handle">
                <div className="miniMapHeaderInfoContainer">
                  <div className="miniMapHeaderFont">{this.props.name}</div>
                  <div className="miniMapSubFont">Subtitle</div>
                </div>
                <div>
                  <div className="miniMapLastUpdate">
                    {"(" + countdown(7200) + ")"}
                  </div>
                  {this.levelOfSignal()}
                </div>
              </div>
              <div
                className="miniMapView"
                style={{ height: `calc(100% - 48px - 10%)` }}
              >
                <div style={{ width: "100%", height: `100%` }} id="pano"></div>
              </div>
              <div className="miniMapOptions">
                <img
                  className="miniMapOptionsIcon"
                  src="/assets/images/svg/motion_icon-28.svg"
                  onClick={this.setExpanded}
                  alt=""
                />
              </div>
              {options}
            </div>
          </Draggable>
        ) : null}
      </>
    );
  }
}
