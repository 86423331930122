import { setUserData } from "./UserActions";
import history from "history.js";
import Cookies from "js-cookie";
import localStorageService from "../../services/localStorageService";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const LOGOUT = "LOGOUT";

export function resetPassword({ email }) {
  return (dispatch) => {
    dispatch({ payload: email, type: RESET_PASSWORD });
  };
}

export function MotionLoginEmailPassword({ email, password }) {
  return (dispatch) => {
    var data = JSON.stringify({
      jsonrpc: "2.0",
      params: {
        login: email,
        password: password,
        db: "motion",
      },
    });
    console.log("se enviara la siguiente data por post: ");
    console.log(data);
    var config = {
      method: "POST",
      url: "/web/session/authenticate",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: data,
    };
    axios(config)
      .then((res) => {
        console.log(res.data);
        if (res.data.error) {
          console.log("Credenciales invalidas pendejo");
          localStorage.removeItem("auth_user");
          return dispatch({ type: LOGIN_ERROR, payload: "Login Failed" });
        } else {
          console.log(res.data.result);
          var user_server_data = res.data.result;

          let sessionId = user_server_data.session_id;
          console.log("session_id: " + sessionId);
          Cookies.set("aux_session", sessionId);
          console.log(document.cookie);
          // guardamos el usuario en las cookies test.
          let user = {
            userId: user_server_data.uid,
            role: "ADMIN",
            displayName: user_server_data.partner_display_name,
            email: user_server_data.username,
            photoURL: "/assets/images/face-7.jpg",
            age: 25,
            token: user_server_data.session_id,
            ...res,
          };
          // session id?
          window.location.replace("/");
          dispatch(setUserData(user));
          return dispatch({ type: LOGIN_SUCCESS });
        }
      })
      .catch(function (error) {
        console.log(error);
        history.push({
          pathname: "/session/login",
        });
        return dispatch({ type: LOGIN_ERROR, payload: error });
      });
  };
}
export function MotionCacheLogin() {
  return (dispatch) => {
    console.log("se intentara encontrar la informacion de usuario por el api");
    const cache = setupCache({
      maxAge: 15 * 60 * 1000,
    });

    // Create `axios` instance passing the newly created `cache.adapter`
    const api = axios.create({
      adapter: cache.adapter,
    });
    let user;
    // Send a GET request to some REST api
    api({
      url: "/api/uid",
      method: "GET",
    }).then(async (response) => {
      // Do something fantastic with response.data \o/
      user = {
        userId: response.data.userId,
        role: response.data.role,
        displayName: response.data.displayName,
        email: response.data.email,
        photoURL: response.data.photoURL,
        age: response.data.age,
        token: response.data.token,
      };
    });
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(user);
      }, 1000);
    })
      .then((data) => {
        // Login successful
        if (data) {
          // Save token in cache.
          localStorageService.setItem("auth_user", data);
          // session id?
          dispatch(setUserData(data));
          history.push({
            pathname: "/",
          });
          return dispatch({ type: LOGIN_SUCCESS });
        } else {
          console.error("no se encontro data en api uid :(");
          return dispatch({
            type: LOGIN_ERROR,
            payload: "no se encontro data en api uid :(",
          });
        }
      })
      .catch((error) => {
        console.error(error);
        history.push({
          pathname: "/session/login",
        });
        return dispatch({ type: LOGIN_ERROR, payload: error });
      });
  };
}

export const logout = () => (dispatch) => {
  localStorage.clear();

  dispatch({
    type: LOGOUT,
  });
};
