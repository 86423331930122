import React, { Component } from "react";

import "./ModalAlert.scss";

export default class ModalAlert extends Component {
  componentDidMount() {
    setTimeout(() => {
      this.props.fun();
      }, 2000);
  }
  render() {
    return (
      <div className="ModalAlert column">
        <div className="ModalAlert-content column">
          <div className="ModalAlert-ico">{this.props.icon}</div>
          <div className="ModalAlert-message">{this.props.message}</div>
        </div>
      </div>
    );
  }
}
