import {
  GET_POSITION_SUCCESS,
  DELETE_POSITION_SUCCESS,
} from "../types/PositionTypes";

const initialState = {
  CurrentPosition: null,
};

const PositionReducer = function (state = initialState, action) {
  switch (action.type) {
    //Create unit
    case GET_POSITION_SUCCESS:
      return { CurrentPosition: action.payload };
    case DELETE_POSITION_SUCCESS:
      return { CurrentPosition: null };
    default:
      return state;
  }
};

export default PositionReducer;
