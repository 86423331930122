import styled from 'styled-components';
import { easyMove } from './style-constants';

import ClusterIco from "./static/motion_icon-cluster.svg"


const MarkerGroup = styled.div`
  display: flex;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  animation: ${easyMove} 0.3s;
  background-color: transparent;
  background-image: url(${ClusterIco});
  background-repeat: no-repeat;
`;

export default MarkerGroup;
