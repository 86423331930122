import React, { Component } from "react";
import MinimapWidget from "./components/MinimapWidget/MinimapWidget";

class MiniMapsPane extends Component {
  constructor() {
    super();
    this.state = {
      lat: 4.658518806607504,
      lng: -74.063882230688,
      zoom: 2,
      clicked: 0,
      minimaps: [],
      x: 900,
      y: 500,
      value: 300,
    };
  }

  componentDidMount() {
    this.setState({
      value: this.state.value - 300 * this.props.markersToRenderMiniMap.lenght,
    });
  }

  render() {
    return (
      <div
        id="MiniMapsPanel"
        style={{ zIndex: this.props.showWidget ? 1 : -1 }}
        className={"mmBottom"}
      >
        {this.props.showWidget
          ? this.props.marker.map((device) => {
              return (
                <MinimapWidget
                  maps={this.props.maps}
                  map={this.props.map}
                  x={10}
                  y={10}
                  value={this.props.markersToRenderMiniMap.length - 1}
                  key={"minimap_" + device.id}
                  id={device.id}
                  lat={device.position.latitud}
                  lng={device.position.longitud}
                  orientacion={device.position.orientacion}
                  marca={device.params.brand}
                  modelo={device.params.model}
                  name={device.name}
                  city={device.position.city}
                  speed={device.position.velocidad}
                  lastUpdate={device.position.last_date_position}
                  signal={device.position.cnkb}
                  timeMoving="00:45:23"
                  timeStoped="00:02:21"
                  altitud={device.position.altitud}
                  voltage={device.position.battery_lvl}
                  hovered={false}
                  mdata={device}
                  closeFunction={this.props.closeFunction}
                />
              );
            })
          : null}
      </div>
    );
  }
}

export default MiniMapsPane;
