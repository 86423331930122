import React, { Component } from "react";
import "./AlertModal.scss";

export default class AlertModal extends Component {
  constructor() {
    super();
    this.state = {
      top: -50,
      y: 0,
    };
  }
  componentDidMount() {
    this.setState({
      y: 70,
    });
    setTimeout(() => {
      this.setState({
        y: 0,
      });
    }, 4000);
    setTimeout(() => {
      this.props.closeMiniMapErr();
    }, 4500);
  }
  render() {
    return (
      <div
        className="AlertModal row"
        style={{
          transform: `translateY(${this.state.y}px)`,
          top: this.state.top,
          left: `calc(${this.props.position}px - (300px / 2)`,
        }}
      >
        {this.props.ico ? <>{this.props.ico}</> : null}
        <div className="AlertModal-text">{this.props.message}</div>
      </div>
    );
  }
}
