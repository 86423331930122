export const UPDATE_UNIT = "UPDATE_UNIT";
export const UPDATE_UNIT_FAILED = "UPDATE_UNIT_FAILED";
export const UPDATE_UNIT_SUCCESS = "UPDATE_UNIT_SUCCESS";
export const UPDATE_UNIT_CLOSE = "UPDATE_UNIT_CLOSE";

export const ADD_UNIT_TO_MOTION = "ADD_UNIT_TO_MOTION";
export const ADD_UNIT_TO_MOTION_SUCCESS = "ADD_UNIT_TO_MOTION_SUCCESS";
export const ADD_UNIT_TO_MOTION_FAILED = "ADD_UNIT_TO_MOTION_FAILED";
export const ADD_UNIT_TO_MOTION_CLOSE = "ADD_UNIT_TO_MOTION_CLOSE";

export const SAVE_UNIT_LIST_BUFFER = "SAVE_UNIT_LIST_BUFFER";
export const SAVE_UNIT_LIST_BUFFER_FAILED = "SAVE_UNIT_LIST_BUFFER_FAILED";

export const GET_UNIT_LIST = "GET_UNIT";
export const GET_UNIT_LIST_SUCCESS = "GET_UNIT_LIST_SUCCESS";
export const GET_UNIT_LIST_FAILED = "GET_UNIT_LIST_FAILED";
export const GET_UNIT_LIST_UPDATE = "GET_UNIT_LIST_UPDATE";

export const ADD_UNIT_GROUP_TO_MOTION = "ADD_UNIT_GROUP_TO_MOTION";
export const ADD_UNIT_GROUP_TO_MOTION_SUCCESS =
  "ADD_UNIT_GROUP_TO_MOTION_SUCCESS";
