import {
  SET_USER_DATA,
  REMOVE_USER_DATA,
  USER_LOGGED_OUT,
  ADD_USER_TO_MOTION,
  ADD_USER_TO_MOTION_SUCCESS,
  DATA_FAILED,
} from "../actions/UserActions";

const initialState = {};

const userReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_USER_DATA: {
      return {
        ...state,
        ...action.data,
      };
    }
    case REMOVE_USER_DATA: {
      return {
        ...state,
      };
    }
    case USER_LOGGED_OUT: {
      return state;
    }
    case ADD_USER_TO_MOTION:
      return {
        ...state,
        isLoading: true,
        errMess: null,
      };
    case ADD_USER_TO_MOTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errMess: null,
      };
    case DATA_FAILED:
      return { ...state, isLoading: false, errMess: action.payload };
    default:
      return state;
  }
};

export default userReducer;
