import React, { Component } from "react";
import Accounts from "./form/Accounts";
import Drivers from "./form/Drivers";
import Users from "./form/Users";

class HiddeNav extends Component {
  constructor(props) {
    super();
    this.state = {};
  }

  render() {
    return (
      <>
        {this.props.hiddeNav ? (
          <div className="hiddenNav">
            <Drivers />
            <Accounts />
            <Users />
          </div>
        ) : (
          <div className="hiddenNav close"></div>
        )}
      </>
    );
  }
}

export default HiddeNav;
