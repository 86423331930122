import axios from "axios";
import qs from "qs";

import {
  //UPDATE
  UPDATE_UNIT,
  UPDATE_UNIT_CLOSE,
  UPDATE_UNIT_FAILED,
  UPDATE_UNIT_SUCCESS,
  //CREATE
  ADD_UNIT_TO_MOTION,
  ADD_UNIT_TO_MOTION_CLOSE,
  ADD_UNIT_TO_MOTION_FAILED,
  ADD_UNIT_TO_MOTION_SUCCESS,
  //GROUP
  ADD_UNIT_GROUP_TO_MOTION,
  ADD_UNIT_GROUP_TO_MOTION_SUCCESS,
} from "../types/UnitsTypes";

//CREATE UNIT
export function createUnit(data) {
  return (dispatch) => {
    dispatch({ type: ADD_UNIT_TO_MOTION, payload: "loading" });
    let unit = JSON.stringify({
      jsonrpc: "2.0",
      params: {
        name: data.name,
        registration_plate: data.placa,
        year: data.modelo,
        model: data.clase,
        color: data.color,
        brand: data.marca,
        vin: data.vin,
        imei: data.imei,
        unique_id2: "",
        ph: data.phone,
        ph2: "",
        device_type: data.device,
        img_type: data.img_type,
      },
    });
    var config = {
      method: "POST",
      url: "/api/tracking/units/new",
      headers: {
        "Content-Type": "application/json",
      },
      data: unit,
    };

    axios(config)
      .then(
        (response) => {
          if (response.status === 200) {
            dispatch({
              type: ADD_UNIT_TO_MOTION_SUCCESS,
              payload: response.request.response,
            });
            return response;
          } else {
            var error = new Error("oops, something went wrong");
            dispatch({ type: ADD_UNIT_TO_MOTION_FAILED, payload: error });
            error.response = response;
            throw error;
          }
        },
        (error) => {
          var errmess = new Error(error);
          dispatch({ type: ADD_UNIT_TO_MOTION_FAILED, payload: errmess });
          throw errmess;
        }
      )
      .catch((error) =>
        dispatch({ type: ADD_UNIT_TO_MOTION_FAILED, payload: error })
      );
  };
}

//CLOSE MODAL CREATE UNIT
export function CloseCreateUnit() {
  return (dispatch) => {
    dispatch({ type: ADD_UNIT_TO_MOTION_CLOSE, payload: "CERRADO" });
  };
}

//UPDATE UNIT
export function UpdateUnit(data) {
  return (dispatch) => {
    dispatch({ type: UPDATE_UNIT, payload: "CARGANDO" });
    let unit = JSON.stringify({
      jsonrpc: "2.0",
      params: {
        name: data.name,
        registration_plate: data.placa,
        year: data.modelo,
        model: data.clase,
        color: data.color,
        brand: data.marca,
        vin: data.vin,
        imei: data.imei,
        ph: data.phone,
        img_type: data.img_type,
      },
    });
    var config = {
      method: "POST",
      url: "/api/tracking/units/update",
      headers: {
        "Content-Type": "application/json",
      },
      data: unit,
    };

    axios(config)
      .then(
        (response) => {
          if (response.status === 200) {
            dispatch({
              type: UPDATE_UNIT_SUCCESS,
              payload: response.request.response,
            });
            return response;
          } else {
            var error = new Error("oops, something went wrong");
            console.log(error);
            dispatch({ type: UPDATE_UNIT_FAILED, payload: error });
            error.response = response;
            throw error;
          }
        },
        (error) => {
          console.log(error);
          var errmess = new Error(error);
          dispatch({ type: UPDATE_UNIT_FAILED, payload: error });
          throw errmess;
        }
      )
      .catch((error) =>
        dispatch({ type: UPDATE_UNIT_FAILED, payload: error.message })
      );
  };
}

//CLOSE MODAL CREATE UNIT
export function CloseUpdateUnit() {
  return (dispatch) => {
    dispatch({ type: UPDATE_UNIT_CLOSE, payload: "CERRADO" });
  };
}

//CREATE GROUP UNITS
export function createUnitGroup({ nameGroup, UnitsIds }) {
  return (dispatch) => {
    let data = qs.stringify({
      name: nameGroup,
      unit_ids: `${UnitsIds}`,
    });
    console.log(data);
    var config = {
      method: "POST",
      url: "/api/tracking/unit_groups",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios(config)
      .then(
        (response) => {
          if (response.status === 200) {
            console.log(response);
            dispatch(createUnitGroupSuccess(response.result));
            return response;
          } else {
            var error = new Error("oops, something went wrong");
            console.log(error);
            error.response = response;
            throw error;
          }
        },
        (error) => {
          console.log(error);
          var errmess = new Error(error);
          throw errmess;
        }
      )
      .catch((error) => {});
  };
}

export function createUnitGroupPost(payload) {
  return { type: ADD_UNIT_GROUP_TO_MOTION, payload };
}

export function createUnitGroupSuccess(payload) {
  const byId = { [payload.result]: payload };
  return { type: ADD_UNIT_GROUP_TO_MOTION_SUCCESS, payload: { byId } };
}
