import axios from "axios";

import {
  //Get list
  GET_UNIT_LIST,
  GET_UNIT_LIST_SUCCESS,
  GET_UNIT_LIST_FAILED,
  //Save list
  SAVE_UNIT_LIST_BUFFER,
  SAVE_UNIT_LIST_BUFFER_FAILED,
} from "../types/UnitsTypes";

function deleteAllCookies() {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

export const listUnitsBuffer = () => async (dispatch) => {
  dispatch({
    type: GET_UNIT_LIST,
  });
  try {
    const { data } = await axios.get(`/api/tracking/units`);
    dispatch({ type: GET_UNIT_LIST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: GET_UNIT_LIST_FAILED, payload: error.message });
  }
};

export const updateListUnitsBuffer = () => async (dispatch) => {
  try {
    const { data } = await axios.get(`/api/tracking/units`);
    dispatch({ type: SAVE_UNIT_LIST_BUFFER, payload: data.result });
  } catch (error) {
    localStorage.clear();
    deleteAllCookies();
    dispatch({ type: SAVE_UNIT_LIST_BUFFER_FAILED, payload: error.message });
  }
};
