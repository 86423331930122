import React, { Component, Fragment } from "react";
import Main from "./motion/components/MainComponent";
import LeftNav from "./motion/components/LeftNav/LeftNav";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import HiddeNav from "./motion/components/HiddeNav/HiddeNav";

const mapStateToProps = (state) => {
  return {
    user: state.user,
    data: state.data,
    value: state.value,
  };
};

class Dashboard1 extends Component {
  constructor() {
    super();
    this.state = {
      openPanel: false,
      showModal: false,
      auth: JSON.parse(localStorage.getItem("auth_user")),
      hiddeNav: false,
      /*LeftNav view*/
      viewUnitModal: true,
      viewListOfDevice: true,
    };
    this.toggleList = this.toggleList.bind(this);
    this.setOpenPanel = this.setOpenPanel.bind(this);
    this.toggleHiddeNav = this.toggleHiddeNav.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  setOpenPanel(open) {
    this.setState({ openPanel: open });
  }

  toggleList() {
    this.setState({
      viewListOfDevice: !this.state.viewListOfDevice,
    });
  }

  toggleModal() {
    this.setState({
      showModal: !this.state.showModal,
    });
  }

  componentDidMount() {
    if (this.state.auth === null) {
      console.log("Se encontro auth in state: ");
      console.log(this.state.auth);
      window.location.replace("/session/login");
    }
  }

  componentDidUpdate() {
    if (this.state.auth === null) {
      console.log("Se encontro auth in state: ");
      console.log(this.state.auth);
      window.location.replace("/session/login");
    }
  }

  toggleHiddeNav() {
    this.setState({
      hiddeNav: !this.state.hiddeNav,
    });
  }

  render() {
    return (
      <Fragment>
        <div className="Connect(Layout1)-layout-14 sidenav-compact layout1 theme-light flex">
          <LeftNav
            showModal={this.state.showModal}
            toggleModal={this.toggleList}
            auth={this.state.auth}
            toggleHiddeNav={this.toggleHiddeNav}
            viewListOfDevice={this.state.viewListOfDevice}
          />
          <HiddeNav hiddeNav={this.state.hiddeNav} />
          <div className="content-wrap position-relative">
            <div className="content">
              <Main
                openPanel={this.state.openPanel}
                setOpenPanel={this.setOpenPanel}
                showModal={this.state.showModal}
                toggleModal={this.toggleModal}
                viewListOfDevice={this.state.viewListOfDevice}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(connect(mapStateToProps)(Dashboard1));
