import React, { Component } from "react";
import { connect } from "react-redux";
import { selectRutaMarker } from "../../../../../../../redux/actions/RutaAction";

const mapStateToProps = (state) => {
  return {
    buffer: state.BufferReducer.buffer,
    RutaReducer: state.RutaReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  selectRutaMarker: (id) => {
    dispatch(selectRutaMarker(id));
  },
});

class DeviceOptions extends Component {
  constructor(props) {
    super();
    this.state = {};
    this.marker = this.marker.bind(this);
    this.rutaModal = this.rutaModal.bind(this);
  }
  marker() {
    this.props.onMarkerDbClick(this.props.id);
    this.props.selectWidget(this.props.id);
    this.props.toggleVisible(this.props.id);
    this.props.handleSetCenter();
    this.props.toggleFocusMarkerNull();
  }
  rutaModal() {
    this.props.selectRutaMarker(this.props.id);
  }
  render() {
    // funcion que retorna el estado del dispositivo.
    let id_select = parseInt(this.props.id);
    let exist = this.props.miniMaps.filter((unit) => {
      return unit.id === id_select;
    });
    return (
      <div className="opt_list">
        <ul className="cardOptions">
          <li className="listHeader">Opciones</li>
          <li className="optionsItem" onClick={this.marker}>
            Seguir
          </li>
          <li
            className="optionsItem"
            onClick={() => this.props.appendToMiniMaps(this.props.id)}
            style={{ color: exist.length === 1 ? "#00249c" : "" }}
          >
            MiniMapa
          </li>
          <li className="optionsItem" onClick={this.rutaModal}>
            Ruta
          </li>
          <li
            className="optionsItem"
            onClick={() => this.props.toggleModalUpdateUnit(this.props.id)}
          >
            Editar
          </li>
          <li className="optionsItem">Recorrido</li>
          <li className="optionsItem">Informe</li>
          <li className="optionsItem">Mensajes</li>
          <li className="optionsItem">Comando</li>
          <li className="optionsItem">Propiedad</li>
          <li className="optionsItem">Compartir</li>
        </ul>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DeviceOptions);
