import React, { Component } from "react";

export default class Widget extends Component {
  constructor(props) {
    super();
    this.state = {
      marker: [],
      delay: 40,
    };
    this.animatedWidget = this.animatedWidget.bind(this);
  }

  componentDidMount() {
    let marker = this.props.marker[0];
    let speed = marker.position.velocidad;
    let VelocidadTotal = 120 - speed;
    this.setState({
      velocidadInicial: this.props.marker[0].position.velocidad,
      velocimetro: VelocidadTotal,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.marker[0].position.velocidad !==
      this.props.marker[0].position.velocidad
    ) {
      this.animatedWidget(prevProps.marker[0].position.velocidad);
      this.setState({
        velocidadInicial: prevProps.marker[0].position.velocidad,
        velocidadFinal: this.props.marker[0].position.velocidad,
      });
    }
  }

  animatedWidget(prevVelocidad) {
    if (this.state.velocidadInicial < this.props.marker[0].position.velocidad) {
      this.intervalSpeed = setTimeout(() => {
        let speed = this.state.velocidadInicial;
        let VelocidadTotal = 120 - (speed + 1);
        this.setState({
          velocidadInicial: this.state.velocidadInicial + 1,
          prevVelocimetro: VelocidadTotal,
        });
        if (
          this.state.velocidadInicial < this.props.marker[0].position.velocidad
        ) {
          this.animatedWidget();
        }
      }, this.state.delay);
    } else if (
      this.state.velocidadInicial > this.props.marker[0].position.velocidad
    ) {
      this.intervalSpeed = setTimeout(() => {
        let speed = this.state.velocidadInicial;
        let VelocidadTotal = 120 - (speed - 1);
        this.setState({
          velocidadInicial: this.state.velocidadInicial - 1,
          prevVelocimetro: VelocidadTotal,
        });
        if (
          this.state.velocidadInicial > this.props.marker[0].position.velocidad
        ) {
          this.animatedWidget();
        }
      }, this.state.delay);
    }
  }

  render() {
    return (
      <div className="widget">
        <svg
          version="1.1"
          id="Capa_1"
          x="0px"
          y="0px"
          viewBox="0 0 510 175"
          style={{ enableBackground: "new 0 0 510 175" }}
        >
          <filter id="dropshadow" height="130%">
            <feGaussianBlur in="SourceAlpha" stdDeviation="3" />

            <feOffset dx="0" dy="-3" result="offsetblur" />

            <feComponentTransfer>
              <feFuncA type="linear" slope="0.15" />
            </feComponentTransfer>
            <feMerge>
              <feMergeNode />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
          <g>
            <g>
              <path
                style={{ filter: "url(#dropshadow)" }}
                className="st0"
                d="M500,145c0,10.2-0.6,20.2-1.8,30H11.8c-1.3-10.3-1.9-20.8-1.8-31.5C10.2,119,13.9,95.3,20.8,73
			c8.5-27.5,40.5-39.9,65.4-25.5l169,97.4l168.6-97.3c25.2-14.5,57.2-1.7,65.6,26.1C496.3,96.2,500,120.2,500,145z"
              />
            </g>
          </g>
          <g>
            <g>
              <filter id="dropshadow2" height="130%">
                <feGaussianBlur in="SourceAlpha" stdDeviation="3" />

                <feOffset dx="0" dy="-3" result="offsetblur" />

                <feComponentTransfer>
                  <feFuncA type="linear" slope="0.15" />
                </feComponentTransfer>
                <feMerge>
                  <feMergeNode />
                  <feMergeNode in="SourceGraphic" />
                </feMerge>
              </filter>
              <path
                style={{ filter: "url(#dropshadow2)" }}
                className="st0"
                d="M390,145c0,10.3-1.2,20.3-3.3,30H123.3c-2.2-9.7-3.3-19.7-3.3-30c0-74.6,60.4-135,135-135S390,70.4,390,145z"
              />
            </g>
          </g>
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset={`0%`} stopColor="#01BEDB"></stop>
              <stop offset={`75%`} stopColor="#C6007E"></stop>
            </linearGradient>
          </defs>
          <path
            fill="none"
            stroke="url(#gradient)"
            id="speedWidget"
            strokeWidth="20"
            strokeMiterlimit="10"
            className="st1 velocimetro"
            stroke-dashoffset={
              this.state.prevVelocimetro
                ? -this.state.prevVelocimetro
                : -this.state.velocimetro
            }
            strokeDasharray="120"
            pathLength="120"
            d="M380,145L380,145c0-69-56-125-125-125S130,76,130,145l0,0"
          />
          <text
            transform="matrix(1 0 0 1 30.4672 118.2871)"
            className="st2 st3 st4"
          >
            {this.props.marker[0].position.fuel_lvl}% gal
          </text>
          <text
            transform="matrix(1 0 0 1 451.2968 118.2871)"
            className="st2 st3 st4"
          >
            29ºC
          </text>
          <path
            className="st5"
            d="M55,103.8v1.2h-4.8H40v-1.2V91.5c0-0.8,0.7-1.5,1.5-1.5h7.2c0.8,0,1.5,0.7,1.5,1.5v6.6l0,0
	c0,0.3-0.1,0.6-0.4,0.8c-0.2,0.2-0.5,0.4-0.8,0.4c-0.2,0-0.4-0.1-0.6-0.2v-7.3h-6.6v12h6.6v-0.9c0.2,0,0.4,0,0.6,0
	c0.4,0,0.8-0.1,1.2-0.2v1.1H55z M52.6,94.5c0-0.6-0.2-1.2-0.7-1.7s-1.1-0.7-1.7-0.7l0,0v1.2l0,0c0.3,0,0.6,0.1,0.8,0.4
	c0.2,0.2,0.4,0.5,0.4,0.8v3.6c0,0.6-0.2,1.2-0.7,1.7s-1.1,0.7-1.7,0.7l0,0c-1,0-1.9-0.6-2.2-1.5h0.1v-1.8h-1.8V99h0.4
	c0.4,1.6,1.8,2.7,3.5,2.7l0,0c1,0,1.9-0.4,2.5-1.1c0.7-0.7,1.1-1.6,1.1-2.5L52.6,94.5z M46.6,95.4v-1.8h-3v1.8H46.6z"
          />
          <path
            className="st6"
            d="M467.3,100.2c0-1.4-0.6-2.6-1.5-3.5v-3.4c0-1.8-1.5-3.3-3.3-3.3s-3.3,1.5-3.3,3.3v3.4c-0.9,0.9-1.5,2.1-1.5,3.5
	c0,2.7,2.1,4.8,4.8,4.8c0.1,0,0.1,0,0.2,0C465.2,104.9,467.3,102.8,467.3,100.2z M462.6,103.8h-0.1h-0.1c-1.4,0-2.7-0.9-3.2-2.2
	c-0.2-0.4-0.3-0.9-0.3-1.4s0.1-1,0.3-1.4c0.3-0.6,0.7-1.1,1.2-1.5v-4c0-1.2,0.9-2.1,2.1-2.1s2.1,0.9,2.1,2.1v4
	c0.5,0.4,0.9,0.9,1.2,1.5c0.2,0.4,0.3,0.9,0.3,1.4s-0.1,1-0.3,1.4C465.3,102.9,464,103.8,462.6,103.8z M464.3,100.2
	c0,1-0.8,1.8-1.8,1.8s-1.8-0.8-1.8-1.8c0-0.7,0.4-1.2,0.9-1.6v-3.8h1.8v3.8C463.9,99,464.3,99.5,464.3,100.2z"
          />
          <g>
            <g>
              <filter id="shadowClose" height="130%">
                <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
                <feOffset dx="0" dy="0" result="offsetblur" />
                <feComponentTransfer>
                  <feFuncA type="linear" slope="1" />
                </feComponentTransfer>
                <feMerge>
                  <feMergeNode />
                  <feMergeNode in="SourceGraphic" />
                </feMerge>
              </filter>
              <circle
                id="closeWidget"
                style={{ cursor: "pointer", filter: "url(#shadowClose)" }}
                className="st6"
                cx="385"
                cy="30"
                r="20"
                onClick={this.props.closeWidget}
              />
            </g>
          </g>
          <g>
            <g>
              <filter id="shadowState" height="130%">
                <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
                <feOffset dx="0" dy="0" result="offsetblur" />
                <feComponentTransfer>
                  <feFuncA type="linear" slope="0.5" />
                </feComponentTransfer>
                <feMerge>
                  <feMergeNode />
                  <feMergeNode in="SourceGraphic" />
                </feMerge>
              </filter>
              <circle
                style={{ cursor: "pointer", filter: "url(#shadowState)" }}
                className="st0"
                cx="125"
                cy="30"
                r="20"
              />
            </g>
          </g>
          <g>
            <path
              className="st7"
              d="M445.9,149c-0.6,0-1.1-0.1-1.6-0.3s-0.9-0.5-1.2-0.8c-0.3-0.4-0.6-0.8-0.8-1.4c-0.2-0.5-0.3-1.2-0.3-1.9
		s0.1-1.3,0.3-1.9s0.5-1,0.9-1.4s0.8-0.6,1.3-0.8s1-0.3,1.5-0.3c0.3,0,0.7,0,0.9,0.1s0.5,0.1,0.7,0.2s0.4,0.1,0.5,0.2
		c0.1,0.1,0.2,0.1,0.3,0.1l-0.3,1c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.3-0.1-0.5-0.2c-0.2-0.1-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1
		c-0.4,0-0.8,0.1-1.2,0.2c-0.4,0.2-0.7,0.4-0.9,0.7c-0.2,0.3-0.4,0.6-0.6,1.1s-0.2,0.9-0.2,1.4s0.1,0.9,0.2,1.4
		c0.1,0.4,0.3,0.8,0.5,1c0.2,0.3,0.5,0.5,0.9,0.7c0.3,0.2,0.8,0.2,1.2,0.2c0.5,0,1-0.1,1.3-0.2c0.4-0.1,0.6-0.2,0.8-0.3l0.3,1
		c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.3,0.1-0.5,0.2c-0.2,0.1-0.5,0.1-0.8,0.1C446.5,149,446.2,149,445.9,149z"
            />
          </g>
          <g>
            <path
              className="st7"
              d="M436,75.6h1.9v8.3H436v-3.5h-3.1v3.5H431v-8.3h1.9v3.2h3.1V75.6z"
            />
          </g>
          <g>
            <path
              className="st7"
              d="M62,148.8v-8.3h5.1v1h-3.9v2.5h3.5v1h-3.5v2.9h4.2v1L62,148.8L62,148.8z"
            />
          </g>
          <g>
            <path
              className="st7"
              d="M73.1,83.9v-8.3h5.6v1.6H75v1.7h3.3v1.6H75v3.4C75,83.9,73.1,83.9,73.1,83.9z"
            />
          </g>
          <g>
            <path
              className="st8"
              d="M162.2,144.7c0-1.4,0.2-2.5,0.7-3.2c0.5-0.8,1.2-1.1,2-1.1s1.6,0.4,2,1.1c0.5,0.8,0.7,1.8,0.7,3.2
		s-0.2,2.5-0.7,3.2c-0.5,0.8-1.2,1.1-2,1.1s-1.6-0.4-2-1.1C162.5,147.1,162.2,146.1,162.2,144.7z M166.6,144.7c0-0.5,0-0.9-0.1-1.3
		c-0.1-0.4-0.1-0.8-0.3-1.1c-0.1-0.3-0.3-0.5-0.5-0.7s-0.5-0.3-0.8-0.3s-0.6,0.1-0.8,0.3s-0.4,0.4-0.5,0.7s-0.2,0.7-0.3,1.1
		c-0.1,0.4-0.1,0.8-0.1,1.3s0,0.9,0.1,1.3c0.1,0.4,0.1,0.8,0.3,1.1c0.1,0.3,0.3,0.5,0.5,0.7s0.5,0.3,0.8,0.3s0.6-0.1,0.8-0.3
		s0.4-0.4,0.5-0.7s0.2-0.7,0.3-1.1C166.6,145.6,166.6,145.1,166.6,144.7z"
            />
          </g>
          <path
            className="st9"
            d="M82.5,145c0-20.7,3.7-40.6,10.4-59"
            stroke-dashoffset={50}
            strokeDasharray="100"
            pathLength="100"
          />
          <path
            className="st10"
            d="M417.1,86c6.7,18.4,10.4,38.3,10.4,59"
            stroke-dashoffset={-50}
            strokeDasharray="100"
            pathLength="100"
          />
          <g>
            <path
              className="st8"
              d="M176,98.3c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.3-0.3,0.5-0.5,0.8s-0.4,0.5-0.6,0.8c-0.2,0.3-0.5,0.5-0.7,0.7
		c-0.1,0.1-0.3,0.3-0.5,0.5s-0.4,0.4-0.5,0.6c-0.2,0.2-0.3,0.4-0.4,0.6c-0.1,0.2-0.2,0.3-0.2,0.5h3.8v0.9h-5v-0.1v-0.1
		c0-0.4,0.1-0.7,0.2-1c0.1-0.3,0.3-0.6,0.5-0.9s0.4-0.6,0.7-0.8c0.2-0.3,0.5-0.5,0.7-0.7s0.4-0.4,0.6-0.6c0.2-0.2,0.3-0.4,0.5-0.6
		c0.1-0.2,0.3-0.4,0.3-0.6c0.1-0.2,0.1-0.4,0.1-0.6s0-0.4-0.1-0.6s-0.2-0.3-0.3-0.4s-0.3-0.2-0.5-0.2c-0.2-0.1-0.4-0.1-0.5-0.1
		c-0.2,0-0.4,0-0.6,0.1c-0.2,0.1-0.4,0.1-0.5,0.2c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.2l-0.6-0.8
		c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0.4-0.2,0.7-0.3c0.2-0.1,0.5-0.1,0.8-0.1c0.9,0,1.5,0.2,2,0.6
		C175.8,97,176,97.6,176,98.3z"
            />
            <path
              className="st8"
              d="M177.8,100.3c0-1.4,0.2-2.5,0.7-3.2c0.5-0.8,1.2-1.1,2-1.1s1.6,0.4,2,1.1c0.5,0.8,0.7,1.8,0.7,3.2
		c0,1.4-0.2,2.5-0.7,3.2c-0.5,0.8-1.2,1.1-2,1.1s-1.6-0.4-2-1.1C178,102.8,177.8,101.7,177.8,100.3z M182.1,100.3
		c0-0.5,0-0.9-0.1-1.3s-0.1-0.8-0.3-1.1c-0.1-0.3-0.3-0.5-0.5-0.7s-0.5-0.3-0.8-0.3s-0.6,0.1-0.8,0.3c-0.2,0.2-0.4,0.4-0.5,0.7
		c-0.1,0.3-0.2,0.7-0.3,1.1s-0.1,0.8-0.1,1.3s0,0.9,0.1,1.3s0.1,0.8,0.3,1.1c0.1,0.3,0.3,0.5,0.5,0.7s0.5,0.3,0.8,0.3
		s0.6-0.1,0.8-0.3s0.4-0.4,0.5-0.7c0.1-0.3,0.2-0.7,0.3-1.1C182.1,101.2,182.1,100.8,182.1,100.3z"
            />
          </g>
          <g>
            <path
              className="st8"
              d="M203.6,68.4c0.2-0.4,0.4-0.8,0.6-1.2c0.3-0.5,0.6-1,0.9-1.4s0.7-1,1.1-1.5c0.4-0.5,0.8-0.9,1.1-1.3h1.1v5.3h1
		v0.9h-1v2.1h-1.1v-2.1h-3.7L203.6,68.4L203.6,68.4z M207.3,64.3c-0.2,0.3-0.5,0.5-0.7,0.9c-0.2,0.3-0.5,0.6-0.7,1s-0.4,0.7-0.7,1.1
		s-0.4,0.7-0.5,1.1h2.6L207.3,64.3L207.3,64.3z"
            />
            <path
              className="st8"
              d="M210.5,67.1c0-1.4,0.2-2.5,0.7-3.2c0.5-0.8,1.2-1.1,2-1.1s1.6,0.4,2,1.1c0.5,0.8,0.7,1.8,0.7,3.2
		s-0.2,2.5-0.7,3.2c-0.5,0.8-1.2,1.1-2,1.1s-1.6-0.4-2-1.1S210.5,68.5,210.5,67.1z M214.9,67.1c0-0.5,0-0.9-0.1-1.3
		s-0.1-0.8-0.3-1.1c-0.1-0.3-0.3-0.5-0.5-0.7c-0.2-0.2-0.5-0.3-0.8-0.3s-0.6,0.1-0.8,0.3c-0.2,0.2-0.4,0.4-0.5,0.7s-0.2,0.7-0.3,1.1
		s-0.1,0.8-0.1,1.3s0,0.9,0.1,1.3s0.1,0.8,0.3,1.1c0.1,0.3,0.3,0.5,0.5,0.7s0.5,0.3,0.8,0.3s0.6-0.1,0.8-0.3
		c0.2-0.2,0.4-0.4,0.5-0.7s0.2-0.7,0.3-1.1S214.9,67.6,214.9,67.1z"
            />
          </g>
          <g>
            <path
              className="st8"
              d="M249.1,55.7c0-0.8,0.1-1.5,0.3-2.1s0.5-1.1,1-1.6s0.9-0.7,1.5-1s1.3-0.3,2-0.3l0.1,0.9c-0.5,0-0.9,0.1-1.3,0.2
		c-0.4,0.1-0.7,0.2-1,0.5c-0.3,0.2-0.6,0.5-0.8,0.8s-0.4,0.7-0.5,1.2c0.2-0.1,0.4-0.2,0.7-0.2c0.3-0.1,0.5-0.1,0.8-0.1
		c0.5,0,0.9,0.1,1.2,0.2c0.3,0.1,0.6,0.3,0.8,0.6c0.2,0.2,0.4,0.5,0.5,0.8s0.1,0.6,0.1,1c0,0.3-0.1,0.6-0.2,0.9s-0.3,0.6-0.5,0.9
		s-0.5,0.5-0.8,0.6c-0.3,0.2-0.7,0.3-1.2,0.3c-0.9,0-1.6-0.3-2.1-0.9C249.3,57.6,249.1,56.8,249.1,55.7z M251.8,54.8
		c-0.3,0-0.5,0-0.8,0.1c-0.2,0.1-0.5,0.1-0.7,0.2c0,0.1,0,0.2,0,0.3s0,0.2,0,0.3c0,0.4,0,0.7,0.1,1c0,0.3,0.1,0.6,0.3,0.8
		s0.3,0.4,0.5,0.6c0.2,0.1,0.5,0.2,0.8,0.2s0.5-0.1,0.7-0.2c0.2-0.1,0.3-0.3,0.5-0.4s0.2-0.4,0.3-0.6c0.1-0.2,0.1-0.4,0.1-0.6
		c0-0.5-0.1-1-0.4-1.2C252.7,55,252.3,54.8,251.8,54.8z"
            />
            <path
              className="st8"
              d="M255.7,54.8c0-1.4,0.2-2.5,0.7-3.2c0.5-0.8,1.2-1.1,2-1.1s1.6,0.4,2,1.1c0.5,0.8,0.7,1.8,0.7,3.2
		s-0.2,2.5-0.7,3.2c-0.5,0.8-1.2,1.1-2,1.1s-1.6-0.4-2-1.1C255.9,57.3,255.7,56.2,255.7,54.8z M260.1,54.8c0-0.5,0-0.9-0.1-1.3
		c-0.1-0.4-0.1-0.8-0.3-1.1c-0.1-0.3-0.3-0.5-0.5-0.7s-0.5-0.3-0.8-0.3s-0.6,0.1-0.8,0.3c-0.2,0.2-0.4,0.4-0.5,0.7s-0.2,0.7-0.3,1.1
		c-0.1,0.4-0.1,0.8-0.1,1.3s0,0.9,0.1,1.3c0.1,0.4,0.1,0.8,0.3,1.1c0.1,0.3,0.3,0.5,0.5,0.7s0.5,0.3,0.8,0.3s0.6-0.1,0.8-0.3
		c0.2-0.2,0.4-0.4,0.5-0.7s0.2-0.7,0.3-1.1C260,55.7,260.1,55.3,260.1,54.8z"
            />
          </g>
          <g>
            <path
              className="st8"
              d="M335.5,142.4c0.5-0.2,0.9-0.4,1.3-0.7s0.8-0.6,1.2-1h0.8v8.3h-1.1v-6.8c-0.1,0.1-0.2,0.2-0.4,0.3
		c-0.1,0.1-0.3,0.2-0.5,0.3s-0.3,0.2-0.5,0.3s-0.4,0.1-0.5,0.2L335.5,142.4z"
            />
            <path
              className="st8"
              d="M346.8,142.8c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.3-0.3,0.5-0.5,0.8s-0.4,0.5-0.6,0.8s-0.5,0.5-0.7,0.7
		c-0.1,0.1-0.3,0.3-0.5,0.5s-0.4,0.4-0.5,0.6c-0.2,0.2-0.3,0.4-0.4,0.6c-0.1,0.2-0.2,0.3-0.2,0.5h3.8v0.9h-5v-0.1v-0.1
		c0-0.4,0.1-0.7,0.2-1.1c0.1-0.3,0.3-0.6,0.5-0.9c0.2-0.3,0.4-0.6,0.7-0.8s0.5-0.5,0.7-0.7s0.4-0.4,0.6-0.6c0.2-0.2,0.3-0.4,0.5-0.6
		c0.1-0.2,0.3-0.4,0.3-0.6s0.1-0.4,0.1-0.6c0-0.2,0-0.4-0.1-0.6s-0.2-0.3-0.3-0.4s-0.3-0.2-0.5-0.2s-0.4-0.1-0.5-0.1
		c-0.2,0-0.4,0-0.6,0.1s-0.4,0.1-0.5,0.2s-0.3,0.2-0.4,0.3s-0.2,0.2-0.2,0.2l-0.6-0.8c0.1-0.1,0.2-0.2,0.3-0.3
		c0.1-0.1,0.3-0.2,0.5-0.3s0.4-0.2,0.7-0.3c0.2-0.1,0.5-0.1,0.8-0.1c0.9,0,1.5,0.2,2,0.6C346.5,141.5,346.8,142.1,346.8,142.8z"
            />
            <path
              className="st8"
              d="M348.5,144.8c0-1.4,0.2-2.5,0.7-3.2s1.2-1.1,2-1.1s1.6,0.4,2,1.1s0.7,1.8,0.7,3.2s-0.2,2.5-0.7,3.2
		c-0.5,0.8-1.2,1.1-2,1.1s-1.6-0.4-2-1.1C348.8,147.3,348.5,146.2,348.5,144.8z M352.9,144.8c0-0.5,0-0.9-0.1-1.3
		c-0.1-0.4-0.1-0.8-0.3-1.1c-0.1-0.3-0.3-0.5-0.5-0.7s-0.5-0.3-0.8-0.3s-0.6,0.1-0.8,0.3c-0.2,0.2-0.4,0.4-0.5,0.7s-0.2,0.7-0.3,1.1
		s-0.1,0.8-0.1,1.3s0,0.9,0.1,1.3c0.1,0.4,0.1,0.8,0.3,1.1c0.1,0.3,0.3,0.5,0.5,0.7s0.5,0.3,0.8,0.3s0.6-0.1,0.8-0.3
		c0.2-0.2,0.4-0.4,0.5-0.7s0.2-0.7,0.3-1.1C352.9,145.7,352.9,145.3,352.9,144.8z"
            />
          </g>
          <g>
            <path
              className="st8"
              d="M299.6,69c0,0.3-0.1,0.6-0.2,0.9s-0.3,0.5-0.5,0.7s-0.5,0.4-0.9,0.5c-0.3,0.1-0.7,0.2-1.2,0.2s-1-0.1-1.3-0.2
		s-0.6-0.3-0.8-0.6c-0.2-0.2-0.4-0.5-0.4-0.7s-0.1-0.5-0.1-0.8c0-0.2,0-0.5,0.1-0.7s0.2-0.4,0.3-0.6c0.1-0.2,0.3-0.4,0.4-0.5
		s0.3-0.3,0.5-0.4c-0.8-0.4-1.2-1.1-1.2-2c0-0.3,0.1-0.6,0.2-0.9c0.1-0.3,0.3-0.5,0.5-0.7s0.5-0.4,0.8-0.5s0.7-0.2,1-0.2
		c0.4,0,0.8,0.1,1.2,0.2c0.3,0.1,0.6,0.3,0.8,0.5c0.2,0.2,0.4,0.4,0.4,0.7c0.1,0.3,0.1,0.5,0.1,0.7s0,0.4-0.1,0.7
		c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.2-0.2,0.3-0.4,0.5c-0.1,0.1-0.3,0.3-0.5,0.4C299.1,67.4,299.5,68.1,299.6,69z M295.2,69.1
		c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.1,0.3,0.3,0.4c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.5,0.1,0.8,0.1s0.5,0,0.7-0.1s0.4-0.2,0.5-0.3
		c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.2,0.1-0.3,0.1-0.5c0-0.3,0-0.5-0.1-0.7s-0.2-0.4-0.4-0.5c-0.2-0.1-0.4-0.3-0.6-0.4
		s-0.5-0.2-0.9-0.3c-0.4,0.2-0.6,0.4-0.8,0.7C295.3,68.4,295.2,68.7,295.2,69.1z M298.2,64.9c0-0.1,0-0.3-0.1-0.4s-0.1-0.3-0.2-0.4
		c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1c-0.3,0-0.5,0-0.7,0.1s-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.3-0.2,0.4
		s-0.1,0.3-0.1,0.4c0,0.2,0,0.4,0.1,0.5c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.2,0.3,0.3,0.5,0.4s0.5,0.2,0.8,0.3c0.3-0.2,0.6-0.4,0.8-0.7
		C298.1,65.6,298.2,65.3,298.2,64.9z"
            />
            <path
              className="st8"
              d="M300.8,67c0-1.4,0.2-2.5,0.7-3.2c0.5-0.8,1.2-1.1,2-1.1s1.6,0.4,2,1.1c0.5,0.8,0.7,1.8,0.7,3.2
		s-0.2,2.5-0.7,3.2c-0.5,0.8-1.2,1.1-2,1.1s-1.6-0.4-2-1.1S300.8,68.4,300.8,67z M305.2,67c0-0.5,0-0.9-0.1-1.3s-0.1-0.8-0.3-1.1
		s-0.3-0.5-0.5-0.7c-0.2-0.2-0.5-0.3-0.8-0.3s-0.6,0.1-0.8,0.3c-0.2,0.2-0.4,0.4-0.5,0.7s-0.2,0.7-0.3,1.1c-0.1,0.4-0.1,0.8-0.1,1.3
		s0,0.9,0.1,1.3s0.1,0.8,0.3,1.1c0.1,0.3,0.3,0.5,0.5,0.7s0.5,0.3,0.8,0.3s0.6-0.1,0.8-0.3c0.2-0.2,0.4-0.4,0.5-0.7
		c0.1-0.3,0.2-0.7,0.3-1.1C305.2,67.9,305.2,67.5,305.2,67z"
            />
          </g>
          <g>
            <path
              className="st8"
              d="M322.9,97.6c0.5-0.2,0.9-0.4,1.3-0.7c0.4-0.3,0.8-0.6,1.2-1h0.8v8.3h-1.1v-6.8c-0.1,0.1-0.2,0.2-0.4,0.3
		c-0.1,0.1-0.3,0.2-0.5,0.3s-0.3,0.2-0.5,0.3c-0.2,0.1-0.4,0.1-0.5,0.2L322.9,97.6z"
            />
            <path
              className="st8"
              d="M329.1,100c0-1.4,0.2-2.5,0.7-3.2c0.5-0.8,1.2-1.1,2-1.1s1.6,0.4,2,1.1c0.5,0.8,0.7,1.8,0.7,3.2
		s-0.2,2.5-0.7,3.2c-0.5,0.8-1.2,1.1-2,1.1s-1.6-0.4-2-1.1C329.4,102.5,329.1,101.4,329.1,100z M333.5,100c0-0.5,0-0.9-0.1-1.3
		s-0.1-0.8-0.3-1.1s-0.3-0.5-0.5-0.7s-0.5-0.3-0.8-0.3s-0.6,0.1-0.8,0.3c-0.2,0.2-0.4,0.4-0.5,0.7s-0.2,0.7-0.3,1.1
		c-0.1,0.4-0.1,0.8-0.1,1.3s0,0.9,0.1,1.3s0.1,0.8,0.3,1.1c0.1,0.3,0.3,0.5,0.5,0.7s0.5,0.3,0.8,0.3s0.6-0.1,0.8-0.3
		c0.2-0.2,0.4-0.4,0.5-0.7c0.1-0.3,0.2-0.7,0.3-1.1C333.5,100.9,333.5,100.5,333.5,100z"
            />
            <path
              className="st8"
              d="M335.9,100c0-1.4,0.2-2.5,0.7-3.2c0.5-0.8,1.2-1.1,2-1.1s1.6,0.4,2,1.1c0.5,0.8,0.7,1.8,0.7,3.2
		s-0.2,2.5-0.7,3.2c-0.5,0.8-1.2,1.1-2,1.1s-1.6-0.4-2-1.1C336.1,102.5,335.9,101.4,335.9,100z M340.3,100c0-0.5,0-0.9-0.1-1.3
		s-0.1-0.8-0.3-1.1s-0.3-0.5-0.5-0.7s-0.5-0.3-0.8-0.3s-0.6,0.1-0.8,0.3c-0.2,0.2-0.4,0.4-0.5,0.7s-0.2,0.7-0.3,1.1
		c-0.1,0.4-0.1,0.8-0.1,1.3s0,0.9,0.1,1.3s0.1,0.8,0.3,1.1c0.1,0.3,0.3,0.5,0.5,0.7s0.5,0.3,0.8,0.3s0.6-0.1,0.8-0.3
		c0.2-0.2,0.4-0.4,0.5-0.7c0.1-0.3,0.2-0.7,0.3-1.1C340.3,100.9,340.3,100.5,340.3,100z"
            />
          </g>
          <path
            className="st11"
            d="M436.5,147h-17v-2c0-19.3-3.3-38.3-9.9-56.3l-0.7-1.9l15.9-5.8c1-0.4,2.2,0.2,2.6,1.2s-0.2,2.2-1.2,2.6
	L414,89.2c6,17.3,9.2,35.3,9.4,53.8h13c1.1,0,2,0.9,2,2S437.6,147,436.5,147z"
          />
          <path
            className="st11"
            d="M90.5,147h-17c-1.1,0-2-0.9-2-2s0.9-2,2-2h13c0.2-18.4,3.4-36.5,9.4-53.8l-12.2-4.4c-1-0.4-1.6-1.5-1.2-2.6
	c0.4-1,1.5-1.6,2.6-1.2l15.9,5.8l-0.7,1.9c-6.6,18-9.9,36.9-9.9,56.3L90.5,147L90.5,147z"
          />
          {/**Encendido andando, con los colores de velocidad igual a la lista */}
          {/* <g>
            <path
              className="st7"
              d="M126.8,26.8L130,30l-3.2,3.2V26.8 M125,22.5v15l7.5-7.5L125,22.5L125,22.5z"
            />
            <polygon
              className="st7"
              points="119.3,27 117.5,27 117.5,33 119.3,33 	"
            />
            <polygon
              className="st7"
              points="123.2,27 121.1,27 121.1,33 123.2,33 	"
            />
          </g> */}
          <g>
            <img
              style={{
                overflow: "visible",
                opacity: "0.25",
                enableBackground: "new    ",
                width: "171",
                height: "85",
                transform: "matrix(1 0 0 1 170 105)",
              }}
              xlinkHref="1E1E43C4.png"
              transform="matrix(1 0 0 1 170 105)"
              alt=""
            ></img>
            <g>
              <path
                className="st8"
                d="M325,145c0,10.7-2.4,20.9-6.7,30H191.7c-4.3-9.1-6.7-19.3-6.7-30c0-8.8,1.6-17.2,4.6-25h130.8
			C323.4,127.8,325,136.2,325,145z"
              />
            </g>
          </g>
          <g>
            <img
              style={{
                overflow: "visible",
                opacity: "0.25",
                enableBackground: "new    ",
                width: "162",
                height: "75",
                transform: "matrix(1 0 0 1 174 60)",
              }}
              xlinkHref="1E1E43C5.png"
              alt=""
            ></img>
            <g>
              <path
                className="st5"
                d="M320.4,120H189.6c10.1-26.3,35.6-45,65.4-45S310.3,93.7,320.4,120z"
              />
            </g>
          </g>
          <g>
            <path
              className="st0"
              d="M289,147.4c0.2-0.2,0.4-0.4,0.5-0.6c0.2-0.2,0.4-0.4,0.5-0.6s0.3-0.4,0.5-0.6c0.2-0.2,0.3-0.3,0.4-0.5h2.1
		c-0.4,0.5-0.8,1-1.2,1.4c-0.4,0.4-0.8,0.9-1.3,1.4c0.2,0.2,0.5,0.5,0.7,0.8s0.5,0.6,0.7,0.9c0.2,0.3,0.5,0.6,0.7,1
		c0.2,0.3,0.4,0.6,0.5,0.9H291c-0.1-0.2-0.3-0.4-0.4-0.7c-0.2-0.3-0.3-0.5-0.5-0.8s-0.4-0.5-0.6-0.7s-0.4-0.4-0.6-0.6v2.8h-1.8v-9
		l1.8-0.3L289,147.4L289,147.4z"
            />
            <path
              className="st0"
              d="M297.6,148.1c0-0.6-0.1-1-0.2-1.2s-0.4-0.4-0.8-0.4c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0v4.9h-1.8v-6.1
		c0.2,0,0.3-0.1,0.5-0.1s0.4-0.1,0.6-0.1s0.5-0.1,0.7-0.1s0.5,0,0.7,0c0.5,0,0.8,0.1,1.1,0.2c0.3,0.1,0.5,0.3,0.7,0.4
		c0.3-0.2,0.5-0.3,0.9-0.4c0.3-0.1,0.6-0.2,0.9-0.2c0.5,0,0.9,0.1,1.2,0.2c0.3,0.1,0.6,0.3,0.8,0.6c0.2,0.3,0.3,0.6,0.4,0.9
		c0.1,0.4,0.1,0.7,0.1,1.2v3.6H301v-3.3c0-0.6-0.1-1-0.2-1.2s-0.4-0.4-0.8-0.4c-0.1,0-0.2,0-0.4,0.1c-0.2,0-0.3,0.1-0.4,0.2
		c0.1,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.4,0,0.7v3.4h-1.8L297.6,148.1L297.6,148.1z"
            />
          </g>
          <text
            transform="matrix(1 0 0 1 255.0019 100.6274)"
            className="st0 st3 st4"
          >
            {this.props.marker[0].position.velocidad} V
          </text>
          <g>
            <path
              className="st2"
              d="M275,155h-65c-2.8,0-5-2.2-5-5v-5c0-2.8,2.2-5,5-5h65c2.8,0,5,2.2,5,5v5C280,152.8,277.8,155,275,155z"
            />
          </g>
          <text
            transform="matrix(1 0 0 1 210.2322 150.6274)"
            className="st0 st3 st4 st12"
          >
            {this.props.marker[0].position.dis_count}
          </text>
          <path
            className="st0"
            d="M239.8,93H238v1.8h-1.2V93H235v-1.2h1.8V90h1.2v1.8h1.8V93z M250,102h-4.8v1.2h4.8V102z M241.4,96.6l5.3-5.3
	l-1.3-1.3l-6.5,6.5l-1.8,1.8h6.6l-5.3,5.3l1.3,1.3l6.5-6.5l1.8-1.8H241.4z"
          />
          <polygon
            style={{ cursor: "pointer" }}
            onClick={this.props.closeWidget}
            className="st0"
            points="392.5,24.2 390.8,22.5 385,28.3 379.2,22.5 377.5,24.2 383.3,30 377.5,35.8 379.2,37.5 385,31.7 
	390.8,37.5 392.5,35.8 386.7,30 "
          />
          <line className="st13" x1="390" y1="145" x2="360" y2="145" />
          <line className="st13" x1="150" y1="145" x2="120" y2="145" />
          <line className="st14" x1="126.1" y1="128" x2="121.2" y2="127.4" />
          <line className="st15" x1="139.1" y1="113.9" x2="124.6" y2="110.1" />
          <line className="st13" x1="371.9" y1="77.5" x2="345.9" y2="92.5" />
          <line className="st13" x1="322.5" y1="28.1" x2="307.5" y2="54.1" />
          <line className="st13" x1="255" y1="10" x2="255" y2="40" />
          <line className="st13" x1="202.5" y1="54.1" x2="187.5" y2="28.1" />
          <line className="st13" x1="138.1" y1="77.5" x2="164.1" y2="92.5" />
          {/**Parking mas de 2 minutos detenido, sky1 encendido y gray1 apagado*/}
          {/* <path
            className="st7"
            d="M125,23.7c3.5,0,6.3,2.8,6.3,6.3s-2.8,6.3-6.3,6.3s-6.3-2.8-6.3-6.3S121.5,23.7,125,23.7 M125,22.5
	c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5c4.1,0,7.5-3.4,7.5-7.5S129.1,22.5,125,22.5L125,22.5z M126,25.9h-3.3v0.9v4.5V34h1.8v-2.7
	h1.5c1.5,0,2.7-1.2,2.7-2.7C128.7,27.2,127.5,25.9,126,25.9z M126,29.5h-1.5v-1.8h1.5c0.5,0,0.9,0.4,0.9,0.9S126.5,29.5,126,29.5z"
          /> */}
          {/**Detenido, sky1 */}
          {/* <path
            className="st5"
            d="M128.6,29.1v1.8h-7.2v-1.8H128.6L128.6,29.1z M125,24.3c-3.1,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7
	c3.1,0,5.7-2.6,5.7-5.7S128.1,24.3,125,24.3 M125,22.5c4.1,0,7.5,3.4,7.5,7.5s-3.4,7.5-7.5,7.5s-7.5-3.4-7.5-7.5
	S120.9,22.5,125,22.5L125,22.5z"
          /> */}
          <line className="st15" x1="170.1" y1="60.1" x2="159.5" y2="49.5" />
          <line className="st15" x1="223.9" y1="29.1" x2="220.1" y2="14.6" />
          <line className="st15" x1="286.1" y1="29.1" x2="289.9" y2="14.6" />
          <line className="st15" x1="339.9" y1="60.1" x2="350.5" y2="49.5" />
          <line className="st15" x1="370.9" y1="113.9" x2="385.4" y2="110.1" />
          <line className="st14" x1="388.8" y1="162.6" x2="383.9" y2="162" />
          <line className="st14" x1="134.9" y1="95.3" x2="130.3" y2="93.3" />
          <line className="st14" x1="151.9" y1="65.9" x2="147.9" y2="62.8" />
          <line className="st14" x1="175.9" y1="41.9" x2="172.8" y2="37.9" />
          <line className="st14" x1="205.3" y1="24.9" x2="203.3" y2="20.3" />
          <line className="st14" x1="238" y1="16.1" x2="237.4" y2="11.2" />
          <line className="st14" x1="272" y1="16.1" x2="272.6" y2="11.2" />
          <line className="st14" x1="304.8" y1="24.9" x2="306.7" y2="20.3" />
          <line className="st14" x1="334.1" y1="41.9" x2="337.2" y2="37.9" />
          <line className="st14" x1="358.1" y1="65.9" x2="362.1" y2="62.8" />
          <line className="st14" x1="375.1" y1="95.3" x2="379.7" y2="93.3" />
          <line className="st14" x1="383.9" y1="128" x2="388.8" y2="127.4" />
          <line className="st14" x1="121.2" y1="162.6" x2="126.1" y2="162" />
        </svg>
      </div>
    );
  }
}
