import React, { Component } from "react";
import {
  createUnit,
  CloseCreateUnit,
} from "../../../../../../../redux/actions/UnitsActions";
import { connect } from "react-redux";

import "./ModalUnit.scss";
import ModalAlert from "../ModalAlert/ModalAlert";
import { ReactComponent as Checked } from "../../assets/Checked.svg";
import { ReactComponent as ErrorIco } from "../../assets/motion_icon-salir-cerrar.svg";
import InputMotion from "../../../MotionComponents/InputMotion/InputMotion";
import { getDevicesMotion } from "app/redux/actions/DeviceActions";
import SelectMotion from "../../../MotionComponents/SelectMotion/SelectMotion";

const mapStateToProps = (state) => ({
  createUnit: state.createUnit,
  form: state.form,
  coolors: state.CoolorsReducer.coolors,
  images: state.ImagesReducer.images,
  data: state.data,
  devices: state.DevicesReducer.devices,
});

const expressions = {
  usuario: /^[a-zA-Z0-9_-]{4,16}$/, // Letras, numeros, guion y guion_bajo
  name: /^[a-zA-Z0-9\s]{1,10}$/, // Letras y espacios, pueden llevar acentos.
  placa: /^[a-zA-Z0-9\s_-]{1,8}$/, // Letras y espacios, pueden llevar acentos.
  marca: /^[a-zA-ZÀ-ÿ\s]{1,10}$/, // Letras y espacios, pueden llevar acentos.
  clase: /^[a-zA-ZÀ-ÿ\s]{1,10}$/, // Letras y espacios, pueden llevar acentos.
  password: /^.{4,12}$/, // 4 a 12 digitos.
  correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  number: /^\d{1,20}$/, // 7 a 14 numeros.
  phone: /^\d{7,14}$/, // 7 a 14 numeros.
};

const mapDispatchToProps = (dispatch) => ({
  createUnit: (data) => {
    dispatch(createUnit(data));
  },
  CloseCreateUnit: () => {
    dispatch(CloseCreateUnit());
  },
  getDevicesMotion: () => {
    dispatch(getDevicesMotion());
  },
});

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        color: 1,
        img_type: 1,
        device: 1,
      },
      name: {
        field: "",
        valid: null,
      },
      placa: {
        field: "",
        valid: null,
      },
      marca: {
        field: "",
        valid: null,
      },
      clase: {
        field: "",
        valid: null,
      },
      modelo: {
        field: "",
        valid: null,
      },
      imei: {
        field: "",
        valid: null,
      },
      vin: {
        field: "",
        valid: null,
      },
      phone: {
        field: "",
        valid: null,
      },
      devices: [],
      background: "",
      labelBackground: "Color",
      showPicker: false,
      //Images
      image: "bus",
      nameImage: "Bus",
    };
    this.showPicker = this.showPicker.bind(this);
    this.colorPicker = this.colorPicker.bind(this);
    this.renderImgType = this.renderImgType.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.setDevices = this.setDevices.bind(this);
  }

  componentDidMount() {
    this.props.getDevicesMotion();

    this.setState({
      form: {
        ...this.state.form,
        color: this.props.coolors[0][0].id,
      },
      background: this.props.coolors[0][0].color,
      filterCss: this.props.coolors[0][0].filterCss,
    });
    this.setDevices();
  }

  componentDidUpdate() {
    this.setDevices();
  }

  setDevices() {
    const { devices } = this.props;
    var fields = devices.map((device) => {
      var data = {
        value: device.id,
        name: device.name,
      };
      return data;
    });
    if (fields.length > 0 && this.state.devices < 1) {
      this.setState({
        devices: fields,
      });
    }
  }

  handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
    });
  };

  handleChangeTest = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    if (name) {
      this.setState({
        [name]: {
          ...this.state[name],
          field: value,
          valid: event.valid,
        },
      });
    }
  };

  colorPicker(coolor) {
    this.setState({
      form: {
        ...this.state.form,
        color: coolor.id,
      },
      background: coolor.color,
      filterCss: coolor.filterCss,
      nameColor: coolor.name,
    });
  }

  handleChangeImages = (event) => {
    const target = event.target;
    const value = target.value;
    const id_select = parseInt(value);
    const images = this.props.images.filter((img) => {
      return img.id === id_select;
    });
    this.setState({
      form: { ...this.state.form, img_type: images[0].id },
      image: images[0].value,
      nameImage: images[0].name,
    });
  };

  handleChangeComplete = (event) => {
    const target = event.target;
    const value = target.value;
    const id_select = parseInt(value);
    const coolor = this.props.coolors[0].filter((color) => {
      return color.id === id_select;
    });
    this.setState({
      form: { ...this.state.form, color: coolor[0].id },
      background: coolor[0].color,
      filterCss: coolor[0].filterCss,
      nameColor: coolor[0].name,
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    let color = this.state.form.color;
    let img_type = this.state.form.img_type;
    let device = this.state.form.device;
    const { name, placa, marca, clase, modelo, vin, imei, phone } = this.state;
    if (
      name.valid &&
      placa.valid &&
      marca.valid &&
      clase.valid &&
      modelo.valid &&
      vin.valid &&
      imei.valid &&
      phone.valid
    ) {
      var data = {
        name: name.field,
        placa: placa.field,
        marca: marca.field,
        clase: clase.field,
        modelo: modelo.field,
        vin: vin.field,
        imei: imei.field,
        phone: phone.field,
        color: color,
        img_type: img_type,
        device: device,
      };
      this.props.createUnit(data);
    }
  };

  renderImgType() {
    return (
      <div className="modal-form-units-img">
        <img
          style={{
            position: "absolute",
            zIndex: 2,
          }}
          className="deviceImgGif img-front"
          src={`/assets/images/animation/${this.state.image}/${this.state.image}-modal.gif`}
          alt="device img"
        />
        <img
          className="deviceImgGif"
          style={{
            position: "absolute",
            zIndex: 1,
            filter: this.state.filterCss + "drop-shadow(0px 0px 10px #8c8c8c)",
          }}
          src={`/assets/images/animation/${this.state.image}/${this.state.image}-modal-color.gif`}
          alt=""
        />
      </div>
    );
  }

  showPicker() {
    this.setState({
      showPicker: !this.state.showPicker,
    });
  }

  closeModal() {
    this.props.CloseCreateUnit();
    this.props.toggleModal();
  }

  render() {
    return (
      <div className="row ModalUnit">
        {this.props.data.isLoadingCreate ? (
          <ModalAlert message="Cargando" />
        ) : this.props.data.createSuccess ? (
          <ModalAlert
            message="Unidad creada con exito"
            fun={this.closeModal}
            icon={<Checked />}
          />
        ) : this.props.data.errMessCreate ? (
          <ModalAlert
            message="Error en la actualizacion de la unidad"
            fun={this.props.CloseCreateUnit}
            icon={<ErrorIco />}
          />
        ) : (
          <div className="modal-container">
            <div className="modal-header row">
              <img
                className="miniMapCloseIcon"
                src="/assets/images/svg/zoomIn.svg"
                onClick={this.props.toggleModal}
                alt=""
              />
            </div>
            <form className="row modal-form-units">
              <div className="column modal-form-colum1">
                <h1 className="blue2">Nueva Unidad</h1>
                <h2 className="gray1">Datos de la unidad</h2>
                <div className="row modal-form-units-row">
                  <InputMotion
                    type="text"
                    placeholder="Nombre Ref."
                    state={this.state.name}
                    changeField={this.handleChangeTest}
                    err="El nombre solo puede contener letras y espacios."
                    name="name"
                    id="name"
                    width="50%"
                    expression={expressions.name}
                  />
                  <InputMotion
                    type="text"
                    placeholder="Placa"
                    state={this.state.placa}
                    changeField={this.handleChangeTest}
                    err="La placa puede solo tener de 1 a 8 caracteres"
                    name="placa"
                    id="placa"
                    width="40%"
                    expression={expressions.placa}
                  />
                </div>
                <div className="row modal-form-units-row">
                  <InputMotion
                    type="text"
                    placeholder="Marca"
                    state={this.state.marca}
                    changeField={this.handleChangeTest}
                    err="La marca solo puede contener letras y espacios."
                    name="marca"
                    width="30%"
                    expression={expressions.marca}
                  />
                  <InputMotion
                    type="text"
                    placeholder="Clase"
                    state={this.state.clase}
                    changeField={this.handleChangeTest}
                    err="La clase solo puede contener letras y espacios."
                    name="clase"
                    width="30%"
                    expression={expressions.clase}
                  />
                  <InputMotion
                    type="text"
                    placeholder="Modelo"
                    state={this.state.modelo}
                    changeField={this.handleChangeTest}
                    err="El modelo solo puede contener letras y espacios."
                    name="modelo"
                    width="30%"
                    expression={expressions.name}
                  />
                </div>
                <div className="row modal-form-units-row">
                  <InputMotion
                    type="text"
                    placeholder="Vin"
                    state={this.state.vin}
                    changeField={this.handleChangeTest}
                    err="El vin solo puede contener de 1 a 20 dijitos y solamente numeros."
                    name="vin"
                    width="48%"
                    expression={expressions.number}
                  />
                  <InputMotion
                    type="text"
                    placeholder="Imei"
                    state={this.state.imei}
                    changeField={this.handleChangeTest}
                    err="El imei solo puede contener de 1 a 20 dijitos y solamente numeros."
                    name="imei"
                    width="48%"
                    expression={expressions.number}
                  />
                </div>
                <div className="row modal-form-units-row">
                  <InputMotion
                    type="text"
                    placeholder="Teléfono"
                    state={this.state.phone}
                    changeField={this.handleChangeTest}
                    err="El telefono solo puede contener numeros, de 7 a 14 dijitos."
                    name="phone"
                    width="48%"
                    expression={expressions.phone}
                  />
                  <SelectMotion
                    value={this.state.form.device}
                    name="device"
                    onChange={this.handleChange}
                    fields={this.state.devices}
                    width="48%"
                  />
                </div>
              </div>
              <div className="column modal-form-colum2">
                <select
                  name="img_type"
                  className="modal-form-units-select1"
                  onChange={this.handleChangeImages}
                  defaultValue={this.state.nameImage}
                >
                  <option hidden>{this.state.nameImage}</option>
                  {this.props.images.map((image) => {
                    return (
                      <option key={image.id} value={image.id}>
                        {image.name}
                      </option>
                    );
                  })}
                </select>
                {this.renderImgType()}
                <div className="row modal-form-units-row-color">
                  <select
                    name="color"
                    className="modal-form-units-select1"
                    onChange={this.handleChangeComplete}
                    placeholder="Tipo de vehiculo"
                    style={{
                      background: `${this.state.background}`,
                      color: `${
                        this.state.background === "#FFFFFF" ? "#c5c5c5" : "#fff"
                      }`,
                      border: `${
                        this.state.background === "#FFFFFF"
                          ? "1px solid #c5c5c5"
                          : "1px solid #fff"
                      }`,
                    }}
                    defaultValue={this.state.labelBackground}
                  >
                    <option hidden selected>
                      {this.state.nameColor
                        ? this.state.nameColor
                        : this.state.labelBackground}
                    </option>
                    {this.props.coolors[0].map((coolor) => {
                      return (
                        <option key={coolor.id} value={coolor.id}>
                          {coolor.name}
                        </option>
                      );
                    })}
                  </select>
                  <div>
                    <div
                      onClick={this.showPicker}
                      className="modal-form-units-button-colorpicker"
                      style={{
                        background: `${this.state.background}`,
                        border: `${
                          this.state.background === "#FFFFFF"
                            ? "1px solid #c5c5c5"
                            : "1px solid #fff"
                        }`,
                      }}
                    ></div>
                    {this.state.showPicker ? (
                      <div className="color-picker">
                        <div className="colorPicker-container">
                          {this.props.coolors[0].map((coolor) => {
                            return (
                              <div
                                className="coloPicker-item-container"
                                key={coolor.id}
                              >
                                <div
                                  className="coloPicker-item"
                                  style={{
                                    backgroundColor: `${coolor.color}`,
                                  }}
                                  name="color"
                                  value={coolor.value}
                                  onClick={() => this.colorPicker(coolor)}
                                ></div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <button className="button-form" onClick={this.onSubmit}>
                  Crear unidad
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
