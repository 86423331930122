import { Component } from "react";

export default class Polyline extends Component {
  constructor() {
    super();
    this.state = {
      path2: {},
      geodesicPolyline: null,
    };
  }

  componentDidMount() {
    this.setState({
      geodesicPolyline: new this.props.maps.Polyline({
        path: this.state.path2,
        geodesic: false,
        strokeColor: "#00a1e1",
        strokeOpacity: 1.0,
        strokeWeight: 4,
      }),
    });
  }

  componentDidUpdate(previousProps) {
    if (previousProps.marker) {
      if (
        previousProps.marker[0].position.latitud !==
        this.props.marker[0].position.latitud
      ) {
        let path4 = {
          lat: previousProps.marker[0].position.latitud,
          lng: previousProps.marker[0].position.longitud,
        };
        let path3 = {
          lat: this.props.marker[0].position.latitud,
          lng: this.props.marker[0].position.longitud,
        };
        this.setState({
          path2: [path4, path3],
          geodesicPolyline: new this.props.maps.Polyline({
            path: this.state.path,
            geodesic: false,
            strokeColor: "#00a1e1",
            strokeOpacity: 1.0,
            strokeWeight: 4,
          }),
        });
      }
    }
  }

  componentWillUnmount() {
    this.state.geodesicPolyline.setMap(null);
  }

  renderPolylines() {
    // const { map, maps } = this.props;

    /** Example of rendering geodesic polyline */
    // this.state.geodesicPolyline.setMap(map);
  }

  render() {
    // console.log(this.state);

    return <>{this.state.path2.length > 0 ? this.renderPolylines() : null}</>;
  }
}
