import React, { Component } from "react";
import "./MoreApps.scss";

import DeliveryIco from "./assets/delivery-control-logo-512px.png";
import EnlistIco from "./assets/enlist-control-logo-512px.png";
import FuecIco from "./assets/fuec-icon-512px.png";
import IntervalControlIco from "./assets/interval-control-logo-512px.png";
import RouteControlIco from "./assets/route-control-logo-512px.png";

export default class MoreApps extends Component {
  render() {
    return (
      <div className="MoreApps">
        <div className="MoreApps-content">
          <div className="MoreApps-item-content">
            <a
              href="https://monitoringinnovation.com/enlistcontrol/"
              className="MoreApps-item"
            >
              <img src={EnlistIco} className="MoreApps-ico" />
              <div className="MoreApps-text">Enlist control</div>
            </a>
          </div>
          <div className="MoreApps-item-content">
            <a
              href="https://monitoringinnovation.com/fuec/"
              className="MoreApps-item"
            >
              <img src={FuecIco} className="MoreApps-ico" />
              <div className="MoreApps-text">Fuec</div>
            </a>
          </div>
          <div className="MoreApps-item-content">
            <a
              href="https://monitoringinnovation.com/fuec/"
              className="MoreApps-item"
            >
              <img src={DeliveryIco} className="MoreApps-ico" />
              <div className="MoreApps-text">Delivery</div>
            </a>
          </div>
          <div className="MoreApps-item-content">
            <a
              href="https://monitoringinnovation.com/fuec/"
              className="MoreApps-item"
            >
              <img src={IntervalControlIco} className="MoreApps-ico" />
              <div className="MoreApps-text">Interval control</div>
            </a>
          </div>
          <div className="MoreApps-item-content">
            <a
              href="https://monitoringinnovation.com/fuec/"
              className="MoreApps-item"
            >
              <img src={RouteControlIco} className="MoreApps-ico" />
              <div className="MoreApps-text">Route control</div>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
