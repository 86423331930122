import React, { Component } from "react";
import { Spinner } from "reactstrap";
import { UncontrolledTooltip } from "reactstrap";
import { ReactComponent as ShowIcon } from "../icons/motion_icon-27.svg";
import { ReactComponent as OptionsIcon } from "../icons/motion_icon-28.svg";
import { ReactComponent as SignalIcon } from "../icons/motion_icon-09.svg";
import { ReactComponent as LastMovementIcon } from "../icons/motion_icon-25.svg";
import { ReactComponent as SearchIcon } from "../icons/motion_icon-20.svg";
import { ReactComponent as EnterpriseLogo } from "../icons/motion-logo-web.svg";
import { ReactComponent as DropDownIcon } from "../icons/motion_icon-56.svg";
import { ReactComponent as SortByType } from "../icons/motion_icon-55.svg";
import { ReactComponent as SortByName } from "../icons/motion_icon-53.svg";
import { ReactComponent as SortByGroup } from "../icons/motion_icon-54.svg";

import DeviceCard from "./components/DeviceCard/DeviceCard";
import ListOptions from "./components/Options/ListOptions";

import ReactPaginate from "react-paginate";

import "./ListOfDevices.scss";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    data: state.data,
    value: state.value,
    buffer: state.BufferReducer.buffer,
  };
};

class ListOfDevices extends Component {
  constructor(props) {
    super();
    this.state = {
      visibleVehicles: [],
      data: props.data,
      listData: [],
      vehicle: [],
      showAllVisible: false,
      showVisible: false,
      imgSecondary: false,
      /*Pagination*/
      allVehicles: [],
      offset: 0,
      tableData: [],
      orgtableData: [],
      perPage: 100,
      currentPage: 0,
    };
    this.handleCenter = this.handleCenter.bind(this);
    this.makeVehicleVisible = this.makeVehicleVisible.bind(this);
    this.filterByName = this.filterByName.bind(this);
    this.filterByState = this.filterByState.bind(this);
    this.filterBySignal = this.filterBySignal.bind(this);
    this.filterByType = this.filterByType.bind(this);
    this.deviceCard = this.deviceCard.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  //this.props.vehicleLocations

  componentDidMount() {
    let vehicles = this.props.vehicleLocations;
    var slice = vehicles.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );
    this.setState({
      allVehicles: vehicles,
      pageCount: Math.ceil(vehicles.length / this.state.perPage),
      tableData: slice,
    });
  }

  changeImage() {
    this.setState({
      imgSecondary: !this.state.imgSecondary,
    });
  }

  /*Pagination*/
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.loadMoreData();
      }
    );
  };
  loadMoreData() {
    const data = this.state.allVehicles;

    const slice = data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      tableData: slice,
    });
    console.log(data);
  }

  handleChangeRowsPerPage = (event) => {
    this.setRowsPerPage(parseInt(event.target.value, 10));
    this.setPage(0);
  };
  handleCenter(c) {
    this.props.setCenterMap(c);
  }
  makeVehicleVisible(id) {
    this.props.appendToVisibleVehicles(id);
  }

  toggleVisible() {
    this.setState({
      showAllVisible: !this.state.showAllVisible,
    });
  }
  handleAllVisible() {
    this.props.appendToVisibleAllVehicles();
    this.props.setZoomMap(6);
    this.props.setCenterMap([4.653891, -74.098075]);
  }

  filter(event) {
    var vehicle = event.target.value;
    const data = this.props.vehicleLocations;
    const newData = data.filter(function (item) {
      const itemData = item.name.toUpperCase();
      const vehicleData = vehicle.toUpperCase();
      return itemData.indexOf(vehicleData) > -1;
    });

    var slice = newData.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );
    this.setState({
      vehicle: vehicle,
      tableData: slice,
    });
  }
  filterByName() {
    let dataByName = this.props.vehicleLocations;
    let slice;
    if (this.state.filterName) {
      let newDataByName = dataByName.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });

      slice = newDataByName.slice(
        this.state.offset,
        this.state.offset + this.state.perPage
      );
      this.setState({
        tableData: slice,
        filterName: false,
      });
    } else {
      let newDataByName = dataByName.sort((a, b) => {
        if (a.name > b.name) {
          return -1;
        }
        if (a.name < b.name) {
          return 1;
        }
        return 0;
      });

      slice = newDataByName.slice(
        this.state.offset,
        this.state.offset + this.state.perPage
      );
      this.setState({
        tableData: slice,
        filterName: true,
      });
    }
  }
  filterByType() {
    let dataByType = this.props.vehicleLocations;
    let newDataByType = dataByType.sort((a, b) => {
      if (a.img_type > b.img_type) {
        return 1;
      }
      if (a.img_type < b.img_type) {
        return -1;
      }
      return 0;
    });
    var slice = newDataByType.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );
    this.setState({
      tableData: slice,
    });
  }
  filterByState() {
    let dataByState = this.props.vehicleLocations;
    let slice;
    if (this.state.filterState) {
      let newDataByState = dataByState.sort((a, b) => {
        return b.position.velocidad - a.position.velocidad;
      });
      slice = newDataByState.slice(
        this.state.offset,
        this.state.offset + this.state.perPage
      );
      this.setState({
        tableData: slice,
        filterState: false,
      });
    } else {
      let newDataByState = dataByState.sort((a, b) => {
        return a.position.velocidad - b.position.velocidad;
      });
      slice = newDataByState.slice(
        this.state.offset,
        this.state.offset + this.state.perPage
      );
      this.setState({
        tableData: slice,
        filterState: true,
      });
    }
  }
  filterBySignal() {
    let dataBySignal = this.props.vehicleLocations;
    let slice;
    if (this.state.filterSignal) {
      let newDataBySignal = dataBySignal.sort((a, b) => {
        return a.position.cnkb - b.position.cnkb;
      });
      slice = newDataBySignal.slice(
        this.state.offset,
        this.state.offset + this.state.perPage
      );
      this.setState({
        tableData: slice,
        filterSignal: false,
      });
    } else {
      let newDataBySignal = dataBySignal.sort((a, b) => {
        return b.position.cnkb - a.position.cnkb;
      });
      slice = newDataBySignal.slice(
        this.state.offset,
        this.state.offset + this.state.perPage
      );
      this.setState({
        tableData: slice,
        filterSignal: true,
      });
    }
  }

  deviceCard() {
    if (this.state.showAllVisible && this.props.showList) {
      return (
        <ul className="generalUnitList">
          {this.state.tableData.map((device) => {
            let vehicle = this.props.vehicleLocations.filter((vehicle) => {
              return vehicle.id === device.id;
            });
            return (
              <li key={"listUnit_" + device.id.toString()} className="listitem">
                <DeviceCard
                  id={device.id}
                  name={device.name}
                  type={device.img_type}
                  color={device.params.color}
                  speed={vehicle[0].position.velocidad}
                  engine={device.status}
                  onHoverMarker={this.props.onHoverMarker}
                  unHoverMarker={this.props.unHoverMarker}
                  makeVehicleVisible={this.makeVehicleVisible}
                  visibleVehicles={this.props.visibleVehicles}
                  onMarkerClick={this.props.onMarkerClick}
                  onMarkerDbClick={this.props.onMarkerDbClick}
                  vehicle={device}
                  appendToMiniMaps={this.props.appendToMiniMaps}
                  lat={vehicle[0].position.latitud}
                  lng={vehicle[0].position.longitud}
                  setCenter={this.handleCenter}
                  objDevice={device}
                  showAllVisible={this.state.showAllVisible}
                  imgSecondary={this.state.imgSecondary}
                  selectWidget={this.props.selectWidget}
                  cnkb={vehicle[0].position.cnkb}
                  toggleFocusMarker={this.props.toggleFocusMarker}
                  toggleFocusMarkerNull={this.props.toggleFocusMarkerNull}
                  setZoomMap={this.props.setZoomMap}
                  miniMaps={this.props.miniMaps}
                  toggleModalUpdateUnit={this.props.toggleModalUpdateUnit}
                  justAppendToVisibleVehicles={
                    this.props.justAppendToVisibleVehicles
                  }
                />
              </li>
            );
          })}
          {this.props.vehicleLocations.length > 100 ? (
            <div className="d-flex flex-row py-4 align-items-center">
              <ReactPaginate
                previousLabel={"prev"}
                nextLabel={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          ) : null}
        </ul>
      );
    } else if (this.props.showList) {
      return (
        <ul className="generalUnitList">
          {this.state.tableData.map((device) => {
            let vehicle = this.props.vehicleLocations.filter((vehicle) => {
              return vehicle.id === device.id;
            });
            let vehicleUpdate = this.props.buffer[1].filter((vehicle) => {
              return vehicle.id === device.id;
            });
            return (
              <li key={"listUnit_" + device.id.toString()} className="listitem">
                {vehicle[0] ? (
                  <DeviceCard
                    id={device.id}
                    name={vehicleUpdate[0].name}
                    type={vehicleUpdate[0].img_type}
                    color={vehicleUpdate[0].params.color}
                    speed={vehicle[0].position.velocidad}
                    engine={vehicle[0].status}
                    onHoverMarker={this.props.onHoverMarker}
                    unHoverMarker={this.props.unHoverMarker}
                    makeVehicleVisible={this.makeVehicleVisible}
                    visibleVehicles={this.props.visibleVehicles}
                    onMarkerClick={this.props.onMarkerClick}
                    onMarkerDbClick={this.props.onMarkerDbClick}
                    vehicle={device}
                    appendToMiniMaps={this.props.appendToMiniMaps}
                    lat={vehicle[0].position.latitud}
                    lng={vehicle[0].position.longitud}
                    setCenter={this.handleCenter}
                    objDevice={device}
                    showAllVisible={this.state.showAllVisible}
                    imgSecondary={this.state.imgSecondary}
                    selectWidget={this.props.selectWidget}
                    cnkb={vehicle[0].position.cnkb}
                    toggleFocusMarker={this.props.toggleFocusMarker}
                    toggleFocusMarkerNull={this.props.toggleFocusMarkerNull}
                    setZoomMap={this.props.setZoomMap}
                    miniMaps={this.props.miniMaps}
                    toggleModalUpdateUnit={this.props.toggleModalUpdateUnit}
                    justAppendToVisibleVehicles={
                      this.props.justAppendToVisibleVehicles
                    }
                  />
                ) : null}
              </li>
            );
          })}
          {this.props.vehicleLocations.length > 100 ? (
            <div className="d-flex flex-row py-4 align-items-center">
              <ReactPaginate
                previousLabel={"prev"}
                nextLabel={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          ) : null}
        </ul>
      );
    } else {
      <></>;
    }
  }

  render() {
    var seeVisible = this.props.showAllVisible ? (
      // colores c5c5c5 y 01bedb
      <ShowIcon
        fill="#01bedb"
        className="filterIcon"
        id="sortByVisibility"
        onClick={() => this.handleAllVisible()}
      />
    ) : (
      <ShowIcon
        fill="#c5c5c5"
        className="filterIcon"
        id="sortByVisibility"
        onClick={() => this.handleAllVisible()}
      />
    );

    let sizeHeight = this.props.showList ? "calc(100% - 35px)" : "90px";

    if (this.props.data.isLoading) {
      return (
        <div className="col-10 m-auto loading">
          <Spinner color="primary" className="loading" />
          <p>LOADING...</p>
        </div>
      );
    }

    if (!this.props.vehicleLocations) {
      return <p>Ooops, algo salio mal {this.props.data.errMess}</p>;
    }

    return (
      <div
        className="searchBar motion-z1 column"
        style={{
          height: sizeHeight,
          borderRadius: 15,
          margin: "16px",
        }}
      >
        <div className="searchBarHeader">
          <EnterpriseLogo className="searchBarHeaderIcon" />
          <form className="search" action="#">
            <input
              className="searchInput"
              type="text"
              placeholder={"Búsqueda..."}
              value={this.state.vehicleLocations}
              onChange={(vehicle) => this.filter(vehicle)}
            />
            <div className="searchButton">
              <SearchIcon fill="#c5c5c5" className="searchIcon" />
            </div>
          </form>
        </div>

        <div className="dropdownContainer">
          {this.props.showList ? (
            <div className="filterSection">
              <SortByType
                onClick={this.filterByType}
                fill="#c5c5c5"
                id="sortByType"
                className="SortIcon"
              />
              <DropDownIcon fill="#c5c5c5" className="dropDownIcon" />
              <SortByName
                onClick={this.filterByName}
                fill="#c5c5c5"
                className="SortIcon"
                id="sortByName"
              />
              <DropDownIcon fill="#c5c5c5" className="dropDownIcon" />

              <SortByGroup
                fill="#c5c5c5"
                className="groupIcon"
                id="sortByGroup"
              />

              <LastMovementIcon
                fill="#c5c5c5"
                className="filterIcon"
                id="sortByStatus"
                onClick={this.filterByState}
              />
              <DropDownIcon fill="#c5c5c5" className="dropDownIcon" />
              <SignalIcon
                fill="#c5c5c5"
                className="filterIcon"
                id="sortBySignal"
                onClick={this.filterBySignal}
              />
              <DropDownIcon fill="#c5c5c5" className="dropDownIcon" />
              {seeVisible}
              <DropDownIcon fill="#c5c5c5" className="dropDownIcon" />

              <OptionsIcon
                fill="#c5c5c5"
                className="searchSortIcon"
                id={"options"}
              />
              <UncontrolledTooltip
                autohide={false}
                placement="right"
                target={"options"}
                className="options"
              >
                <ListOptions
                  onMarkerDbClick={this.props.onMarkerDbClick}
                  appendToMiniMaps={this.props.appendToMiniMaps}
                  selectWidget={this.props.selectWidget}
                  toggleModal={this.props.toggleModal}
                  toggleModalGroupUnits={this.props.toggleModalGroupUnits}
                />
              </UncontrolledTooltip>

              <UncontrolledTooltip
                placement="right"
                target={"sortByType"}
                className="tooltipImageStop"
              >
                <div className="sortShort">
                  <div className="deviceOffStatus">Ordenar por</div>
                  <div className="deviceTimeOffStatus">{"Clase"}</div>
                </div>
              </UncontrolledTooltip>
              <UncontrolledTooltip
                placement="right"
                target={"sortByName"}
                className="tooltipImageStop"
              >
                <div className="sortShort">
                  <div className="deviceOffStatus">Ordenar por</div>
                  <div className="deviceTimeOffStatus">{"Nombre"}</div>
                </div>
              </UncontrolledTooltip>
              <UncontrolledTooltip
                placement="right"
                target={"sortByGroup"}
                className="tooltipImageStop"
              >
                <div className="sortShort">
                  <div className="deviceOffStatus">Ordenar por</div>
                  <div className="deviceTimeOffStatus">{"Grupos"}</div>
                </div>
              </UncontrolledTooltip>
              <UncontrolledTooltip
                placement="right"
                target={"sortByStatus"}
                className="tooltipImageStop"
              >
                <div className="sortShort">
                  <div className="deviceOffStatus">Ordenar por</div>
                  <div className="deviceTimeOffStatus">{"Estado"}</div>
                </div>
              </UncontrolledTooltip>
              <UncontrolledTooltip
                placement="right"
                target={"sortBySignal"}
                className="tooltipImageStop"
              >
                <div className="sort">
                  <div className="deviceOffStatus">Ordenar por</div>
                  <div className="deviceTimeOffStatus">{"Nivel de señal"}</div>
                </div>
              </UncontrolledTooltip>
              <UncontrolledTooltip
                placement="right"
                target={"sortByVisibility"}
                className="tooltipImageStop"
              >
                <div className="sortShort">
                  <div className="deviceOffStatus">Ordenar por</div>
                  <div className="deviceTimeOffStatus">{"Visibilidad"}</div>
                </div>
              </UncontrolledTooltip>
            </div>
          ) : null}
          {this.deviceCard()}
        </div>

        <div
          className="dropDownList"
          onClick={() => {
            this.props.handleToggleList();
            this.props.unSelect();
          }}
        >
          <img
            className="groupBarIcon"
            src="/assets/images/svg/motion_icon-35.svg"
            alt="layer control icon"
          />
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps)(ListOfDevices);
