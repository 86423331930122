import React from "react";
import styled, { css } from "styled-components";

const colores = {
  borde: "#c5c5c5",
  error: "#c6007e",
  exito: "#01bebd",
};

const SelectContent = styled.div`
  position: relative;
  ${(props) =>
    css`
      width: ${props.width};
    `}
`;

const SelectStyle = styled.select`
  width: -webkit-fill-available;
  border: 1px solid ${colores.borde};
  color: #c5c5c5;
  font-weight: 300;
  font-size: 16px;
  background: transparent;
  outline: none;
  padding: 7px 1rem;
  font-weight: 300;
  font-size: 16px;
  border-radius: 7px;
  align-items: center;
`;

export default function SelectMotion({
  changeField,
  name,
  width,
  fields = [],
}) {
  return (
    <SelectContent width={width}>
      <SelectStyle type="text" onChange={changeField} name={name}>
        {fields.map((field) => {
          return <option value={field.value}>{field.name}</option>;
        })}
      </SelectStyle>
    </SelectContent>
  );
}
