import {
  SHOW_RUTA_MODAL,
  HIDEN_RUTA_MODAL,
  SELECTED_RUTA,
} from "../types/RutaTypes";

const initialState = {
  ShowRutaModal: false,
  RutaMarker: null,
};

const RutaReducer = function (state = initialState, action) {
  switch (action.type) {
    case SHOW_RUTA_MODAL:
      return { RutaMarker: null, ShowRutaModal: true };
    case HIDEN_RUTA_MODAL:
      return { RutaMarker: null, ShowRutaModal: false };
    case SELECTED_RUTA:
      return { RutaMarker: action.payload, ShowRutaModal: true };
    default:
      return state;
  }
};

export default RutaReducer;
