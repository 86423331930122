import React, { Component } from "react";

class ListOptions extends Component {
  constructor() {
    super();
    this.state = {};
    this.marker = this.marker.bind(this);
  }
  marker() {
    this.props.onMarkerDbClick(this.props.id);
    this.props.selectWidget();
  }
  render() {
    // funcion que retorna el estado del dispositivo.
    return (
      <div className="opt_list">
        <ul className="cardOptions">
          <li className="listHeader">Opciones</li>
          <li className="optionsItem" onClick={this.props.toggleModal}>
            Crear Unidad
          </li>
          <li
            className="optionsItem"
            onClick={this.props.toggleModalGroupUnits}
          >
            Crear Grupo
          </li>
        </ul>
      </div>
    );
  }
}

export default ListOptions;
