import React, { Component } from "react";
import { ReactComponent as IconSignal } from "../../static/motion_icon-09.svg";
import { ReactComponent as IconTime } from "../../static/motion_icon-12.svg";
import { ReactComponent as LogoMercedes } from "../assets/mercedes-benz.svg";

export default class CardInfoUnit extends Component {
  constructor() {
    super();
    this.state = {
      urlStreetView: "",
    };
  }
  componentDidMount() {
    const fenway = {
      lat: this.props.lat,
      lng: this.props.lng,
    };
    const panorama = new this.props.maps.StreetViewPanorama(
      document.getElementById("miniMapCardInfo"),
      {
        position: fenway,
        pov: {
          heading: this.props.orientation,
          pitch: 10,
        },
        addressControl: false,
        linksControl: false,
        panControl: false,
        enableCloseButton: false,
      }
    );
    try {
      this.props.map.setStreetView(panorama);
    } catch (err) {
    }
    this.setState({
      panorama: panorama,
    });
  }

  render() {
    return (
      <React.Fragment>
        <div
          className="cardMarker"
          onMouseLeave={this.props.mouseOut}
          onMouseEnter={this.props.mouseIn}
        >
          <div className="cardMarkerHeader">
            <div className="cardHeaderImg">
              <LogoMercedes className="cardHeaderImg_Svg" />
            </div>
            <div className="cardHeaderDesc">
              <span>
                <b>{this.props.marca}</b>
              </span>
              <br />
              <span>{this.props.modelo}</span>
            </div>
          </div>
          <div className="cardMarkerBody">
            <div
              style={{ width: "100%", height: `100%` }}
              id="miniMapCardInfo"
            ></div>
          </div>
          <div className="cardMarkerFooter column">
            <div className="row cardMarkerFooter_row1">
              <div className="CFinfoTag CFp5 column">
                <div className="deviceHeader">{this.props.name}</div>
                <div className="deviceSubtitle">{this.props.city}</div>
              </div>
              <div className="column">
                <div className="CFinfoVel CFp5 row">
                  <div className="CFvelocidad column">
                    <div className="deviceSpeed">{this.props.speed} Km/h</div>
                    <div className="deviceLastUpdate">
                      {/*this.props.lastUpdate*/}(00:00:14)
                    </div>
                  </div>
                  <div className="CFVelIco">
                    <IconSignal className="infoCardStatusIcon" fill="#cbcbcb" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row cardMarkerFooter_row2">
              <div className="CFtime1 row">
                <div className="iconMovimiento">
                  <IconTime className="infoCardStatusIcon" fill="#01bedb" />
                </div>
                <div className="tagMovimiento">
                  <p>{this.props.timeMoving}</p>
                </div>
              </div>
              <div className="CFtime2 row">
                <div className="iconStoped">
                  <IconTime className="infoCardStatusIcon" fill="#c6007e" />
                </div>
                <div className="tagStoped">
                  <p>{this.props.timeStoped}</p>
                </div>
              </div>
            </div>
            <div className="row cardMarkerFooter_row3">
              <div className="CFaltitud CFp5 row">
                <span className="greyData">Altitud:</span>
                <span className="boldData"> {this.props.altitud} m</span>
              </div>
              <div className="CFvoltage CFp5 row">
                <span className="greyData">Voltage:</span>
                <span className="boldData"> {this.props.voltage}V</span>
              </div>
            </div>
          </div>
          <div className="cardMarkerBottomMenu row">
            <div className='circleOption row'>
              <div className="circle circle_1"></div>
              <div className="circle circle_2"></div>
              <div className="circle circle_3"></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
