import React from "react";

import MarkerStyled from "./MarkerStyled";
import { ReactComponent as SignalCircle } from "../../../../../../../../../assets/svg/marker/signal-circle.svg";

import "./Marker.scss";

class Marker extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      orientation: 0,
      cardOptions: null,
      showMarker: true,
      hovered: false,
      markerState: "Normal",
      status: "",
      animationStatus: false,
    };
    this.animateMarker = this.animateMarker.bind(this);
    this.imageForType = this.imageForType.bind(this);
    this.changeOrientation = this.changeOrientation.bind(this);
    this.animationStatus = this.animationStatus.bind(this);
  }

  componentDidMount() {
    if (this.props.orientation === 360) {
      this.setState({ orientation: 0 });
    } else {
      this.setState({ orientation: this.props.orientation });
    }
    if (this.props.signal > 0) {
      this.setState({
        markerState: "Normal",
      });
    } else {
      this.setState({
        markerState: "Error",
      });
    }
    if (this.props.speed > 0) {
      this.setState({
        animationStatus: false,
      });
    } else {
      this.setState({
        animationStatus: true,
      });
      this.animationStatus();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.orientation) {
      if (this.props.showWidget) {
        if (this.props.idSelect === this.props.id) {
          this.props.setCenterMap([this.props.lat, this.props.lng]);
        }
      }
      if (prevProps.orientation !== this.props.orientation) {
        if (prevProps.orientation === 360) {
          this.setState({ orientation: 0 });
        } else {
          this.setState({ orientation: prevProps.orientation });
        }
        this.changeOrientation();
      }
    } else {
      if (this.props.orientation === 360) {
        this.setState({ orientation: 0 });
      } else {
        this.setState({ orientation: this.props.orientation });
      }
    }
    if (prevProps.signal !== this.props.signal) {
      if (this.props.signal > 0) {
        this.setState({
          markerState: "Normal",
          animationStatus: false,
        });
      } else {
        this.setState({
          markerState: "Error",
        });
      }
    }
    if (prevProps.speed !== this.props.speed) {
      if (!this.state.animationStatus && this.props.speed === 0) {
        this.animationStatus();
        this.setState({
          animationStatus: true,
        });
      }
    }
  }

  animationStatus() {
    this.timeStatus = setTimeout(() => {
      if (this.state.status === "") {
        this.setState({
          status: "-stop",
        });
      } else {
        this.setState({
          status: "",
        });
      }
      if (this.state.animationStatus) {
        this.animationStatus();
      }
    }, 700);
  }

  changeOrientation() {
    var speed = 20;
    if (this.state.orientation) {
      if (this.state.orientation < this.props.orientation) {
        if (this.props.orientation - this.state.orientation <= 180) {
          this.time = setTimeout(() => {
            this.setState({
              orientation: this.state.orientation + 1,
            });

            if (this.state.orientation < this.props.orientation) {
              this.changeOrientation();
            }
            if (this.state.orientation === 359) {
              this.setState({
                orientation: 0,
              });
            }
          }, speed);
        } else {
          this.time = setTimeout(() => {
            if (this.state.orientation === 360) {
              this.setState({
                orientation: 360 - 1,
              });
            } else {
              this.setState({
                orientation: this.state.orientation - 1,
              });
            }
            if (this.props.orientation > this.state.orientation) {
              this.changeOrientation();
            }
            if (this.state.orientation === 359) {
              this.setState({
                orientation: 0,
              });
            }
          }, speed);
        }
      } else {
        if (this.state.orientation - this.props.orientation >= 180) {
          this.time = setTimeout(() => {
            if (this.state.orientation === 360) {
              this.setState({
                orientation: 0 + 1,
              });
            } else {
              this.setState({
                orientation: this.state.orientation + 1,
              });
            }
            if (this.state.orientation > this.props.orientation) {
              this.changeOrientation();
            }
            if (this.state.orientation === 359) {
              this.setState({
                orientation: 0,
              });
            }
          }, speed);
        } else {
          this.time = setTimeout(() => {
            this.setState({
              orientation: this.state.orientation - 1,
            });

            if (this.state.orientation > this.props.orientation) {
              this.changeOrientation();
            }
            if (this.state.orientation === 359) {
              this.setState({
                orientation: 0,
              });
            }
          }, speed);
        }
      }
    }
  }

  animateMarker() {
    let changeImg;

    if (this.state.orientation === 360) {
      changeImg = 0;
    } else {
      changeImg =
        this.state.orientation -
        Math.round((this.state.orientation / 7.5) * 6.5);
    }

    if (changeImg < 10) {
      let animationIndex = "00" + changeImg;
      this.setState({
        imgSrc: `/assets/images/animation/${this.props.type.value}/${
          this.props.type.value
        }${
          this.state.animationStatus ? this.state.status : ""
        }${animationIndex}.png`,
        imgBack: `/assets/images/animation/${this.props.type.value}/${this.props.type.value}-color${animationIndex}.png`,
      });
    } else {
      let animationIndex = "0" + (changeImg > 47 ? "00" : changeImg);
      this.setState({
        imgSrc: `/assets/images/animation/${this.props.type.value}/${
          this.props.type.value
        }${
          this.state.animationStatus ? this.state.status : ""
        }${animationIndex}.png`,
        imgBack: `/assets/images/animation/${this.props.type.value}/${this.props.type.value}-color${animationIndex}.png`,
      });
    }
  }

  imageForType() {
    this.animateMarker();

    const styles = {
      width: `40px`,
    };
    return (
      <>
        <img
          src={this.state.imgBack}
          style={{
            ...styles,
            position: "absolute",
            filter: this.props.color.filterCss,
          }}
          alt=""
          className={`${
            this.props.focusMarker === this.props.id
              ? `markerFocus `
              : `marker `
          }
            `}
        />
        <img
          src={this.state.imgSrc}
          alt=""
          style={{
            ...styles,
            position: "absolute",
          }}
          className={`${
            this.props.focusMarker === this.props.id ? `markerFocus` : `marker`
          } img-front`}
        />
      </>
    );
  }

  render() {
    return (
      <div>
        <MarkerStyled className="imgMarker" onClick={this.handleClic}>
          {this.imageForType()}
          <SignalCircle
            className={`outline_marker outline_marker${this.state.markerState}`}
            style={{
              width: `40px`,
              position: "absolute",
              zIndex: -1,
            }}
          ></SignalCircle>
        </MarkerStyled>
      </div>
    );
  }
}

export default Marker;
