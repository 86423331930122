import React, { Component } from "react";
import {
  showRutaModal,
  hidenRutaModal,
} from "../../../../../../../redux/actions/RutaAction";
import { connect } from "react-redux";

import { ReactComponent as UnitIco } from "../../../../../../../assets/svg/sideNav/motion_icon-55.svg";
import { ReactComponent as RecorridosIco } from "../../../../../../../assets/svg/sideNav/motion_icon-48.svg";
import { ReactComponent as MensajesIco } from "../../../../../../../assets/svg/sideNav/motion_icon-07.svg";
import { ReactComponent as ReportesIco } from "../../../../../../../assets/svg/sideNav/motion_icon-02.svg";
import { ReactComponent as ConductoresIco } from "../../../../../../../assets/svg/sideNav/motion_icon-04.svg";
import { ReactComponent as RutasIco } from "../../../../../../../assets/svg/sideNav/motion_icon-03.svg";
import { ReactComponent as GeocercasIco } from "../../../../../../../assets/svg/sideNav/motion_icon-47.svg";
import { ReactComponent as NotificacionesIco } from "../../../../../../../assets/svg/sideNav/motion_icon-06.svg";
import { ReactComponent as PasajerosIco } from "../../../../../../../assets/svg/sideNav/motion_icon-pasajeros.svg";
import { ReactComponent as PoiIco } from "../../../../../../../assets/svg/sideNav/motion_icon-01.svg";
import { ReactComponent as WidtgetIco } from "../../../../../../../assets/svg/sideNav/motion_icon-33.svg";
import { ReactComponent as MantenimientoIco } from "../../../../../../../assets/svg/sideNav/motion_icon-34.svg";
import { ReactComponent as OtherIco } from "../../../../../../../assets/svg/sideNav/motion_icon-05.svg";

import "./VerticalNav.scss";

const mapStateToProps = (state) => {
  return {
    RutaReducer: state.RutaReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showRutaModal: () => {
    dispatch(showRutaModal());
  },
  hidenRutaModal: () => {
    dispatch(hidenRutaModal());
  },
});

class VerticalNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewConductores: false,
      ShowRutaModal: false,
    };
    this.newUnit = this.newUnit.bind(this);
    this.conductores = this.conductores.bind(this);
    this.toggleRutaModal = this.toggleRutaModal.bind(this);
  }
  componentDidMount() {
    console.log(this.props.showRutaModal);
  }
  newUnit() {
    this.props.toggleModal();
  }
  conductores() {
    this.props.toggleHiddeNav();

    this.setState({
      viewConductores: !this.state.viewConductores,
    });
  }
  toggleRutaModal() {
    if (!this.state.ShowRutaModal) {
      this.setState({
        ShowRutaModal: !this.state.ShowRutaModal,
      });
      this.props.showRutaModal();
    } else {
      this.setState({
        ShowRutaModal: !this.state.ShowRutaModal,
      });
      this.props.hidenRutaModal();
    }
  }
  render() {
    return (
      <div className="navigation">
        {/*unidades*/}

        <div
          key="nav_item0"
          className={this.props.viewListOfDevice ? "nav-itemS" : "nav-item"}
          onClick={this.newUnit}
        >
          <div key="Unidades" name="child" className="w-full">
            <UnitIco
              className="item-icon align-middle logoToolBar"
              alt="toolbar img"
              fill="#01bedb"
            />
            <span className="align-middle item-text">Unidades</span>
            <div className="mx-auto"></div>
          </div>
        </div>

        {/*recorridos*/}
        <div key="nav_item1" className="nav-item">
          <div key="Unidades" name="child" className="w-full">
            <RecorridosIco
              className="item-icon align-middle logoToolBar"
              alt="toolbar img"
              fill="#01bedb"
            />
            <span className="align-middle item-text">Recorridos</span>
            <div className="mx-auto"></div>
          </div>
        </div>
        {/*Mensajes*/}
        <div key="nav_item2" className="nav-item">
          <div key="Unidades" name="child" className="w-full">
            <MensajesIco
              className="item-icon align-middle logoToolBar"
              alt="toolbar img"
              fill="#01bedb"
            />
            <span className="align-middle item-text">Mensajes</span>
            <div className="mx-auto"></div>
          </div>
        </div>
        {/*Reportes*/}
        <div key="nav_item3" className="nav-item">
          <div key="Unidades" name="child" className="w-full">
            <ReportesIco
              className="item-icon align-middle logoToolBar"
              alt="toolbar img"
              fill="#01bedb"
            />
            <span className="align-middle item-text">Reportes</span>
            <div className="mx-auto"></div>
          </div>
        </div>
        {this.state.viewConductores ? (
          <div key="nav_item4" className="nav-itemS" onClick={this.conductores}>
            <div key="Unidades" name="child" className="w-full">
              <ConductoresIco
                className="item-icon align-middle logoToolBar"
                alt="toolbar img"
                fill="#01bedb"
              />
              <span className="align-middle item-text">Conductores</span>
              <div className="mx-auto"></div>
            </div>
          </div>
        ) : (
          <div key="nav_item4" className="nav-item" onClick={this.conductores}>
            <div key="Unidades" name="child" className="w-full">
              <ConductoresIco
                className="item-icon align-middle logoToolBar"
                alt="toolbar img"
                fill="#01bedb"
              />
              <span className="align-middle item-text">Conductores</span>
              <div className="mx-auto"></div>
            </div>
          </div>
        )}

        <div
          key="nav_item5"
          className={
            this.props.RutaReducer.ShowRutaModal ? "nav-itemS" : "nav-item"
          }
          onClick={this.toggleRutaModal}
        >
          <div key="Unidades" name="child" className="w-full">
            <RutasIco
              className="item-icon align-middle logoToolBar"
              alt="toolbar img"
              fill="#01bedb"
            />
            <span className="align-middle item-text">Rutas</span>
            <div className="mx-auto"></div>
          </div>
        </div>
        <div key="nav_item6" className="nav-item">
          <div key="Unidades" name="child" className="w-full">
            <GeocercasIco
              className="item-icon align-middle logoToolBar"
              alt="toolbar img"
              fill="#01bedb"
            />
            <span className="align-middle item-text">Geocercas</span>
            <div className="mx-auto"></div>
          </div>
        </div>
        <div key="nav_item7" className="nav-item">
          <div key="Unidades" name="child" className="w-full">
            <NotificacionesIco
              className="item-icon align-middle logoToolBar"
              alt="toolbar img"
              fill="#01bedb"
            />
            <span className="align-middle item-text">Notificaciones</span>
            <div className="mx-auto"></div>
          </div>
        </div>
        <div key="nav_item8" className="nav-item">
          <div key="Unidades" name="child" className="w-full">
            <PasajerosIco
              className="item-icon align-middle logoToolBar"
              alt="toolbar img"
              fill="#01bedb"
            />
            <span className="align-middle item-text">Pasajeros</span>
            <div className="mx-auto"></div>
          </div>
        </div>
        <div key="nav_item9" className="nav-item">
          <div key="Unidades" name="child" className="w-full">
            <PoiIco
              className="item-icon align-middle logoToolBar"
              alt="toolbar img"
              fill="#01bedb"
            />
            <span className="align-middle item-text">
              Puntos de interes (POI)
            </span>
            <div className="mx-auto"></div>
          </div>
        </div>
        <div key="nav_item10" className="nav-item">
          <div key="Unidades" name="child" className="w-full">
            <WidtgetIco
              className="item-icon align-middle logoToolBar"
              alt="toolbar img"
              fill="#01bedb"
            />
            <span className="align-middle item-text">Widgets</span>
            <div className="mx-auto"></div>
          </div>
        </div>
        <div key="nav_item11" className="nav-item">
          <div key="Unidades" name="child" className="w-full">
            <MantenimientoIco
              className="item-icon align-middle logoToolBar"
              alt="toolbar img"
              fill="#01bedb"
            />
            <span className="align-middle item-text">Mantenimiento</span>
            <div className="mx-auto"></div>
          </div>
        </div>
        <div key="nav_item12" className="nav-item">
          <div key="Unidades" name="child" className="w-full">
            <OtherIco
              className="item-icon align-middle logoToolBar"
              alt="toolbar img"
              fill="#01bedb"
            />
            <span className="align-middle item-text">Otro</span>
            <div className="mx-auto"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerticalNav);
