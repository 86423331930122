import axios from "axios";
import qs from "qs";

export const ADD_DRIVER_TO_MOTION = "ADD_DRIVER_TO_MOTION";
export const ADD_DRIVER_TO_MOTION_SUCCESS = "ADD_DRIVER_TO_MOTION_SUCCESS";
export const DATA_FAILED = "DATA_FAILED";

/* Aqui probare la accion de crear nueva unidad*/
export function createDriver({ name, tel, description }) {
  return (dispatch) => {
    let data = qs.stringify({
      name: name,
      tel: tel,
      description: description,
    });
    console.log(data);
    var config = {
      method: "POST",
      url: "/api/tracking/drivers",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios(config)
      .then(
        (response) => {
          if (response.status === 200) {
            console.log(response);
            return response;
          } else {
            var error = new Error("oops, something went wrong");
            console.log(error);
            error.response = response;
            throw error;
          }
        },
        (error) => {
          console.log(error);
          var errmess = new Error(error);
          throw errmess;
        }
      )
      .then((response) => dispatch(createUnitSuccess(response.result)))
      .catch((error) => dispatch(dataFailed(error.message)));
  };
}

//Create unit

export function createUnitPost(payload) {
  return { type: ADD_DRIVER_TO_MOTION, payload };
}

export function createUnitSuccess(payload) {
  const byId = { [payload.result]: payload };
  return { type: ADD_DRIVER_TO_MOTION_SUCCESS, payload: { byId } };
}

export const dataFailed = (errmess) => ({
  type: DATA_FAILED,
  payload: errmess,
});
