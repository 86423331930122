import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { createDriver } from "../../../../../../redux/actions/driverActions";

const mapStateToProps = (state) => ({
  createDriver: PropTypes.func.isRequired,
  conductores: state.conductores,
});

class Drivers extends Component {
  constructor(props) {
    super();
    this.state = {
      conductores: {
        nombre: "",
        telefono: "",
        description: "",
      },
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState({
      conductores: {
        ...this.state.conductores,
        [name]: value,
      },
    });
  }
  peticion(event) {
    event.preventDefault();
    let name = this.state.conductores.nombre;
    let tel = this.state.conductores.telefono;
    let description = this.state.conductores.description;

    this.props.createDriver({
      name,
      tel,
      description,
    });
  }
  render() {
    return (
      <div className="form-hiddenav">
        <h1>Nuevo Conductor</h1>
        <form>
          <div>
            <h2>Nombre</h2>
            <input
              type="text"
              placeholder="nombre"
              value={this.state.conductores.nombre}
              onChange={this.handleChange}
              name="nombre"
            />
          </div>
          <div>
            <h2>Telefono</h2>
            <input
              type="text"
              placeholder="telefono"
              name="telefono"
              value={this.state.conductores.telefono}
              onChange={this.handleChange}
            />
          </div>
          <div>
            <h2>Descripcion</h2>
            <input
              type="text"
              placeholder="descripcion"
              name="description"
              value={this.state.conductores.description}
              onChange={this.handleChange}
            />
          </div>
          <button onClick={(event) => this.peticion(event)}>
            Crear Conductor
          </button>
        </form>
      </div>
    );
  }
}

export default connect(mapStateToProps, { createDriver })(Drivers);
